
















































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import PanelAnnexes from '@/components/panel-annexes.vue';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import { AnnexeDto } from '@/shared/dtos/annexe.dto';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';
import store from '@/shared/store';
import roles from '@/shared/constants/roles.const';

@Component({
  name: 'PrestationExpertiseHeaderActions',
  components: { Spinner, PanelAnnexes },
})
export default class PrestationExpertiseHeaderActions extends Mixins(DeviceMixin) {
  @Prop() public isReadOnly: boolean;
  @Prop() public isSaving: boolean = false;
  @Prop() public isExporting: boolean = false;
  @Prop() public isDepositing: boolean = false;
  @Prop() public mission: MissionDto;
  @Prop() public lastPrestationExpertiseVersionHistory: PrestationExpertiseVersionHistoryDto;
  @Prop({ default: true }) public isExportDisplayed: boolean;

  public isPanelAnnexesVisible: boolean = false;
  public isSearchingAnnexes: boolean = false;
  public annexes: AnnexeDto[] = [];

  public etapeMissionEnum = EtapeMissionEnum;
  public isControleur = store.user.roles.includes(roles.Controleur);
  //#region LIFE CYCLES

  public async beforeMount(): Promise<void> {
    await this.initAnnexes();
  }

  //#endregion

  //#region COMPUTED
  get canDeposer() {
    return (
      this.isBrowser &&
      this.$can('Deposer', 'Rapport') &&
      (!(
        this.mission.etape === this.etapeMissionEnum.ValeursAViserVisa1 ||
        this.mission.etape === this.etapeMissionEnum.ValeursAViserVisa2 ||
        this.mission.etape === this.etapeMissionEnum.ExpertiseCompleteAViser
      ) ||
        (this.isControleur && this.mission.expert.id === store.user.omereContactId))
    );
  }

  get canSendMessage() {
    return this.$can('EmailCommande', 'Pages') || this.mission.expert.id === store.user.omereContactId;
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onSaveButtonClick(): void {
    this.$emit('saveButtonClicked');
  }

  public onExportButtonClick(): void {
    this.$emit('exportButtonClicked');
  }

  public onDeposerButtonClick(): void {
    this.$emit('deposerButtonClicked');
  }

  public onSendMessageClick(): void {
    this.$router.push({ path: `/missions/${this.mission.id}/contact`, replace: true });
  }

  //#endregion

  //#region FUNCTIONS
  public async initAnnexes(): Promise<void> {
    if (!navigator.onLine) return;

    this.isSearchingAnnexes = true;

    const logMessage = `Recherche des annexes attachées à la mission: ${this.mission.id}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseHeaderActions');

    await Vue.prototype.$missionApiService
      .getAnnexes(this.mission.id)
      .then(async (annexes: AnnexeDto[]) => {
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseHeaderActions');

        this.annexes = annexes;
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.info(`${logMessage} - ERROR`, error, 'PrestationExpertiseHeaderActions');
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération des annexes attachées à la mission`);
      })
      .finally(() => {
        this.isSearchingAnnexes = false;
      });
  }

  //#endregion
}
