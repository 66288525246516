







































import { MissionComparableDto } from '@/shared/dtos/mission-comparable.dto';
import { Component, Prop, Vue } from 'vue-property-decorator';
import store from '@/shared/store';

@Component({
  name: 'PanelComparables',
})
export default class PanelComparables extends Vue {
  @Prop({ default: false }) public isVisible: boolean;
  @Prop({ default: [] }) public comparables: MissionComparableDto[];

  public fields: any = [
    'description',
    'surface',
    'prix',
    {
      key: 'dateRapport',
      label: 'Date',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  //#region LIFE CYCLES
  public created(): void {
    document.addEventListener('keydown', this.onEscapeKeyPress);
  }

  public destroyed(): void {
    document.removeEventListener('keydown', this.onEscapeKeyPress);
  }
  //#endregion

  //#region COMPUTED

  get list(): any {
    return this.comparables;
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onEscapeKeyPress(e: any): void {
    if (e.keyCode === 27) {
      this.onCloseClick();
    }
  }

  //#endregion

  public onCloseClick(): void {
    this.$emit('visibleChanged', false);
  }

  public onAddComparableClick(data: any): void {
    this.$emit('comparableSelected', data);
  }

  public onViewRapportClick(expertiseId: number): void {
    store.isLoading = true;
    Vue.prototype.$expertiseApiService
      .getRapportById(expertiseId)
      .then((response: any) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        var blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  //#region FUNCTIONS

  //#endregion
}
