








import { Component, Mixins } from 'vue-property-decorator';

import ValeurSpotPlusSectionMixin from '@/mixins/valeur-spot-plus-section.mixin';

import PrestationExpertiseSituationAdresse from '@/shared/components/prestation-expertise/prestation-expertise-situation-adresse.vue';
import StarRating from 'vue-star-rating';

@Component({
  name: 'PrestationExpertiseValeurSpotPlusSituationGeographiquePlansDeSituation',
  components: {
    PrestationExpertiseSituationAdresse,
    StarRating,
  },
})
export default class PrestationExpertiseValeurSpotPlusSituationGeographiquePlansDeSituation extends Mixins(ValeurSpotPlusSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
