












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MultipleChart',
})
export default class MultipleChart extends Vue {
  @Prop() public title: string;
  @Prop() public data: any;
  @Prop() public name: any;
  @Prop() public options: Function;

  public legend: any = null;
  public charts: any = [];

  //#region LIFE CYCLES
  public mounted(): void {
    this.init();
  }

  public beforeUpdate(): void {
    this.update();
  }

  public beforeDestroy(): void {
    this.destroy();
  }
  //#endregion

  //#region COMPUTED
  get hasChart() {
    return this.data != null;
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  public init(): void {
    if (this.data == null) return;
    let chart;
    for (let i = 0; i < this.data.length; i++) {
      let dataset = this.data[i];
      const refChart: any = this.$refs[this.name + i];
      try {
        chart = new (window as any).Chart(refChart[0].getContext('2d'), this.options(dataset));
      } catch (error) {
        // En cas d'erreur, on rejoue une deuxième fois. On est obligé de faire ça si on veut éviter des exceptions bizarroides.
        chart = null;
        chart = new (window as any).Chart(refChart[0].getContext('2d'), this.options(dataset));
      }
      this.charts.push(chart);
    }
    this.legend = chart.generateLegend();
  }

  public update(): void {
    this.destroy();
    this.init();
  }

  public destroy(): void {
    for (let i = 0; i < this.charts.length; i++) {
      let chart = this.charts[i];
      chart.destroy();
    }
    this.charts = [];
  }

  //#endregion
}
