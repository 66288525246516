







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
@Component({
  name: 'TextareaWithDefaultValue',
})
export default class TextareaWithDefaultValue extends Vue {
  @Prop() public value: string;
  @Prop() public text: string;
  @Prop() public title: string;

  public valeur: string = null;

  //#region LIFE CYCLES

  created() {
    this.valeur = this.value;
  }

  beforeMount() {
    this.fill();
  }

  //#endregion

  //#region WATCH

  @Watch('valeur')
  public valeurChanged(val: string) {
    this.$emit('input', val);
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  clear() {
    if (this.valeur === this.text) {
      this.valeur = null;
    }
  }
  fill() {
    if (isNullOrEmpty(this.valeur)) {
      this.valeur = this.text;
    }
  }
  //#endregion
}
