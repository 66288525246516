











































import { Component, Prop, Vue } from 'vue-property-decorator';
import TableSurfaces from '@/components/rapport-expertise/table-surfaces.vue';
import { isDistributionInterieureSurfaceTypeAutre, isDistributionInterieureEtageTypeAutre } from '@/helpers/rapport-expertise.helper';
import { mixins } from 'vue-class-component';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

@Component({
  name: 'DistributionInterieureEtage',
  components: {
    TableSurfaces,
  },
})
export default class DistributionInterieureEtage extends mixins(RapportExpertiseSectionMixin) {
  @Prop() public etage: any;
  @Prop() public deleteEtage: Function;
  @Prop() public surfacesChanged: Function;

  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  public surfaceTypeIsAutre(surface: any): boolean {
    return isDistributionInterieureSurfaceTypeAutre(surface);
  }
  public etageTypeIsAutre(etage: any): boolean {
    return isDistributionInterieureEtageTypeAutre(etage);
  }

  //#endregion
}
