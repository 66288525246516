

























































































import { Component, Vue } from 'vue-property-decorator';
import store from '@/shared/store';

@Component({
  name: 'BasicLayout',
})
export default class BasicLayout extends Vue {
  public version = Vue.prototype.$config.appVersion;

  public isLogged = store.isLogged;
  public isPageRapport =
    this.$route.fullPath.indexOf('RapportExpertise') !== -1 ||
    this.$route.fullPath.indexOf('ValeurSpotPlus') !== -1 ||
    this.$route.fullPath.indexOf('CertificatExpertise') !== -1 ||
    this.$route.fullPath.indexOf('EtatAvancementTravaux') !== -1;
  public userName = '';

  public ability = this.$router.app.$ability;

  //#region LIFE CYCLES
  public beforeMount(): void {
    this.isLogged = store.isLogged;

    if (store.user) this.userName = store.user.surname + ' ' + store.user.name;
  }

  //#endregion

  //#region EVENTS
  public onLogoutButtonClick(): void {
    Vue.prototype.$authentificationService.logout();
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
