








/* eslint-disable no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
import Spinner from 'vue-spinner-component/src/Spinner.vue';

@Component({
  name: 'AgendaLoadingMessage',
  components: {
    Spinner,
  },
})
export default class AgendaLoadingMessage extends Vue {
  @Prop({ required: true }) public isDisplayed: boolean;
}
