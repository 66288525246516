














import { Component, Prop, Vue } from 'vue-property-decorator';
import { PrestationExpertiseProprietesDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-proprietes.dto';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
import PrestationExpertiseVersionHistories from '@/shared/components/prestation-expertise/prestation-expertise-version-histories.vue';

@Component({
  name: 'PrestationExpertiseProprietes',
  components: {
    RadioBoolean,
    PrestationExpertiseVersionHistories,
  },
})
export default class PrestationExpertiseProprietes extends Vue {
  @Prop() proprietes: PrestationExpertiseProprietesDto;
  @Prop() prestationExpertiseId: string;

  //#region LIFE CYCLES

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
