import moment from 'moment';
import { PrestationExpertisePointInspectionDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-point-inspection.dto';
import { ModeleEmailDto } from '@/shared/dtos/modele-email.dto';
import _ from 'lodash';

const BUSINESS_ENTRIES = 'businessentries';
const LAST_PULL_DATE = 'lastPullDate';
const POINTS_INSPECTIONS = 'pointsinspections';
const MODELES_EMAILS = 'modelesEmails';
const BACK_URL_HISTORY = 'backUrlHistory';

export class LocalStorageService {
  constructor() {}

  //#region missions

  //#endregion

  //#region missions

  //#endregion

  //#region BusinessEntries
  public getBusinessEntries(): any {
    return JSON.parse(localStorage.getItem(BUSINESS_ENTRIES));
  }

  public setBusinessEntries(businessEntries: any): void {
    return localStorage.setItem(BUSINESS_ENTRIES, JSON.stringify(businessEntries));
  }

  //#endregion

  //#region PointsInspections
  public getPointsInspections(): PrestationExpertisePointInspectionDto[] {
    return JSON.parse(localStorage.getItem(POINTS_INSPECTIONS));
  }

  public setPointsInspections(pointsInspections: PrestationExpertisePointInspectionDto[]): void {
    return localStorage.setItem(POINTS_INSPECTIONS, JSON.stringify(pointsInspections));
  }

  //#endregion

  //#region ModelesEmails
  public getModelesEmails(): ModeleEmailDto[] {
    return JSON.parse(localStorage.getItem(MODELES_EMAILS));
  }

  public setModelesEmails(modelesEmails: ModeleEmailDto[]): void {
    return localStorage.setItem(MODELES_EMAILS, JSON.stringify(modelesEmails));
  }

  //#endregion

  //#region Synchronization Dates
  public getLastPullDate(): string {
    return JSON.parse(localStorage.getItem(LAST_PULL_DATE)) as string;
  }

  public setLastPullDate(date: moment.Moment | null): void {
    return localStorage.setItem(LAST_PULL_DATE, JSON.stringify(date));
  }

  //#endregion

  //#region BackUrlHistory
  public getBackUrlHistory(): any[] {
    let backUrlHistory = localStorage.getItem(BACK_URL_HISTORY);

    if (backUrlHistory) return JSON.parse(backUrlHistory);
    else return [];
  }

  public pushBackUrlHistory(from: any, to: any): void {
    if (from.fullPath && from.fullPath !== '/') {
      let backUrlHistory = this.getBackUrlHistory() as any[];

      if (backUrlHistory.length > 0 && backUrlHistory[backUrlHistory.length - 1].from === to.fullPath) {
        backUrlHistory.pop();
        localStorage.setItem(BACK_URL_HISTORY, JSON.stringify(backUrlHistory));
      } else {
        backUrlHistory.push({ from: from.fullPath, to: to.fullPath });
        localStorage.setItem(BACK_URL_HISTORY, JSON.stringify(backUrlHistory));
      }

      if (backUrlHistory.length === 0) {
        backUrlHistory.push({ from: from.fullPath, to: to.fullPath });
        localStorage.setItem(BACK_URL_HISTORY, JSON.stringify(backUrlHistory));
      }
    }
  }

  public removeLastHistory(): void {
    let backUrlHistory = this.getBackUrlHistory() as any[];
    if (backUrlHistory.length > 0) {
      backUrlHistory.pop();
      localStorage.setItem(BACK_URL_HISTORY, JSON.stringify(backUrlHistory));
    }
  }

  //#endregion
}
