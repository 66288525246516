















































import DeviceMixin from '@/mixins/device.mixin';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { TypePrestationExpertisePieceJointeEnum } from '@/shared/enums/type-prestation-expertise-piece-jointe.enum';
import { SubjectMessage } from '@/shared/models/subject-message';
import { Component, Inject, Mixins, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PrestationExpertisePieceJointe',
})
export default class PrestationExpertisePieceJointe extends Mixins(DeviceMixin) {
  private _fileExtension: string;

  @Inject('deletePieceJointeByIdAndType') public deletePieceJointeByIdAndType: any;
  @Inject('confirmDelete') public confirmDelete: any;
  @Inject('renamePieceJointe') public renamePieceJointe: any;

  @Prop({ required: true }) public type: string;
  @Prop() public pieceJointe: PrestationExpertisePieceJointeDto;
  @Prop({ default: true }) public showLabel: boolean;

  public isEditing: boolean = false;
  public nom: string = null;
  public canDefineMainPhoto: boolean = true;

  //#region LIFE CYCLES
  public beforeMount(): void {
    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.REMOVE_MAIN_PHOTO) {
        this.pieceJointe.isMain = false;
        this.pieceJointe.dateModificationUtc = new Date();
      }
    });
  }

  public mounted(): void {
    this.canDefineMainPhoto =
      this.isBrowser && this.type !== TypePrestationExpertisePieceJointeEnum[TypePrestationExpertisePieceJointeEnum.PlanCadastre];
  }
  //#endregion

  //#region COMPUTED
  get path() {
    if (this.pieceJointe.content.indexOf('base64') === -1) {
      return `${Vue.prototype.$config.baseUrlApiAppService}${this.pieceJointe.content}`;
    }
    return this.pieceJointe.content;
  }
  //#endregion

  //#region EVENTS
  public onEditClick(): void {
    this.isEditing = true;

    const fileNameAndExtension = /\.[^/.]+$/.exec(this.pieceJointe.nom);

    if (fileNameAndExtension && fileNameAndExtension.length) {
      this._fileExtension = /\.[^/.]+$/.exec(this.pieceJointe.nom)[0];
      this.nom = this.pieceJointe.nom.replace(/\.[^/.]+$/, '');
    } else {
      this._fileExtension = '.jpg';
      this.nom = this.pieceJointe.nom;
    }
  }

  public async onUpdateClick(): Promise<void> {
    if (this.nom == '') {
      Vue.prototype.$notificationService.error(`Vous devez renseigner un nom sur cette photo`);
      return;
    }

    await this.renamePieceJointe(this.pieceJointe.id, this.type, this.nom + this._fileExtension);
    this.isEditing = false;
  }

  public onCancelClick(): void {
    this.isEditing = false;
  }

  public onDeleteClick(): void {
    this.confirmDelete('cette pièce jointe').then(() => {
      this.deletePieceJointeByIdAndType(this.pieceJointe.id, this.type);
    });
  }

  public onShowConfirmToSetMainPhotoClick(): void {
    this.$bvModal
      .msgBoxConfirm('Voulez-vous mettre cette photo en couverture sur le rapport ?', {
        title: 'Confirmation',
        size: 'sm',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        centered: true,
        hideHeaderClose: false,
        footerClass: 'p-2 text-right',
      })
      .then((isOK) => {
        if (isOK) {
          Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.REMOVE_MAIN_PHOTO);
          this.pieceJointe.isMain = true;
          this.pieceJointe.dateModificationUtc = new Date();
        }
      });
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
