import { SimpleMissionDto } from '@/shared/dtos/simple-mission.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class SimpleMissionApiService extends BaseDtoApiService<SimpleMissionDto> {
  constructor() {
    super('missions');
  }

  public getByOmereContactId(omereContactId: number): Promise<SimpleMissionDto[]> {
    return axios.get(`${this._endpoint}/contact/${omereContactId}/simple`).then((data: any) => this.convertDatas(data));
  }
}
