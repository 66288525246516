






































































































































import { Vue, Component, Inject, Mixins, Watch } from 'vue-property-decorator';

import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import {
  isContratConditionOccupationOccupe,
  isContratTypeVisiteExterieur,
  isContratTypeVisiteInterieurEtExterieur,
} from '@/helpers/rapport-expertise.helper';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import PrestationExpertiseContexteIntervention from '@/shared/components/prestation-expertise/prestation-expertise-contexte-intervention.vue';
import { PieceJointeRapportDto } from '@/shared/dtos/piece-jointe-rapport.dto';
import { EvaluationMethodeRetenueEnum } from '@/shared/enums/evaluation-methode-retenue.enum';
import { ContratHypotheseEvaluationEnum } from '@/shared/enums/contrat-hypothese-evaluation.enum';
import DateVisite from '@/shared/components/date-visite.vue';

@Component({
  name: 'PrestationExpertiseRapportContratMission',
  components: {
    DateVisite,
    ChoicePopup,
    PrestationExpertiseContexteIntervention,
  },
})
export default class PrestationExpertiseRapportContratMission extends Mixins(RapportExpertiseSectionMixin) {
  @Inject('deletePieceJointeByIdAndType') public deletePieceJointeByIdAndType: any;
  //#region LIFE CYCLES
  public beforeMount(): void {
    if (this.isVisiteExterieure) {
      this.cleanPrestations();
    }
  }

  //#endregion

  //#region COMPUTED
  get isConditionOccupationOccupe(): boolean {
    return isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId);
  }

  get isVisiteExterieure(): boolean {
    return isContratTypeVisiteExterieur(this.rapport.contratTypeVisiteId);
  }

  get isVisiteInterieurEtExterieur(): boolean {
    return isContratTypeVisiteInterieurEtExterieur(this.rapport.contratTypeVisiteId);
  }

  get isHypotheseEvaluationAutre(): boolean {
    return this.rapport.contratHypotheseEvaluationId === ContratHypotheseEvaluationEnum.Autre;
  }

  //#endregion

  //#region WATCH
  @Watch('rapport.contratCategorieDActifId')
  public contratCategorieDActifIdChanged(): void {
    this.rapport.contratTypeBienId = null;

    if (!this.isTypeDeBienAppartement) {
      this.rapport.contratTypeBienSpecificiteId = null;
    }

    if (this.isTypeDeBienTerrain) {
      this.rapport.evaluationSurfaceOuSuperficie = this.rapport.terrainSuperficie;
      this.clearDescriptionParticuliere();
    } else {
      this.rapport.evaluationSurfaceOuSuperficie = this.rapport.particuliereSurfaceTotaleHabitable;
    }

    this.updateCategorieActif();
  }

  @Watch('rapport.contratTypeVisiteId')
  public contratTypeVisiteIdChange(): void {
    if (this.isVisiteExterieure) {
      this.cleanPrestations();
    }
  }

  @Watch('rapport.contratConditionOccupationId')
  public contratConditionOccupationIdChanged(): void {
    if (this.isRapportVersionLowerOrEqualsTo(2.0)) {
      if (this.isConditionOccupationOccupe) {
        this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.ComparaisonEtRendement;
      } else {
        this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.Comparaison;
        this.rapport.contratHypotheseEvaluationId = null;
      }
    } else {
      if (!this.isConditionOccupationOccupe) this.clearSituationLocative();
    }
  }

  @Watch('rapport.contratHypotheseEvaluationId')
  public contratHypotheseEvaluationIdChanged(val: number): void {
    if (this.isRapportVersionGreaterOrEqualsTo(3.0)) {
      switch (val) {
        case ContratHypotheseEvaluationEnum.BienValoriseLibreDeTouteOccupationOuLocation:
        case ContratHypotheseEvaluationEnum.BienValoriseApresTravaux:
          this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.Comparaison;
          break;
        case ContratHypotheseEvaluationEnum.BienValoriseEnEtatEtCompteTenuDeSesConditionsOccupation:
        case ContratHypotheseEvaluationEnum.BienSupposeLoueALaValeurLocativeDeMarche:
          this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.ComparaisonEtRendement;
          break;
        case ContratHypotheseEvaluationEnum.Autre:
          this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.Comparaison;
          break;
      }

      if (val !== ContratHypotheseEvaluationEnum.Autre) this.rapport.contratHypotheseEvaluationAutre = null;
    }
  }
  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  public clearDescriptionParticuliere(): void {
    if (this.rapport.piecesJointesRapport != null) {
      this.rapport.piecesJointesRapport['SectionGrosOeuvre'].forEach((pj: PieceJointeRapportDto) => {
        this.deletePieceJointeByIdAndType(pj.id, 'SectionGrosOeuvre');
      });
      this.rapport.piecesJointesRapport['SectionSurface'].forEach((pj: PieceJointeRapportDto) => {
        this.deletePieceJointeByIdAndType(pj.id, 'SectionSurface');
      });

      this.rapport.piecesJointesRapport['Cuisine'].forEach((pj: PieceJointeRapportDto) => {
        this.deletePieceJointeByIdAndType(pj.id, 'Cuisine');
      });

      this.rapport.piecesJointesRapport['SalleDeBain'].forEach((pj: PieceJointeRapportDto) => {
        this.deletePieceJointeByIdAndType(pj.id, 'SalleDeBain');
      });
    }

    this.rapport.caracteristiquesAnneeConstruction = null;
    this.rapport.caracteristiquesMitoyennete = null;
    this.rapport.caracteristiquesMitoyenneteCoteId = null;
    this.rapport.caracteristiquesGrosOeuvres = [];
    this.rapport.caracteristiquesMenuiseries = [];
    this.rapport.caracteristiquesToitures = [];
    this.rapport.caracteristiquesFacades = [];
    this.rapport.caracteristiqueAssainissementId = null;
    this.rapport.caracteristiquesDescription = null;
    this.rapport.caracteristiquesDPEId = null;

    this.rapport.distributionInterieureEtages = [];
    this.rapport.prestationsMurs = [];
    this.rapport.prestationsPlafonds = [];
    this.rapport.prestationsSols = [];
    this.rapport.prestationsModesChauffages = [];
    this.rapport.prestationsEquipements = [];
    this.rapport.prestationsAgrements = [];
    this.rapport.prestationsAvisExpert = null;
    this.rapport.prestationsCuisines = [];
    this.rapport.prestationsSallesDeBains = [];
    this.rapport.prestationsSurfaceAnnexes = [];
    this.rapport.prestationsDependances = [];
    this.rapport.prestationsDescription = null;

    this.rapport.prestationsPartiesCommunesId = null;
    this.rapport.particuliereSourceDesSurfacesId = null;
    this.rapport.particuliereSurfaceTotaleHabitable = null;
    this.rapport.prestationsInstallationElectriqueId = null;
    this.rapport.prestationsIsolationId = null;
  }

  public cleanPrestations() {
    this.rapport.piecesJointesRapport['SectionSurface'].forEach((pj: PieceJointeRapportDto) => {
      this.deletePieceJointeByIdAndType(pj.id, 'SectionSurface');
    });

    this.rapport.piecesJointesRapport['Cuisine'].forEach((pj: PieceJointeRapportDto) => {
      this.deletePieceJointeByIdAndType(pj.id, 'Cuisine');
    });

    this.rapport.piecesJointesRapport['SalleDeBain'].forEach((pj: PieceJointeRapportDto) => {
      this.deletePieceJointeByIdAndType(pj.id, 'SalleDeBain');
    });

    this.rapport.prestationsMurs = [];
    this.rapport.prestationsSols = [];
    this.rapport.prestationsPlafonds = [];
    this.rapport.prestationAvecChauffage = null;
    this.rapport.prestationsModesChauffages = [];
    this.rapport.prestationsEquipements = [];
    this.rapport.prestationsAgrements = [];
    this.rapport.prestationsCuisines = [];
    this.rapport.prestationsSallesDeBains = [];
    this.rapport.prestationsPartiesCommunesId = null;
    this.rapport.contratVisitePresenceCiviliteId = null;
    this.rapport.prestationsDescription = null;
    this.rapport.prestationsInstallationElectriqueId = null;
    this.rapport.prestationsIsolationId = null;
  }

  public clearSituationLocative(): void {
    if (!this.isRapport) return;
    this.rapport.locativeChargesNonRecuperables = null;
    this.rapport.locativeCommentaire = null;
    this.rapport.locativeDateBail = null;
    this.rapport.locativeLoyerAnnuelBrut = null;
    this.rapport.locativeLoyerAnnuelNet = null;
    this.rapport.locativeLoyerMensuelBrut = null;
    this.rapport.locativeNatureBailId = null;
  }

  public async updateCategorieActif(): Promise<void> {
    if (navigator.onLine) {
      const logMessage = `Mise à jour de la catégorie d'actif de la mission ${this.mission.reference}.`;
      await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseRapport');
      await Vue.prototype.$rapportExpertiseApiService
        .updateCategorieActif(this.rapport.id, this.rapport.contratCategorieDActifId)
        .then(async () => {
          await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseRapport');
        });
    } else {
      navigator.serviceWorker.controller.postMessage({
        type: 'ADD_REQUEST',
        data: Vue.prototype.$rapportExpertiseApiService.updateCategorieActif(this.rapport.id, this.rapport.contratCategorieDActifId),
      });
    }
  }
  //#endregion
}
