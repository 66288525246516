import { MissionForListDto } from '@/shared/dtos/liste-mission.dto';
import store from '@/shared/store';
import Vue from 'vue';

export const getMissionsByOmereContactIdResolver = (to: any, from: any, next: any) => {
  Vue.prototype.$missionForListApiService.getLastByOmereContactId(store.user.omereContactId).then((missions: MissionForListDto[]) => {
    to.meta.missions = missions;
    next();
  });
};
