

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'VisaDisplay',
})
export default class VisaDisplay extends Vue {
  @Prop({ required: true }) public visa: any;
  @Prop({ default: 'Cette section a été' }) public baseText: string;

  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
