




























































import { Vue, Component } from 'vue-property-decorator';
import PrestationExpertiseLayout from '@/views/layouts/prestation-expertise.layout.vue';
import PrestationExpertise from '@/views/prestation-expertise/prestation-expertise';
import PrestationExpertiseSections from '@/components/prestation-expertise/prestation-expertise-sections.vue';
import PrestationExpertiseDesktopMenu from '@/components/prestation-expertise/prestation-expertise-desktop-menu.vue';
import PrestationExpertiseMobileMenu from '@/components/prestation-expertise/prestation-expertise-mobile-menu.vue';
import PrestationExpertiseHeaderActions from '@/components/prestation-expertise/prestation-expertise-header-actions.vue';
import { EtudeDeMarcheDto } from '@/shared/dtos/etude-de-marche.dto';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { PrestationExpertiseProprietesDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-proprietes.dto';
import { etudeDeMarcheValidation } from '@/validations/etude-de-marche.validation';

@Component({
  name: 'PrestationExpertiseEtudeDeMarche',
  components: {
    // Layout
    PrestationExpertiseLayout,
    PrestationExpertiseSections,
    PrestationExpertiseDesktopMenu,
    PrestationExpertiseMobileMenu,
    PrestationExpertiseHeaderActions,
  },
  validations: etudeDeMarcheValidation,
})
export default class PrestationExpertiseEtudeDeMarche extends PrestationExpertise {
  constructor() {
    // Cannot pass componentsSections in the parent bescause @Component doesn't support properties in contructor...
    super();
  }

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    if (this.hasAccess) {
      this.initComponentSections();
    }
  }

  public mounted(): void {
    // Watch à cet endroit là pour éviter de le trigger dès le début et MAJ la date de modification sans le vouloir
    // VueJS semble mettre à jour l'objet mission
    setTimeout(() => {
      this.$watch(
        'etudeDeMarche.proprietes.estEditionForcee',
        async () => {
          this._needToByPasseHasPrestationExpertiseChanged = true;
          const logMessage = `Sauvegarde des propriétés: ${this.mission.reference}`;
          this.saveInStorage(true);

          await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseEtudeDeMarche');

          Vue.prototype.$prestationExpertiseProprietesApiService
            .update((this.etudeDeMarche.proprietes as PrestationExpertiseProprietesDto).id, this.etudeDeMarche.proprietes)
            .then(async () => {
              await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseEtudeDeMarche');
              Vue.prototype.$notificationService.success(`Les propriétés ont été sauvegardées avec succès.`);
            })
            .catch(async (error: any) => {
              Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la sauvegarde des propriétés.`);
              await Vue.prototype.$logStoreService.info(`${logMessage} - ERROR`, error, 'PrestationExpertiseEtudeDeMarche');
            });
        },
        { deep: true }
      );
    }, 50);
  }

  //#endregion

  //#region COMPUTED
  get etudeDeMarche(): EtudeDeMarcheDto {
    return this.mission.prestationExpertise as EtudeDeMarcheDto;
  }

  set etudeDeMarche(value: EtudeDeMarcheDto) {
    this.mission.prestationExpertise = value;
  }

  get color(): string {
    return '#00ab9d';
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  //#endregion

  //#region FUNCTIONS
  protected cleanBeforeSaveInStorage(missionToClean: MissionDto): void {}

  protected async cleanBeforeSave(): Promise<void> {}

  protected touchDatas(): boolean {
    this.$v.mission.prestationExpertise.$touch();
    this.$v.mission.prestationExpertise.annexes.$touch();

    return this.$v.mission.prestationExpertise.$invalid || this.$v.mission.prestationExpertise.annexes.$invalid;
  }

  public initComponentSections(): void {
    this.componentsSections = [
      {
        slug: 'edm',
        name: 'Etude de marché',
        isOnlyForDesktop: false,
        children: [
          {
            slug: 'rapport-proprietes',
            name: 'propriétés',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseProprietes',
            properties: {
              proprietes: this.etudeDeMarche.proprietes as PrestationExpertiseProprietesDto,
              prestationExpertiseId: this.etudeDeMarche.id,
              isReadOnly: false,
            },
            permission: 'SavePropriete|Rapport',
          },
        ],
        permission: 'SavePropriete|Rapport',
      },
      {
        slug: 'contrat',
        name: 'contrat',
        children: [
          {
            slug: 'contrat-mission',
            name: 'mission',
            isActive: true,
            componentName: 'PrestationExpertiseEtudeDeMarcheContratMission',
          },
        ],
        validationName: 'contratGroup',
      },
      {
        slug: 'situation-geographique',
        name: 'situation géographique',
        children: [
          {
            slug: 'situation-geographique-plans-de-situation',
            name: 'plans de situation',
            componentName: 'PrestationExpertiseEtudeDeMarcheSituationGeographiquePlansDeSituation',
          },
        ],
        validationName: 'situationGeographiqueGroup',
      },
      {
        slug: 'rapport',
        name: 'rapport',
        children: [
          {
            slug: 'modele',
            name: 'modèle de rapport',
            componentName: 'PrestationExpertiseEtudeDeMarcheModeleRapport',
          },
          {
            slug: 'documents',
            name: 'rapport',
            componentName: 'PrestationExpertiseEtudeDeMarcheRapport',
          },
        ],
        validationName: 'rapportGroup',
      },
    ];
  }

  //#endregion
}
