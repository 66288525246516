







































import EtatAvancementSectionMixin from '@/mixins/etat-avancement-travaux-section.mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
@Component({
  name: 'PrestationExpertiseEtatAvancementTravauxConclusion',
  components: {
    RadioBoolean,
  },
})
export default class PrestationExpertiseEtatAvancementTravauxConclusion extends Mixins(EtatAvancementSectionMixin) {
  public isHorsEau: boolean = false;

  //#region LIFE CYCLES
  public beforeMount(): void {
    this.isHorsEau = this.mission.motifPrestationExpertise === "Hors d'eau";
  }

  public mounted(): void {
    this.updateStyleRange();
  }
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  @Watch('etatAvancementTravaux.etatAvancement')
  public etatAvancementChanged(): void {
    this.updateStyleRange();
  }
  //#endregion
  //#region FUNCTIONS
  private updateStyleRange(): void {
    var range: HTMLElement = (this.$refs.range as any).$el as HTMLElement;
    range.style.backgroundSize = `${this.etatAvancementTravaux.etatAvancement}% 100%`;
  }
  //#endregion
  //#region EVENTS
  //#endregion
}
