







import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubjectMessage } from '../models/subject-message';
import { SubjectMessageTypeEnum } from '../enums/subject-message-type.enum';
import { first } from 'rxjs/operators';
import store from '../store';
@Component({
  name: 'BackButton',
})
export default class BackButton extends Vue {
  @Prop() public title?: string;
  @Prop() public backUrl?: string;
  @Prop() public defaultBackUrl?: string;

  //#region LIFE CYCLES
  public beforeMount(): void {
    Vue.prototype.$subjectMessageService.subject.pipe(first()).subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.BACK) {
        this.onBackButtonClick();
      }
    });
  }
  //#endregion

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onBackButtonClick(): void {
    const backUrl = this.getBackUrl();
    if (backUrl.indexOf('connexion') !== -1 && store.isLogged) {
      this.$router.push('/agenda');
      return;
    }
    this.$router.push(this.backUrl ? this.backUrl : backUrl ? backUrl : this.defaultBackUrl);
  }

  //#endregion

  //#region FUNCTIONS
  private getBackUrl(): string {
    const backUrlHistory = Vue.prototype.$localStorageService.getBackUrlHistory() as any[];

    return backUrlHistory.length ? backUrlHistory[backUrlHistory.length - 1].from : null;
  }
  //#endregion
}
