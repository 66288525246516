import { MissionDto } from '@/shared/dtos/mission.dto';
import { NatureBienEnum } from '@/shared/enums/nature-bien.enum';

const CATEGORY_MAISON = 1;
const CATEGORY_APPARTEMENT = 2;
const CATEGORY_TERRAIN = 3;

const getTypeDeBien = function (prestationExpertise: any, businessEntries: any) {
  let actifObj = businessEntries.contratCategorieActifs.filter((c: any) => c.id == prestationExpertise.contratCategorieDActifId)[0];
  let actif = actifObj ? actifObj.displayText.toLowerCase() : '';
  let typeObj = businessEntries.contratTypeBiens.filter((t: any) => t.id == prestationExpertise.contratTypeBienId)[0];
  let type = typeObj ? typeObj.displayText.toLowerCase() : '';

  // conditions sur les anciens noms (pre-immoprod)
  if (actif.includes('appart') || type.includes('appart')) {
    return CATEGORY_APPARTEMENT;
  }

  // conditions sur les nouveaux noms
  if (actif.includes('collectif') || (actif.includes('tourisme') && actif.includes('services'))) {
    return CATEGORY_APPARTEMENT;
  }

  // dans une V1 on considère qu'un immeuble est comme un appartement
  if (actif.includes('immeuble')) {
    return CATEGORY_APPARTEMENT;
  }

  if (actif.includes('terrain') || actif.includes('terres') || prestationExpertise.contratCategorieDActifId === NatureBienEnum.ParkingBox) {
    return CATEGORY_TERRAIN;
  }

  // on considère que tout ce qui n'est pas Appartement est Maison
  return CATEGORY_MAISON;
};

const isTypeDeBienAppartement = function (prestationExpertise: any, businessEntries: any): boolean {
  return getTypeDeBien(prestationExpertise, businessEntries) === CATEGORY_APPARTEMENT;
};

const isTypeDeBienMaison = function (prestationExpertise: any, businessEntries: any): boolean {
  return getTypeDeBien(prestationExpertise, businessEntries) === CATEGORY_MAISON;
};

const isTypeDeBienTerrain = function (prestationExpertise: any, businessEntries: any): boolean {
  return getTypeDeBien(prestationExpertise, businessEntries) === CATEGORY_TERRAIN;
};

const hasFourchetteValeur = function (mission: MissionDto): boolean {
  return mission.hasFourchetteValeur === true;
};

const hasValeurVenteForcee = function (mission: MissionDto): boolean {
  return mission.hasValeurVenteForcee === true;
};

const hasValeurVenteRapide = function (mission: MissionDto): boolean {
  return mission.hasValeurVenteRapide === true;
};

export {
  getTypeDeBien,
  isTypeDeBienAppartement,
  isTypeDeBienTerrain,
  isTypeDeBienMaison,
  hasFourchetteValeur,
  hasValeurVenteForcee,
  hasValeurVenteRapide,
};
