export enum EtapeMissionEnum {
  EnAttenteDePieces = 55420,
  NonAttribuee = 47489,
  AttribueeALExpert = 1001341,
  ValeursAViserVisa1 = 1001344,
  ValeursAViserVisa2 = 2910112,
  ExpertiseCompleteAViser = 1001342,
  ValeursARevoir = 1001936,
  ValeursVisees = 1001343,
  ExpertiseCompleteValidee = 47490,
  ExpertiseVerrouillee = 1001937,
  Abandonnee = 47518,
  EtapeTemp = 2935105,
  Validee = 1001813,
}
