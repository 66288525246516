export enum TypePrestationExpertiseEnum {
  CertificatExpertise = 2937051,
  EtatAvancementTravaux = 2937052,
  ExpertiseImmobiliereCFE = 2937053,
  ExpertiseCKV = 2937054,
  RapportExpertise = 2937055,
  ReceptionLot = 2937056,
  ValeurSpot = 2937057,
  ValeurSpotPlus = 2937058,
  VisiteComplementaire = 2937732,
  EtudeMarche = 2912587,
}
