export enum PointInspectionCategorieEnum {
  MenuiseriesExterieures,
  Electricite,
  Plomberie,
  Chauffage,
  Isolation,
  Finitions,
  GrosOeuvre,
  Raccordement,
}
