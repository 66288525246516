































































































































































import BasicLayout from './layouts/basic.layout.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import HeaderTitle from '@/shared/components/header-title.vue';
import { NatureBien } from '@/shared/models/nature-bien';
import { Label } from '@/shared/models/label';
import { RechercheMissionDto } from '@/shared/dtos/recherche-mission.dto';
import Suggestions from 'v-suggestions';
import 'v-suggestions/dist/v-suggestions.css';
import store from '@/shared/store';
import { BTable } from 'bootstrap-vue';
import { SortAriaLabelDirective } from '@/shared/directives/sort-aria-label.directive';
import { RechercheLocalisationMissionDto } from '@/shared/dtos/recherche-localisation-mission.dto';
import { addressInputChange, geocode } from '@/shared/utilities/google-maps.utility';
import { ResultatRechercheMissionDto } from '@/shared/dtos/resultat-recherche-mission.dto';
import { required } from 'vuelidate/lib/validators';
Vue.directive('sort-aria-label', SortAriaLabelDirective);

@Component({
  name: 'Recherche',
  components: {
    BasicLayout,
    HeaderTitle,
    Suggestions,
  },
  validations: {
    model: {
      duree: {
        required,
      },
    },
  },
})
export default class Recherche extends Vue {
  public model: RechercheMissionDto = new RechercheMissionDto();
  public naturesBiens: NatureBien[] = [
    {
      id: 0,
      title: '<Toutes natures du bien>',
      types: [],
    },
  ];
  public typesBiens: Label[] = [
    {
      id: 0,
      title: '<Toutes types du bien>',
    },
  ];

  public optionsSuggestions: any = {
    placeholder: 'Saisissez une adresse, une ville, un département ou bien une région',
    inputClass: 'v-suggestions-input fs-1 recherche-component-form-localisation',
  };

  public localisation: any = '';
  public apiKey: string = Vue.prototype.$config.googleMapApiKey;
  public googleMapsApi: any = null;
  public autoCompleteService: any = null;
  public geocoderService: any = null;
  public sessionToken: any = null;

  public missions: ResultatRechercheMissionDto[] = [];
  public fields: any[] = [
    {
      key: 'reference',
      label: 'Référence',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'emprunteur',
      label: 'Emprunteur',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'natureBienString',
      label: 'Nature du bien',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'typeBienString',
      label: 'Type de bien',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'adresse',
      label: 'Adresse',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'dateRestitution',
      label: 'Date de restitution',
      sortable: true,
      class: 'text-left',
    },
    {
      key: 'valeurMin',
      label: 'Valeur Min',
      sortable: true,
      class: 'text-right',
    },
    {
      key: 'valeurMax',
      label: 'Valeur Max',
      sortable: true,
      class: 'text-right',
    },
    {
      key: 'surfaceHabitable',
      label: 'Surf. habitable',
      sortable: true,
      class: 'text-right',
    },
    {
      key: 'surfaceTerrain',
      label: 'Surf. du terrain',
      sortable: true,
      class: 'text-right',
    },
    {
      key: 'hasPieceJointeRapport',
      label: '',
      sortable: false,
    },
  ];
  public perPage = 10;
  public currentPage = 1;

  //#region LIFE CYCLES

  public beforeMount(): void {
    this.naturesBiens.push(...Vue.prototype.$bienService.getBiens());
  }

  public mounted(): void {
    this.sessionToken = new Vue.prototype.$googleMapsApi.places.AutocompleteSessionToken();
    this.autoCompleteService = new Vue.prototype.$googleMapsApi.places.AutocompleteService();
    this.geocoderService = new Vue.prototype.$googleMapsApi.Geocoder();
  }

  //#endregion

  //#region COMPUTED
  get rows(): number {
    return this.missions.length;
  }
  //#endregion

  //#region WATCH

  public async onLocalisationInputChange(value: string): Promise<string[]> {
    return await addressInputChange(value, this.autoCompleteService, this.sessionToken);
  }
  //#endregion

  //#region EVENTS

  public onNatureBienChange(natureBienId: number): void {
    this.typesBiens = [
      {
        id: 0,
        title: '<Toutes types du bien>',
      },
    ];
    this.model.typeBienId = 0;

    if (natureBienId !== 0) {
      this.typesBiens.push(...Vue.prototype.$bienService.getTypesBiensByNatureBienId(natureBienId));
    }
  }

  public onLocalisationSelected(value: any): any {
    this.geocode({ address: value }).then(this.setLocalisationValue);
  }

  public onLocalisationClear(): void {
    this.localisation = null;
  }

  public onRechercheSubmit(event: any): void {
    event.preventDefault();

    if (this.$v.model.$invalid) {
      this.$v.model.$touch();
      return;
    }

    store.isLoading = true;
    Vue.prototype.$missionApiService
      .search(this.model)
      .then((missions: ResultatRechercheMissionDto[]) => {
        this.missions = missions;
        (this.$refs.missions as BTable).refresh();
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public onRapportClick(expertiseId: number): void {
    store.isLoading = true;
    Vue.prototype.$expertiseApiService
      .getRapportById(expertiseId)
      .then((response: any) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        var blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  //#endregion

  //#region FUNCTIONS

  public geocode(parameters: any): any {
    return geocode(this.geocoderService, parameters);
  }

  private setLocalisationValue(place: any): void {
    this.localisation = place.formatted_address;
    this.model.localisation = this.mapPlaceGoogleMapsToLocalisation(place);
  }

  private mapPlaceGoogleMapsToLocalisation(place: any): RechercheLocalisationMissionDto {
    const adresseGoogleMapsForm: any = {
      street_number: 'short_name',
      route: 'long_name',
      postal_code: 'short_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
    };

    const result: any = [];
    for (const address of place.address_components) {
      const addressType: any = address.types[0];
      if (adresseGoogleMapsForm[addressType]) {
        result[addressType] = address[adresseGoogleMapsForm[addressType]];
      }
    }

    let localisation: RechercheLocalisationMissionDto = new RechercheLocalisationMissionDto();

    if (result.street_number) {
      localisation.numero = result.street_number;
    }

    if (result.route) {
      localisation.voie = result.route;
    }

    if (result.postal_code) {
      localisation.codePostal = result.postal_code;
    }

    if (result.locality) {
      localisation.ville = result.locality;
    }

    if (result.administrative_area_level_1) {
      localisation.region = result.administrative_area_level_1;
    }

    return localisation;
  }

  //#endregion
}
