/* eslint-disable no-console */
import { Component, Mixins, Vue } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import store from '@/shared/store';
import moment from 'moment';
import { orderBy } from 'lodash';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import _ from 'lodash';
import { getJoursOuvres } from '@/shared/utilities/date.utility';
import axios from 'axios';

@Component({})
export default class Agenda extends Mixins(DeviceMixin) {
  public missionsPlanifiees: MissionDto[] = [];
  public missionsAPlanifier: MissionDto[] = [];
  public missionsDuJour: MissionDto[] = [];
  public missionsDelaiOK: MissionDto[] = [];
  public missionsHorsDelais: MissionDto[] = [];
  public missionsHorsDelaisEtContactPris: MissionDto[] = [];
  public lastPullDate: string = null;
  public isLoading: boolean = false;
  public targetedMissionId: number;

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    const missions: MissionDto[] = await Vue.prototype.$missionStoreService.getAll();

    this.setMissionsPlanifiees(missions);
    await this.setMissionsAPlanifier(missions);

    const lastPullDate = Vue.prototype.$localStorageService.getLastPullDate();
    if (lastPullDate) {
      this.lastPullDate = moment(lastPullDate).calendar();
    }
  }

  //#endregion

  //#region EVENTS
  public onCardMissionClick(missionId: number) {
    this.targetedMissionId = missionId;
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  protected setMissionsPlanifiees(missions: MissionDto[]) {
    if (missions && missions.length) {
      const missionsPlanifies = missions.filter((mission: MissionDto) => mission !== null && mission.dateVisite !== null);

      this.missionsPlanifiees = orderBy(missionsPlanifies, (mission: MissionDto) => mission.dateVisite, 'asc');
    }
  }

  protected async setMissionsAPlanifier(missions: MissionDto[]) {
    if (missions && missions.length) {
      const missionsAPlanifier = missions.filter((mission: MissionDto) => mission !== null && mission.dateVisite === null);
      this.missionsAPlanifier = [];
      this.missionsAPlanifier = orderBy(missionsAPlanifier, (mission: MissionDto) => mission.reference, 'asc');
      await this.trierMissionsAPlanifier();
    }
  }

  protected async trierMissionsAPlanifier() {
    this.missionsDuJour = [];
    this.missionsDelaiOK = [];
    this.missionsHorsDelais = [];
    this.missionsHorsDelaisEtContactPris = [];

    let joursFeries: string[] = [];
    await axios
      .all([
        Vue.prototype.$jourFiereService.getAll().then((json: any) => {
          return json;
        }),
      ])
      .then((result) => {
        joursFeries = result[0];
      });

    this.missionsAPlanifier.forEach(async (item) => {
      const days = getJoursOuvres(item.commande.dateCommande, new Date(), joursFeries); // <= 3;

      if (days == 0) {
        this.missionsDuJour.push(item);
      } else {
        // 72 heures == 3 jours
        if (days <= 3) {
          this.missionsDelaiOK.push(item);
        } else {
          if (item.emailsCommandes.length > 0) {
            this.missionsHorsDelaisEtContactPris.push(item);
          } else {
            this.missionsHorsDelais.push(item);
          }
        }
      }
    });
  }

  protected async getDonneesTechniques(): Promise<void> {
    await Vue.prototype.$logStoreService.info('Synchronisation des données techniques depuis le serveur - START', 'Synchro');

    this.$bvModal.show('modal-pull-donnees-techniques');
    this.isLoading = true;

    await Vue.prototype.$synchronizationService.getDonneesTechniques().finally(() => {
      this.$bvModal.hide('modal-pull-donnees-techniques');
      this.isLoading = false;
    });
  }

  protected async getDonneesAgenda(): Promise<void> {
    const logMessage = `Synchronisation des données de l'agenda depuis le serveur`;
    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'Synchro');

    this.$bvModal.show('modal-pull-donnees-agenda');
    this.isLoading = true;

    const storedMissions: MissionDto[] = await Vue.prototype.$missionStoreService.getAll();

    // To compare to server in order to not get all prestationsExpertises if already up to date
    let datesModificationsPrestationsExpertisesAndMissionsIds = storedMissions.map((storedMission: MissionDto) => {
      if (
        storedMission.prestationExpertise &&
        storedMission.prestationExpertise.dateModificationUtc &&
        storedMission.prestationExpertise.dateModificationUtc.toString() !== '0001-01-01T00:00:00+00:00'
      ) {
        return {
          missionId: storedMission.id,
          dateUtc: storedMission.prestationExpertise.dateModificationUtc,
        };
      }
    });

    datesModificationsPrestationsExpertisesAndMissionsIds = datesModificationsPrestationsExpertisesAndMissionsIds.filter(
      (dateModificationUtcPrestationExpertise: any) => dateModificationUtcPrestationExpertise
    );

    await Vue.prototype.$missionApiService
      .getByOmereContactId(store.user.omereContactId, datesModificationsPrestationsExpertisesAndMissionsIds)
      .then(async (missions: MissionDto[]) => {
        // Save all Missions into LocalStorage
        await this.saveMissionsToStorage(missions);

        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'Agenda');

        missions = await Vue.prototype.$missionStoreService.getAll();
        this.setMissionsPlanifiees(missions);
        this.setMissionsAPlanifier(missions);

        await Vue.prototype.$logStoreService.info("Sauvegarde des missions affectées dans l'IndexedDB", 'Agenda');

        // Set last refresh date in localstorage
        const lastPullDate = moment();
        this.lastPullDate = lastPullDate.calendar();

        Vue.prototype.$localStorageService.setLastPullDate(lastPullDate);
        await Vue.prototype.$logStoreService.info('Sauvegarde de la dernière date de synchronisation dans le LocalStorage', 'Agenda');
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'Agenda');
      })
      .finally(() => {
        this.$bvModal.hide('modal-pull-donnees-agenda');
        this.isLoading = false;
      });
  }

  private async saveMissionsToStorage(missionsToStore: MissionDto[]): Promise<void> {
    // Set missionsToStore as already saved to avoid these missions to be push while the're new and just pulled
    missionsToStore.forEach((missionToStore: MissionDto, index: number) => {
      if (missionToStore.prestationExpertise) {
        missionToStore.prestationExpertise.dateDeSauvegardeUtc = missionToStore.prestationExpertise.dateModificationUtc;
      }
    });

    // Get all the stored Missions
    let storedMissions: MissionDto[] = await Vue.prototype.$missionStoreService.getAll();

    let storedMissionsToDelete: MissionDto[] = [];

    if (storedMissions && storedMissions.length) {
      // Delete missions wich doesn't exists anymore. What come from the server represent all of existing missions
      storedMissionsToDelete = storedMissions.filter(
        (storedMission: MissionDto) => !missionsToStore.map((missionToStore: MissionDto) => missionToStore.id).includes(storedMission.id)
      );

      storedMissions = storedMissions.filter((storedMission: MissionDto) =>
        missionsToStore.map((missionToStore: MissionDto) => missionToStore.id).includes(storedMission.id)
      );

      // Add new missions to stored mission if they doesn't exists or if they are more recent
      missionsToStore.forEach((missionToStore: MissionDto, index: number) => {
        // If the mission to store has etape === EtapeMissionEnum.AttribueeALExpert, it has a prestationExpertise and it need to be compared with an eventually stored prestationExpertise
        if (missionToStore.etape === EtapeMissionEnum.AttribueeALExpert) {
          const storedExistingMission = storedMissions.find(
            (storedMissionAffectee: MissionDto) => missionToStore.id === storedMissionAffectee.id
          );

          // If we locally have the prestationExpertise
          if (storedExistingMission) {
            if (missionToStore.prestationExpertise && storedExistingMission.prestationExpertise) {
              // If the prestationsExpertises to compare have a dateModificationUtc
              if (
                missionToStore.prestationExpertise.dateModificationUtc &&
                storedExistingMission.prestationExpertise.dateModificationUtc &&
                missionToStore.prestationExpertise.dateModificationUtc.toString() !== '0001-01-01T00:00:00+00:00' &&
                storedExistingMission.prestationExpertise.dateModificationUtc.toString() !== '0001-01-01T00:00:00+00:00'
              ) {
                // If the local based prestationExpertise is more recent than the one wich come from the server, keep it
                if (
                  moment(storedExistingMission.prestationExpertise.dateModificationUtc).valueOf() >
                  moment(missionToStore.prestationExpertise.dateModificationUtc).valueOf()
                ) {
                  missionsToStore[index].prestationExpertise = storedExistingMission.prestationExpertise;
                  missionsToStore[index].prestationExpertise.dateDeSauvegardeUtc =
                    missionsToStore[index].prestationExpertise.dateModificationUtc;
                } else {
                  // Case of piecesJointes. If we locally have the prestationExpertise, and this one have piecesJointes, they are stored in base64 format, so we need to keep this format instead of url because we're working disconnected
                  // However we store there other properties
                  for (let sectionPieceJointe in missionToStore.prestationExpertise.piecesJointes) {
                    const missionToStorePiecesJointes = missionToStore.prestationExpertise.piecesJointes[sectionPieceJointe];
                    const storedExistingMissionPiecesJointes = storedExistingMission.prestationExpertise.piecesJointes[sectionPieceJointe];

                    if (
                      missionToStorePiecesJointes &&
                      storedExistingMissionPiecesJointes &&
                      missionToStorePiecesJointes.length &&
                      storedExistingMissionPiecesJointes.length
                    ) {
                      for (const missionToStorePieceJointe of missionToStorePiecesJointes) {
                        const storedExistingMissionPieceJointe = storedExistingMissionPiecesJointes.find(
                          (pieceJointe: PrestationExpertisePieceJointeDto) => pieceJointe.id === missionToStorePieceJointe.id
                        );

                        if (storedExistingMissionPieceJointe) {
                          missionToStorePieceJointe.content = storedExistingMissionPieceJointe.content;
                        }
                      }
                    }
                  }
                }
              }
            } else if (!missionToStore.prestationExpertise && storedExistingMission.prestationExpertise) {
              missionsToStore[index].prestationExpertise = storedExistingMission.prestationExpertise;
            }

            if (new Date(missionToStore.dateModification) < new Date(storedExistingMission.dateModification)) {
              const tempPrestationExpertise = missionsToStore[index].prestationExpertise;

              missionsToStore[index] = storedExistingMission;
              missionsToStore[index].prestationExpertise = tempPrestationExpertise;
            }
          }
        }
      });
    }

    // Update local storage
    // This condition to prevent cleanning IDB when opening a PrestationExpertise wich need IDB (click on tile)
    if (this.targetedMissionId === undefined) {
      for (let storedMissionToDelete of storedMissionsToDelete) {
        await Vue.prototype.$missionStoreService.deleteByPropertyAndValue('id', storedMissionToDelete.id);
      }

      await Vue.prototype.$missionStoreService.addBulk(missionsToStore);
    }
  }

  //#endregion
}
