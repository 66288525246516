/* eslint-disable no-console */
/* eslint-disable no-alert */

import { register } from 'register-service-worker';
import Vue from 'vue';
import store from '@/shared/store';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('[APP] - App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered() {
      console.log('[APP] - Service worker has been registered.');
    },
    cached() {
      console.log('[APP] - Content has been cached for offline use.');
    },
    updatefound() {
      // https://stackoverflow.com/a/60309577/17072923
      // new content clear cache so user gets the new version
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
      console.log('[APP] - New content is downloading.');
    },
    updated(registration) {
      if (registration == null || registration.waiting == null) return;

      const installingWorker = registration.waiting;
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'activated') {
          if (Vue.prototype.$config.isLogOutDuringMep && store.isLogged) {
            console.log('[APP] - Session is disconnecting...');
            Vue.prototype.$authentificationService.logout();
          }
        }
      };

      console.log('[APP] - Service worker has been updated.');
      let message = `Une mise à jour est disponible. L’application va être rechargée.`;
      if (Vue.prototype.$config.isLogOutDuringMep && store.isLogged) {
        message = `Une mise à jour est disponible. L’application va être rechargée et vous allez être déconnecté(e).`;
      }

      alert(message);
      registration.waiting.postMessage({ action: 'SKIP_WAITING' });
    },
    offline() {
      console.log('[APP] - No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('[APP] - Error during service worker registration:', error);
    },
  });

  var preventDevToolsReloadLoop: boolean;
  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', (event) => {
      // Ensure refresh is only called once.
      // This works around a bug in "force update on reload".
      if (preventDevToolsReloadLoop) return;
      preventDevToolsReloadLoop = true;
      window.location.reload();
    });
  }
}
