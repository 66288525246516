import { Component, Vue, Prop } from 'vue-property-decorator';
const Color = require('color');

@Component({
  name: 'ThemeMixin',
})
export default class ThemeMixin extends Vue {
  @Prop() public color: string;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get cssVars(): any {
    return {
      '--themeColor': this.colorObject,
      '--lightenThemeColor': this.colorObject.lighten(0.2).toString(),
      '--darkenThemeColor': this.colorObject.darken(0.2).toString(),
    };
  }

  get colorObject(): any {
    return Color(this.color);
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
