












import EtatAvancementSectionMixin from '@/mixins/etat-avancement-travaux-section.mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import PointInspection from '@/shared/components/point-inspection.vue';
import { PrestationExpertisePointInspectionDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-point-inspection.dto';
import { PrestationExpertisePointInspectionRelationDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-point-inspection-relation.dto';

@Component({
  name: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
  components: {
    PointInspection,
  },
})
export default class PrestationExpertiseEtatAvancementTravauxPointInspection extends Mixins(EtatAvancementSectionMixin) {
  @Prop() nom: string;
  @Prop() pointsInspections: PrestationExpertisePointInspectionDto[];
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  public getPointsInspectionsRelations(id: number): PrestationExpertisePointInspectionRelationDto {
    return this.etatAvancementTravaux.pointsInspectionsRelations.find((x) => x.pointInspectionId === id);
  }

  public getState(id: number): any {
    const index = this.etatAvancementTravaux.pointsInspectionsRelations.findIndex((x) => x.pointInspectionId === id);
    return !this.validation.mission.prestationExpertise.pointsInspectionsRelations.$each[index].etat.$error;
  }
  //#endregion
}
