























































































import BasicLayout from './layouts/basic.layout.vue';
import { SortAriaLabelDirective } from '@/shared/directives/sort-aria-label.directive';
import { Component, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/shared/components/header-title.vue';
import store from '@/shared/store';
import moment from 'moment';
import { BTable } from 'bootstrap-vue';
import { MissionForListDto } from '@/shared/dtos/liste-mission.dto';

Vue.directive('sort-aria-label', SortAriaLabelDirective);

@Component({
  name: 'Missions',
  components: {
    BasicLayout,
    HeaderTitle,
  },
})
export default class Missions extends Vue {
  public store = store;
  public missions: MissionForListDto[] = null;
  public selection: string = 'last';

  public filters: any = {
    reference: '',
    dateCommande: '',
    dateAffectation: '',
    societeNom: '',
    dateVisite: '',
    typePrestationExpertiseLibelle: '',
    emprunteur: '',
    etapeLibelle: '',
    adresse: '',
    valeurMin: '',
    valeurMax: '',
    commentaire: '',
  };

  public fields: any = [
    {
      key: 'reference',
      label: 'Référence',
      sortable: true,
    },
    {
      key: 'dateCommande',
      label: 'Date de la commande',
      sortable: true,
    },
    {
      key: 'dateAffectation',
      label: `Date d'affectation`,
      sortable: true,
    },
    {
      key: 'societeNom',
      label: 'Client',
      sortable: true,
    },
    {
      key: 'dateVisite',
      label: 'Date de RDV',
      sortable: true,
    },
    {
      key: 'typePrestationExpertiseLibelle',
      label: 'Objet',
      sortable: true,
    },
    {
      key: 'emprunteur',
      label: 'Emprunteur',
      sortable: true,
    },
    {
      key: 'etapeLibelle',
      label: 'Etat',
      sortable: true,
    },
    {
      key: 'adresse',
      label: 'Adresse',
      sortable: true,
    },
    {
      key: 'valeurMin',
      label: 'Valeur min',
      sortable: true,
    },
    {
      key: 'valeurMax',
      label: 'Valeur max',
      sortable: true,
    },
    {
      key: 'notesVisite',
      label: 'Notes pour la visite',
      sortable: true,
    },
    {
      key: 'openMission',
      label: '',
      sortable: false,
      formatter: (value: any, key: any, item: MissionForListDto) => {
        return '/missions/' + item.id + '/' + item.typePrestationExpertiseString;
      },
    },
  ];

  public perPage = 10;
  public currentPage = 1;

  //#region LIFE CYCLES
  beforeMount(): void {
    this.missions = this.$route.meta.missions as MissionForListDto[];
  }
  //#endregion

  //#region COMPUTED
  get rows(): number {
    return this.filtered.length;
  }

  get filtered(): MissionForListDto[] {
    const filtered = this.missions.filter((item: any) => {
      return Object.keys(this.filters).every((key) => {
        let itemValue = item[key];

        switch (key) {
          case 'dateAffectation':
          case 'dateVisite':
          case 'dateCommande':
            itemValue = moment(String(itemValue)).format('DD/MM/YYYY');
            break;
          case 'notesVisite':
            if (itemValue === null) itemValue = '';
        }

        if (itemValue === null || itemValue === undefined) return true;

        return itemValue.toString().toLowerCase().includes(this.filters[key].toLowerCase());
      });
    });

    return filtered.length > 0 ? filtered : [];
  }
  //#endregion

  //#region EVENTS
  public onSelectionChange(): void {
    store.isLoading = true;
    Vue.prototype.$missionForListApiService
      .getLastOrAllByOmereContactId(store.user.omereContactId, this.selection)
      .then((missions: MissionForListDto[]) => {
        this.missions = missions;
        (this.$refs.missions as BTable).refresh();
      })
      .finally(() => {
        store.isLoading = false;
      });
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
