















































import { Vue, Component, Mixins, Prop, Watch, Inject } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import VueGallery from 'vue-gallery';
import _ from 'lodash';
import axios from 'axios';
import PrestationExpertiseFileUploader from './prestation-expertise-file-uploader.vue';
import { TypePrestationExpertisePieceJointeEnum } from '@/shared/enums/type-prestation-expertise-piece-jointe.enum';
import { urlToData } from '@/shared/utilities/url.utility';

@Component({
  name: 'PrestationExpertisePlanCadastre',
  components: {
    PrestationExpertiseFileUploader,
    Spinner,
    VueGallery,
  },
})
export default class PrestationExpertisePlanCadastre extends Mixins(DeviceMixin) {
  @Inject('addPieceJointe') public addPieceJointe: any;
  @Inject('deletePieceJointeByIdAndType') public deletePieceJointeByIdAndType: any;

  private codeInsee: number;
  private type = TypePrestationExpertisePieceJointeEnum[TypePrestationExpertisePieceJointeEnum.PlanCadastre];

  @Prop() public latitude: number;
  @Prop() public longitude: number;
  @Prop() public piecesJointes: any;
  @Prop() public state: any;
  @Prop() public zoom: number;

  public cadastreUrl: string = '';
  public cadastreBaseUrl: string = Vue.prototype.$config.cadastreBaseUrl;

  public zoomOptions = [
    { text: 'Large', value: 0.001 },
    { text: 'Moyen', value: 0.00075 },
    { text: 'Rapproché', value: 0.0005 },
  ];
  public selectedZoom: number = 0.00075;
  public isManuallyDisplayed = false;

  //#region LIFE CYCLES

  public async mounted(): Promise<void> {
    if (this.zoom === null) {
      this.selectedZoom = this.zoomOptions[1].value;
      this.$emit('zoomPlanCadastreChanged', this.selectedZoom);
    } else {
      this.selectedZoom = this.zoom;
    }

    if (this.PlanCadastre.length === 0) {
      await this.getPlanCadastre();
    } else {
      var planCadastre = this.PlanCadastre[0];
      if (planCadastre.nom.indexOf('PlanCadastreInspire') >= 0) {
        this.cadastreUrl = `${Vue.prototype.$config.baseUrlApiAppService}${planCadastre.content}`;
      } else {
        this.isManuallyDisplayed = true;
      }
    }

    // watching multiple properties with a single handler
    // https://thewebdev.info/2021/05/22/how-to-watch-multiple-properties-with-a-single-handler-in-vue-js/
    this.$watch(
      () => [this.latitude, this.longitude],
      () => {
        if (!this.isManuallyDisplayed) {
          this.getPlanCadastre();
        }
      },
      {
        deep: true,
      }
    );
  }

  //#endregion

  //#region COMPUTED
  get PlanCadastre(): any {
    if (this.piecesJointes) return this.piecesJointes[this.type];

    return null;
  }
  //#endregion

  //#region WATCH
  @Watch('selectedZoom')
  public async selectedZoomChanged(): Promise<void> {
    if (this.zoom !== this.selectedZoom) {
      this.$emit('zoomPlanCadastreChanged', this.selectedZoom);
      await this.getPlanCadastre();
    }
  }

  //#endregion

  //#region EVENTS
  public onAddPlanCadastreManuallyClick(): void {
    this.cadastreUrl = null;
    this.isManuallyDisplayed = true;
    this.clearPieceJointePlanCadastre();
  }

  public async onAddPlanCadastreAutoClick(): Promise<void> {
    this.isManuallyDisplayed = false;
    this.clearPieceJointePlanCadastre();
    await this.getPlanCadastre();
  }
  //#endregion

  //#region FUNCTIONS

  private clearPieceJointePlanCadastre(): void {
    if (this.PlanCadastre.length > 0) {
      this.deletePieceJointeByIdAndType(this.PlanCadastre[0].id, this.type);
    }
    this.piecesJointes[this.type] = [];
  }

  private async getPlanCadastre(): Promise<void> {
    await axios
      .get(`https://geo.api.gouv.fr/communes?lat=${this.latitude}&lon=${this.longitude}&fields=nom,code&format=json&geometry=centre`)
      .then(async (response: any) => {
        this.codeInsee = response.data[0].code;
        await this.setCadastreUrl();
      })
      .catch(async (error: any) => {
        var logMessage = `Une erreur est survenue lors de la récupération du plan de cadastre.`;
        Vue.prototype.$notificationService.error(logMessage);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertisePlanCadastre');
      });
  }

  private async setCadastreUrl(): Promise<void> {
    const bbox = `${this.latitude - this.selectedZoom},${this.longitude - this.selectedZoom},${this.latitude + this.selectedZoom},${
      this.longitude + this.selectedZoom
    }`;

    if (this.codeInsee !== undefined) {
      this.cadastreUrl = `https://inspire.cadastre.gouv.fr/scpc/${this.codeInsee}.wms?service=wms&version=1.3&request=GetMap&layers=AMORCES_CAD,LIEUDIT,CP.CadastralParcel,BU.Building,CLOTURE,DETAIL_TOPO,HYDRO,VOIE_COMMUNICATION,BU.Building,BORNE_REPERE&format=image/png&crs=EPSG:4326&bbox=${bbox}&width=900&height=900&styles=`;

      urlToData(this.cadastreUrl, async (dataUrl: any) => {
        await this.addPieceJointe(this.type, dataUrl, 'PlanCadastreInspire.png');
      });
    }
  }
  //#endregion
}
