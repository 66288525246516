import { Dpe } from '../models/dpe.model';

export const DpeEnum: { [key: string]: Dpe } = {
  A: {
    id: 2059001,
    minusLabel: 'a',
    label: 'A',
    kWhLabel: '<= 70',
    CO2Label: '<= 6',
    color: '#006644',
  },
  B: {
    id: 2059002,
    minusLabel: 'b',
    label: 'B',
    kWhLabel: '71 à 110',
    CO2Label: '7 à 11',
    color: '#4eb353',
  },
  C: {
    id: 2059003,
    minusLabel: 'c',
    label: 'C',
    kWhLabel: '111 à 180',
    CO2Label: '12 à 30',
    color: '#a5cd75',
  },
  D: {
    id: 2059004,
    minusLabel: 'd',
    label: 'D',
    kWhLabel: '181 à 250',
    CO2Label: '31 à 50',
    color: '#f2e814',
  },
  E: {
    id: 2059005,
    minusLabel: 'e',
    label: 'E',
    kWhLabel: '251 à 330',
    CO2Label: '51 à 70',
    color: '#f0b511',
  },
  F: {
    id: 2059006,
    minusLabel: 'f',
    label: 'F',
    kWhLabel: '331 à 420',
    CO2Label: '71 à 100',
    color: '#ec8432',
  },
  G: {
    id: 2059007,
    minusLabel: 'g',
    label: 'G',
    kWhLabel: '>= 421',
    CO2Label: '>= 101',
    color: '#af1b19',
  },
};
