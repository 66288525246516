import { Component, Vue } from 'vue-property-decorator';
import { isBrowser, isTablet, isMobileOnly } from 'mobile-device-detect';

// isMobile	bool	returns true if device type is mobile or tablet
// isMobileOnly	bool	returns true if device type is mobile
// isTablet	bool	returns true if device type is tablet
// isBrowser	bool	returns true if device type is browser

@Component({
  name: 'ComponentsSectionsMenu',
})
export default class DeviceMixin extends Vue {
  public isBrowser = isBrowser || isTablet;
  public isMobile = isMobileOnly;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
