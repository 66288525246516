


































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';

import ValeurSpotPlusSectionMixin from '@/mixins/valeur-spot-plus-section.mixin';

import vueSlider from 'vue-slider-component';
import StarRating from 'vue-star-rating';
import Radios from '@/shared/components/radios.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import Decimal from 'decimal.js';
import { hasValidNumberValue } from '@/shared/utilities/type.utility';
import { hasFourchetteValeur, hasValeurVenteForcee, hasValeurVenteRapide } from '@/helpers/prestation-expertise.helper';
import { SubjectMessage } from '@/shared/models/subject-message';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';

const RATINGS: string[] = ['', 'Médiocre niveau', 'Faible niveau', 'Niveau moyen', 'Bon niveau', 'Très bon niveau'];
const RATING_SUFFIXE = ' de liquidité à la valeur vénale établie';

@Component({
  name: 'PrestationExpertiseValeurSpotPlusConclusionValeurs',
  components: {
    vueSlider,
    StarRating,
    Radios,
    NumericNullable,
  },
})
export default class PrestationExpertiseValeurSpotPlusConclusionValeurs extends Mixins(ValeurSpotPlusSectionMixin) {
  public currentRating: string = null;

  public percentFourchetteBasse: number = 0.975;
  public percentFourchetteHaute: number = 1.025;

  //#region LIFE CYCLES

  public beforeMount(): void {
    // fix bugs error for vue-slider : Cannot read properties of null (reading '0')
    if (this.isNull(this.valeurSpotPlus.valeurVenteForceeAbattement)) {
      this.valeurSpotPlus.valeurVenteForceeAbattement = 0;
    }

    if (this.isNull(this.valeurSpotPlus.valeurVenteRapideAbattement)) {
      this.valeurSpotPlus.valeurVenteRapideAbattement = 0;
    }
  }

  public mounted(): void {
    this.updateValeurs();
    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.REFRESH_VALEURS_DATA) {
        this.updateValeurs();
      }
    });
  }
  //#endregion
  //#region COMPUTED

  get valeurCalculee(): number {
    return new Decimal(this.valeurSpotPlus.reductionMetriqueTransaction || 0)
      .times(this.valeurSpotPlus.surfaceHabitable || 0)
      .floor()
      .toNumber();
  }

  get roundValeurCalculee(): number {
    return this.round(this.valeurCalculee);
  }

  get valeurCalculeeIsCalculable(): boolean {
    return (
      hasValidNumberValue(this.valeurSpotPlus.surfaceHabitable) && hasValidNumberValue(this.valeurSpotPlus.reductionMetriqueTransaction)
    );
  }

  get valeurCalculeeIsNotCalculableReason(): string {
    if (!this.valeurSpotPlus.surfaceHabitable) {
      return 'Surface totale habitable manquante';
    }

    if (!this.valeurSpotPlus.reductionMetriqueTransaction) {
      return 'Réduction métrique transaction retenue manquante';
    }

    return 'Information(s) manquante(s) pour calculer la valeur.';
  }

  get hasFourchetteValeur(): boolean {
    return hasFourchetteValeur(this.mission);
  }

  get hasValeurVenteForcee(): boolean {
    return hasValeurVenteForcee(this.mission);
  }

  get hasValeurVenteRapide(): boolean {
    return hasValeurVenteRapide(this.mission);
  }

  get currentSelectedRating(): string {
    return this.valeurSpotPlus.appreciationNiveauLiquidite > 0
      ? RATINGS[this.valeurSpotPlus.appreciationNiveauLiquidite] + RATING_SUFFIXE
      : '&nbsp;';
  }

  get sliderStyles() {
    var color = '#00ab9d';
    return {
      sliderProcessStyle: {
        backgroundColor: color,
      },
      sliderTooltipStyle: {
        backgroundColor: color,
        borderColor: color,
      },
    };
  }
  get abattementVenteRapideDisplaytext(): string {
    const percent = new Decimal(this.valeurSpotPlus.valeurVenteRapideAbattement || 0).times(100).toNumber();
    return `${percent} %`;
  }

  get abattementVenteForceeDisplaytext(): string {
    const percent = new Decimal(this.valeurSpotPlus.valeurVenteForceeAbattement || 0).times(100).toNumber();
    return `${percent} %`;
  }

  //#endregion
  //#region WATCH

  @Watch('valeurCalculee')
  public valeurCalculeeChanged(value: number): void {
    this.valeurSpotPlus.valeurVenale = value ?? 0;
  }

  @Watch('valeurSpotPlus.valeurVenale')
  public valeurVenaleChanged(): void {
    this.updateValeurVenaleArrondie();
    this.updateValeurVenaleFourchettesArrondies();
    this.updateValeurVenteRapide();
    this.updateValeurVenteForcee();
  }

  @Watch('valeurSpotPlus.valeurVenteRapideAbattement')
  public valeurVenteRapideAbattementChanged(): void {
    this.updateValeurVenteRapide();
    this.updateValeurVenteRapideArrondie();
    this.updateValeurVenteRapideFourchettesArrondies();
  }

  @Watch('valeurSpotPlus.valeurVenteRapide')
  public valeurVenteRapideChanged(newValue: number) {
    this.valeurSpotPlus.valeurVenteRapideArrondie = !this.hasFourchetteValeur && this.hasValeurVenteRapide ? this.round(newValue) : null;
    this.updateValeurVenteRapideFourchettesArrondies();
  }

  @Watch('valeurSpotPlus.valeurVenteForceeAbattement')
  public valeurVenteForceeAbattementChanged(): void {
    this.updateValeurVenteForcee();
    this.updateValeurVenteForceeArrondie();
    this.updateValeurVenteForceeFourchettesArrondies();
  }

  @Watch('valeurSpotPlus.valeurVenteForcee')
  public valeurVenteForceeChanged(newValue: number): void {
    this.valeurSpotPlus.valeurVenteForceeArrondie = !this.hasFourchetteValeur && this.hasValeurVenteForcee ? this.round(newValue) : null;
    this.updateValeurVenteForceeFourchettesArrondies();
  }

  //#endregion
  //#region EVENTS
  public onCurrentRatingChange(rating: number): void {
    if (rating === 0 ? this.currentSelectedRating : rating == null) {
      this.currentRating = '&nbsp;';
    } else {
      this.currentRating = RATINGS[rating] + RATING_SUFFIXE;
    }
  }

  public venteForceeChanged(value: number): void {
    if (value > this.valeurSpotPlus.valeurVenteForceeAbattement) this.valeurSpotPlus.valeurVenteForceeAbattement = value;
  }

  public venteRapideChanged(value: number) {
    if (value < this.valeurSpotPlus.valeurVenteRapideAbattement) this.valeurSpotPlus.valeurVenteRapideAbattement = value;
  }
  //#endregion
  //#region FUNCTIONS
  private round(value: number, at?: number): number {
    if (value === null || value === undefined) return 0;
    at = at || (value < 150000 ? 1000 : 5000);
    return Math.round(value / at) * at;
  }

  private calculValeur(value: Decimal.Value, percent: Decimal.Value): number {
    const p = new Decimal(1).minus(percent || 0);
    return new Decimal(value || 0).times(p).toNumber();
  }

  private updateValeurs(): void {
    this.updateValeurVenaleArrondie();
    this.updateValeurVenaleFourchettesArrondies();

    this.updateValeurVenteRapide();
    this.updateValeurVenteRapideArrondie();
    this.updateValeurVenteRapideFourchettesArrondies();

    this.updateValeurVenteForcee();
    this.updateValeurVenteForceeArrondie();
    this.updateValeurVenteForceeFourchettesArrondies();
  }

  public updateValeurVenaleArrondie(): void {
    if (!this.hasFourchetteValeur) {
      this.valeurSpotPlus.valeurVenaleArrondie = this.round(this.valeurSpotPlus.valeurVenale);
    } else {
      this.valeurSpotPlus.valeurVenaleArrondie = null;
    }
  }

  public updateValeurVenaleFourchettesArrondies(): void {
    if (this.hasFourchetteValeur) {
      this.valeurSpotPlus.valeurVenaleFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.valeurSpotPlus.valeurVenale,
        this.percentFourchetteBasse
      );
      this.valeurSpotPlus.valeurVenaleFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.valeurSpotPlus.valeurVenale,
        this.percentFourchetteHaute
      );
    } else {
      this.valeurSpotPlus.valeurVenaleFourchetteBasseArrondie = null;
      this.valeurSpotPlus.valeurVenaleFourchetteHauteArrondie = null;
    }
  }

  public updateValeurVenteRapide(): void {
    if (this.hasValeurVenteRapide) {
      var valeurReference = this.valeurSpotPlus.valeurVenale ?? 0;
      this.valeurSpotPlus.valeurVenteRapide = this.calculValeur(valeurReference, this.valeurSpotPlus.valeurVenteRapideAbattement);
    } else {
      this.valeurSpotPlus.valeurVenteRapide = null;
    }
  }

  public updateValeurVenteRapideArrondie(): void {
    if (!this.hasFourchetteValeur && this.hasValeurVenteRapide) {
      this.valeurSpotPlus.valeurVenteRapideArrondie = this.round(this.valeurSpotPlus.valeurVenteRapide);
    } else {
      this.valeurSpotPlus.valeurVenteRapideArrondie = null;
    }
  }

  public updateValeurVenteRapideFourchettesArrondies(): void {
    if (this.hasFourchetteValeur && this.hasValeurVenteRapide) {
      this.valeurSpotPlus.valeurVenteRapideFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.valeurSpotPlus.valeurVenteRapide,
        this.percentFourchetteBasse
      );
      this.valeurSpotPlus.valeurVenteRapideFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.valeurSpotPlus.valeurVenteRapide,
        this.percentFourchetteHaute
      );
    } else {
      this.valeurSpotPlus.valeurVenteRapideFourchetteBasseArrondie = null;
      this.valeurSpotPlus.valeurVenteRapideFourchetteHauteArrondie = null;
    }
  }

  public updateValeurVenteForcee(): void {
    if (this.hasValeurVenteForcee) {
      var valeurReference = this.valeurSpotPlus.valeurVenale ?? 0;
      this.valeurSpotPlus.valeurVenteForcee = this.calculValeur(valeurReference, this.valeurSpotPlus.valeurVenteForceeAbattement);
    } else {
      this.valeurSpotPlus.valeurVenteForcee = null;
    }
  }

  public updateValeurVenteForceeArrondie(): void {
    if (!this.hasFourchetteValeur && this.hasValeurVenteForcee) {
      this.valeurSpotPlus.valeurVenteForceeArrondie = this.round(this.valeurSpotPlus.valeurVenteForcee);
    } else {
      this.valeurSpotPlus.valeurVenteForceeArrondie = null;
    }
  }

  public updateValeurVenteForceeFourchettesArrondies(): void {
    if (this.hasFourchetteValeur && this.hasValeurVenteForcee) {
      this.valeurSpotPlus.valeurVenteForceeFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.valeurSpotPlus.valeurVenteForcee,
        this.percentFourchetteBasse
      );
      this.valeurSpotPlus.valeurVenteForceeFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.valeurSpotPlus.valeurVenteForcee,
        this.percentFourchetteHaute
      );
    } else {
      this.valeurSpotPlus.valeurVenteForceeFourchetteBasseArrondie = null;
      this.valeurSpotPlus.valeurVenteForceeFourchetteHauteArrondie = null;
    }
  }

  public calculValeurFourchetteArrondie(value: Decimal.Value, percent: Decimal.Value, roundAt?: number | undefined): number {
    return this.round(new Decimal(value || 0).times(percent || 0).toNumber(), roundAt);
  }

  private isNull(value: Decimal.Value): boolean {
    return value === null || value === undefined;
  }

  //#endregion
}
