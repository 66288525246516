






























































import { Component, Mixins, Prop } from 'vue-property-decorator';

import { ComponentSection } from '@/shared/models/component-section';
import PrestationExpertiseSectionMixin from '@/mixins/prestation-expertise-section.mixin';

//#region Rapport
// Rapport
import PrestationExpertiseRapportIndicateurs from '@/views/prestation-expertise/rapport/prestation-expertise-rapport-indicateurs.vue';
import PrestationExpertiseRapportProprietes from '@/views/prestation-expertise/rapport/prestation-expertise-rapport-proprietes.vue';
// Contrat
import PrestationExpertiseRapportContratMission from '@/views/prestation-expertise/rapport/contrat/prestation-expertise-rapport-contrat-mission.vue';
// Situation Geographique
import PrestationExpertiseRapportSituationGeographiqueGeographie from '@/views/prestation-expertise/rapport/situation-geographique/prestation-expertise-rapport-situation-geographique-geographie.vue';
import PrestationExpertiseRapportSituationGeographiqueAvisExpert from '@/views/prestation-expertise/rapport/situation-geographique/prestation-expertise-rapport-situation-geographique-avis-expert.vue';
import PrestationExpertiseRapportSituationGeographiqueControle from '@/views/prestation-expertise/rapport/situation-geographique/prestation-expertise-rapport-situation-geographique-controle.vue';
// Description Générale
import PrestationExpertiseRapportDescriptionGeneraleTerrain from '@/views/prestation-expertise/rapport/description-generale/prestation-expertise-rapport-description-generale-terrain.vue';
import PrestationExpertiseRapportDescriptionGeneraleAvisExpert from '@/views/prestation-expertise/rapport/description-generale/prestation-expertise-rapport-description-generale-avis-expert.vue';
import PrestationExpertiseRapportDescriptionGeneraleControle from '@/views/prestation-expertise/rapport/description-generale/prestation-expertise-rapport-description-generale-controle.vue';
// Description Particulière
import PrestationExpertiseRapportDescriptionParticuliereBatiment from '@/views/prestation-expertise/rapport/description-particuliere/prestation-expertise-rapport-description-particuliere-batiment.vue';
import PrestationExpertiseRapportDescriptionParticulierePrestations from '@/views/prestation-expertise/rapport/description-particuliere/prestation-expertise-rapport-description-particuliere-prestations.vue';
import PrestationExpertiseRapportDescriptionParticuliereSurface from '@/views/prestation-expertise/rapport/description-particuliere/prestation-expertise-rapport-description-particuliere-surface.vue';
import PrestationExpertiseRapportDescriptionParticuliereAvisExpert from '@/views/prestation-expertise/rapport/description-particuliere/prestation-expertise-rapport-description-particuliere-avis-expert.vue';
import PrestationExpertiseRapportDescriptionParticuliereControle from '@/views/prestation-expertise/rapport/description-particuliere/prestation-expertise-rapport-description-particuliere-controle.vue';
// Situation Fiscale
import PrestationExpertiseRapportSituationFiscaleFiscalite from '@/views/prestation-expertise/rapport/situation-fiscale/prestation-expertise-rapport-situation-fiscale-fiscalite.vue';
import PrestationExpertiseRapportSituationFiscaleControle from '@/views/prestation-expertise/rapport/situation-fiscale/prestation-expertise-rapport-situation-fiscale-controle.vue';
// Situation Juridique
import PrestationExpertiseRapportSituationJuridiqueJuridique from '@/views/prestation-expertise/rapport/situation-juridique/prestation-expertise-rapport-situation-juridique-juridique.vue';
import PrestationExpertiseRapportSituationJuridiqueControle from '@/views/prestation-expertise/rapport/situation-juridique/prestation-expertise-rapport-situation-juridique-controle.vue';
// Situation Urbanistique
import PrestationExpertiseRapportSituationUrbanistiqueUrbanisme from '@/views/prestation-expertise/rapport/situation-urbanistique/prestation-expertise-rapport-situation-urbanistique-urbanisme.vue';
import PrestationExpertiseRapportSituationUrbanistiqueControle from '@/views/prestation-expertise/rapport/situation-urbanistique/prestation-expertise-rapport-situation-urbanistique-controle.vue';
// Situation Locative
import PrestationExpertiseRapportSituationLocativeLocative from '@/views/prestation-expertise/rapport/situation-locative/prestation-expertise-rapport-situation-locative-locative.vue';
import PrestationExpertiseRapportSituationLocativeControle from '@/views/prestation-expertise/rapport/situation-locative/prestation-expertise-rapport-situation-locative-controle.vue';
// Marche
import PrestationExpertiseRapportMarcheEtudeDeMarche from '@/views/prestation-expertise/rapport/marche/prestation-expertise-rapport-marche-etude-de-marche.vue';
import PrestationExpertiseRapportMarcheControle from '@/views/prestation-expertise/rapport/marche/prestation-expertise-rapport-marche-controle.vue';
//Evaluation
import PrestationExpertiseRapportEvaluationEvaluation from '@/views/prestation-expertise/rapport/evaluation/prestation-expertise-rapport-evaluation-evaluation.vue';
import PrestationExpertiseRapportEvaluationControle from '@/views/prestation-expertise/rapport/evaluation/prestation-expertise-rapport-evaluation-controle.vue';
//Conclusion
import PrestationExpertiseRapportConclusionConclusion from '@/views/prestation-expertise/rapport/conclusion/prestation-expertise-rapport-conclusion-conclusion.vue';
import PrestationExpertiseRapportConclusionControle from '@/views/prestation-expertise/rapport/conclusion/prestation-expertise-rapport-conclusion-controle.vue';
import PrestationExpertiseRapportConclusionAnnexes from '@/views/prestation-expertise/rapport/conclusion/prestation-expertise-rapport-conclusion-annexes.vue';
//  Controle
import PrestationExpertiseRapportControleAppreciationGenerale from '@/views/prestation-expertise/rapport/controle/prestation-expertise-rapport-appreciation-generale.vue';
//#endregion

//#region PrestationExpertise
import PrestationExpertiseProprietes from '@/views/prestation-expertise/prestation-expertise-proprietes.vue';
//#endregion

//#region ValeurSpotPlus
// Contrat
import PrestationExpertiseValeurSpotPlusContratMission from '@/views/prestation-expertise/valeur-spot-plus/contrat/prestation-expertise-valeur-spot-plus-contrat-mission.vue';
// Situation Géographique
import PrestationExpertiseValeurSpotPlusSituationGeographiquePlansDeSituation from '@/views/prestation-expertise/valeur-spot-plus/situation-geographique/prestation-expertise-valeur-spot-plus-situation-geographique-plans-de-situation.vue';
// Description Générale
import PrestationExpertiseValeurSpotPlusDescriptionGeneraleDescriptionDuBien from '@/views/prestation-expertise/valeur-spot-plus/description-generale/prestation-expertise-valeur-spot-plus-description-generale-description-du-bien.vue';
// Analyse du bien
import PrestationExpertiseValeurSpotPlusAnalyseDuBienCommentaires from '@/views/prestation-expertise/valeur-spot-plus/analyse-du-bien/prestation-expertise-valeur-spot-plus-analyse-du-bien-commentaires.vue';
// Conclusion
import PrestationExpertiseValeurSpotPlusConclusionValeurs from '@/views/prestation-expertise/valeur-spot-plus/conclusion/prestation-expertise-valeur-spot-plus-conclusion-valeurs.vue';
//#endregion

//#region Etat Avancement des Travaux
// Contrat
import PrestationExpertiseEtatAvancementTravauxContratMission from '@/views/prestation-expertise/etat-avancement-travaux/contrat/prestation-expertise-etat-avancement-travaux-contrat-mission.vue';
import PrestationExpertiseEtatAvancementTravauxBien from '@/views/prestation-expertise/etat-avancement-travaux/bien/prestation-expertise-etat-avancement-travaux-bien.vue';
import PrestationExpertiseEtatAvancementTravauxPointInspection from '@/views/prestation-expertise/etat-avancement-travaux/points-inspections/prestation-expertise-etat-avancement-travaux-point-inspection.vue';
import PrestationExpertiseEtatAvancementTravauxAvisExpert from '@/views/prestation-expertise/etat-avancement-travaux/commentaires/prestation-expertise-etat-avancement-travaux-avis-expert.vue';
import PrestationExpertiseEtatAvancementTravauxConclusion from '@/views/prestation-expertise/etat-avancement-travaux/conclusion/prestation-expertise-etat-avancement-travaux-conclusion.vue';
//#endregion

//#region Etude de marché

import PrestationExpertiseEtudeDeMarcheContratMission from '@/views/prestation-expertise/etude-de-marche/contrat/prestation-expertise-etude-de-marche-contrat-mission.vue';
import PrestationExpertiseEtudeDeMarcheSituationGeographiquePlansDeSituation from '@/views/prestation-expertise/etude-de-marche/situation-geographique/prestation-expertise-etude-de-marche-situation-geographique-plans-de-situation.vue';
import PrestationExpertiseEtudeDeMarcheModeleRapport from '@/views/prestation-expertise/etude-de-marche/rapport/prestation-expertise-etude-de-marche-modele-rapport.vue';
import PrestationExpertiseEtudeDeMarcheRapport from '@/views/prestation-expertise/etude-de-marche/rapport/prestation-expertise-etude-de-marche-rapport.vue';

//#endregion

@Component({
  name: 'PrestationExpertiseSections',
  components: {
    //#region Rapport
    // Rapport
    PrestationExpertiseRapportProprietes,
    PrestationExpertiseRapportIndicateurs,
    // Contrat
    PrestationExpertiseRapportContratMission,
    //  Situation Geographique
    PrestationExpertiseRapportSituationGeographiqueGeographie,
    PrestationExpertiseRapportSituationGeographiqueAvisExpert,
    PrestationExpertiseRapportSituationGeographiqueControle,
    // Description Générale
    PrestationExpertiseRapportDescriptionGeneraleTerrain,
    PrestationExpertiseRapportDescriptionGeneraleAvisExpert,
    PrestationExpertiseRapportDescriptionGeneraleControle,
    // Description Particulière
    PrestationExpertiseRapportDescriptionParticuliereBatiment,
    PrestationExpertiseRapportDescriptionParticulierePrestations,
    PrestationExpertiseRapportDescriptionParticuliereSurface,
    PrestationExpertiseRapportDescriptionParticuliereAvisExpert,
    PrestationExpertiseRapportDescriptionParticuliereControle,
    // Situation Fiscale
    PrestationExpertiseRapportSituationFiscaleFiscalite,
    PrestationExpertiseRapportSituationFiscaleControle,
    // Situation Juridique
    PrestationExpertiseRapportSituationJuridiqueJuridique,
    PrestationExpertiseRapportSituationJuridiqueControle,
    // Situation Urbanistique
    PrestationExpertiseRapportSituationUrbanistiqueUrbanisme,
    PrestationExpertiseRapportSituationUrbanistiqueControle,
    //Situation Locative
    PrestationExpertiseRapportSituationLocativeLocative,
    PrestationExpertiseRapportSituationLocativeControle,
    // Marche
    PrestationExpertiseRapportMarcheEtudeDeMarche,
    PrestationExpertiseRapportMarcheControle,
    // Evaluation
    PrestationExpertiseRapportEvaluationEvaluation,
    PrestationExpertiseRapportEvaluationControle,
    // Conclusion
    PrestationExpertiseRapportConclusionConclusion,
    PrestationExpertiseRapportConclusionControle,
    PrestationExpertiseRapportConclusionAnnexes,
    // Controle
    PrestationExpertiseRapportControleAppreciationGenerale,
    //#endregion

    //#region PrestationExpertise
    PrestationExpertiseProprietes,
    //#endregion

    //#region ValeurSpotPlus
    // Contrat
    PrestationExpertiseValeurSpotPlusContratMission,
    // Situation Géographique
    PrestationExpertiseValeurSpotPlusSituationGeographiquePlansDeSituation,
    // Description Générale
    PrestationExpertiseValeurSpotPlusDescriptionGeneraleDescriptionDuBien,
    // Analyse du bien
    PrestationExpertiseValeurSpotPlusAnalyseDuBienCommentaires,
    // Conclusion
    PrestationExpertiseValeurSpotPlusConclusionValeurs,
    //#endregion

    //#region Etat Avancement des travaux
    PrestationExpertiseEtatAvancementTravauxContratMission,
    PrestationExpertiseEtatAvancementTravauxBien,
    PrestationExpertiseEtatAvancementTravauxPointInspection,
    PrestationExpertiseEtatAvancementTravauxAvisExpert,
    PrestationExpertiseEtatAvancementTravauxConclusion,
    //#endregion

    //#region Etude de marché
    PrestationExpertiseEtudeDeMarcheContratMission,
    PrestationExpertiseEtudeDeMarcheSituationGeographiquePlansDeSituation,
    PrestationExpertiseEtudeDeMarcheModeleRapport,
    PrestationExpertiseEtudeDeMarcheRapport,
    //#endregion
  },
})
export default class PrestationExpertiseSections extends Mixins(PrestationExpertiseSectionMixin) {
  @Prop() public componentsSections: ComponentSection[];
  @Prop() public currentComponentSection: ComponentSection;
  @Prop() public isReadOnly: boolean;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get commonComponentProperties(): any {
    return {
      businessEntries: this.businessEntries,
      validation: this.validation,
      color: this.color,
    };
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
