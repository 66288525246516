





























































import { Vue, Component } from 'vue-property-decorator';
import PrestationExpertiseLayout from '@/views/layouts/prestation-expertise.layout.vue';
import PrestationExpertise from '@/views/prestation-expertise/prestation-expertise';
import PrestationExpertiseSections from '@/components/prestation-expertise/prestation-expertise-sections.vue';
import PrestationExpertiseDesktopMenu from '@/components/prestation-expertise/prestation-expertise-desktop-menu.vue';
import PrestationExpertiseMobileMenu from '@/components/prestation-expertise/prestation-expertise-mobile-menu.vue';
import PrestationExpertiseHeaderActions from '@/components/prestation-expertise/prestation-expertise-header-actions.vue';

import { valeurSpotPlusValidation } from '@/validations/valeur-spot-plus.validation';

import { ValeurSpotPlusDto } from '@/shared/dtos/valeur-spot-plus.dto';
import { MissionDto } from '@/shared/dtos/mission.dto';
import PrestationExpertiseMissionMerge from '@/shared/components/prestation-expertise/prestation-expertise-mission-merge.vue';
import { MergeDto } from '@/shared/dtos/merge.dto';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { PrestationExpertiseProprietesDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-proprietes.dto';

@Component({
  name: 'PrestationExpertiseValeurSpotPlus',
  components: {
    // Layout
    PrestationExpertiseLayout,
    PrestationExpertiseSections,
    PrestationExpertiseDesktopMenu,
    PrestationExpertiseMobileMenu,
    PrestationExpertiseHeaderActions,
    PrestationExpertiseMissionMerge,
  },
  validations: valeurSpotPlusValidation,
})
export default class PrestationExpertiseValeurSpotPlus extends PrestationExpertise {
  constructor() {
    // Cannot pass componentsSections in the parent bescause @Component doesn't support properties in contructor...
    super();
  }

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    if (this.hasAccess) {
      this.initComponentSections();

      await this.initMergeDatas();
    }
  }

  public mounted(): void {
    // Watch à cet endroit là pour éviter de le trigger dès le début et MAJ la date de modification sans le vouloir
    // VueJS semble mettre à jour l'objet mission
    setTimeout(() => {
      this.$watch(
        'valeurSpotPlus.proprietes.estEditionForcee',
        async () => {
          this._needToByPasseHasPrestationExpertiseChanged = true;
          const logMessage = `Sauvegarde des propriétés: ${this.mission.reference}`;
          this.saveInStorage(true);

          await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseValeurSpotPlus');

          Vue.prototype.$prestationExpertiseProprietesApiService
            .update((this.valeurSpotPlus.proprietes as PrestationExpertiseProprietesDto).id, this.valeurSpotPlus.proprietes)
            .then(async () => {
              await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseValeurSpotPlus');
              Vue.prototype.$notificationService.success(`Les propriétés ont été sauvegardées avec succès.`);
            })
            .catch(async (error: any) => {
              Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la sauvegarde des propriétés.`);
              await Vue.prototype.$logStoreService.info(`${logMessage} - ERROR`, error, 'PrestationExpertiseValeurSpotPlus');
            });
        },
        { deep: true }
      );
    }, 50);
  }

  //#endregion

  //#region COMPUTED
  get valeurSpotPlus(): ValeurSpotPlusDto {
    return this.mission.prestationExpertise as ValeurSpotPlusDto;
  }

  set valeurSpotPlus(value: ValeurSpotPlusDto) {
    this.mission.prestationExpertise = value;
  }

  get color(): string {
    return '#00ab9d';
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  //#endregion

  //#region FUNCTIONS
  protected cleanBeforeSaveInStorage(missionToClean: MissionDto): void {}

  protected async cleanBeforeSave(): Promise<void> {}

  protected touchDatas(): boolean {
    this.$v.mission.prestationExpertise.$touch();
    this.$v.mission.prestationExpertise.piecesJointes.$touch();

    return this.$v.mission.prestationExpertise.$invalid || this.$v.mission.prestationExpertise.piecesJointes.$invalid;
  }

  public initComponentSections(): void {
    this.componentsSections = [
      {
        slug: 'rapport',
        name: 'rapport',
        isOnlyForDesktop: false,
        children: [
          {
            slug: 'rapport-proprietes',
            name: 'propriétés',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseProprietes',
            properties: {
              proprietes: this.valeurSpotPlus.proprietes as PrestationExpertiseProprietesDto,
              prestationExpertiseId: this.valeurSpotPlus.id,
              isReadOnly: false,
            },
            permission: 'SavePropriete|Rapport',
          },
        ],
        permission: 'SavePropriete|Rapport',
      },
      {
        slug: 'contrat',
        name: 'contrat',
        children: [
          {
            slug: 'contrat-mission',
            name: 'mission',
            isActive: true,
            componentName: 'PrestationExpertiseValeurSpotPlusContratMission',
          },
        ],
        validationName: 'contratGroup',
      },
      {
        slug: 'situation-geographique',
        name: 'situation géographique',
        children: [
          {
            slug: 'situation-geographique-plans-de-situation',
            name: 'plans de situation',
            componentName: 'PrestationExpertiseValeurSpotPlusSituationGeographiquePlansDeSituation',
          },
        ],
        validationName: 'situationGeographiqueGroup',
      },
      {
        slug: 'description-generale',
        name: 'description générale',
        children: [
          {
            slug: 'description-generale-description-du-bien',
            name: 'description du bien',
            componentName: 'PrestationExpertiseValeurSpotPlusDescriptionGeneraleDescriptionDuBien',
          },
        ],
        validationName: 'descriptionGeneraleGroup',
      },
      {
        slug: 'analyse-du-bien',
        name: 'analyse du bien',
        children: [
          {
            slug: 'analyse-du-bien-commentaires',
            name: 'commentaires',
            componentName: 'PrestationExpertiseValeurSpotPlusAnalyseDuBienCommentaires',
          },
        ],
        validationName: 'analyseBienGroup',
      },
      {
        slug: 'conclusion',
        name: 'conclusion',
        children: [
          {
            slug: 'conclusion-valeurs',
            name: 'valeurs',
            componentName: 'PrestationExpertiseValeurSpotPlusConclusionValeurs',
          },
        ],
        validationName: 'conclusionGroup',
      },
    ];
  }

  public async onEmitMergeMission(merges: MergeDto[]): Promise<void> {
    if (merges.length === 1 && merges[0].fieldName === 'TypePrestationExpertise') {
      const mergeTypePrestationExpertise = merges[0];
      return await this.changeTypePrestationExpertise(mergeTypePrestationExpertise, 'PrestationExpertiseValeurSpotPlus');
    }

    merges.forEach((merge: MergeDto) => {
      switch (merge.fieldName) {
        case 'HasFourchetteValeur':
          this.mission.hasFourchetteValeur = merge.newValue;
          break;
        case 'HasValeurVenteForcee':
          this.mission.hasValeurVenteForcee = merge.newValue;
          break;
        case 'HasValeurVenteRapide':
          this.mission.hasValeurVenteRapide = merge.newValue;
          break;
      }
    });

    Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.REFRESH_VALEURS_DATA);
    this._hasMissionChanged = true;
    await this.saveInStorage();
  }

  //#endregion
}
