import { IDBPDatabase } from 'idb';
import moment from 'moment';

export class BaseStoreService<T> {
  protected _tableName: string;
  protected _indexName: string;
  protected _db: IDBPDatabase;

  constructor(tableName: string, indexName: string, db: IDBPDatabase) {
    this._tableName = tableName;
    this._indexName = indexName;
    this._db = db;
  }

  public async clear(): Promise<any> {
    try {
      await this._db.clear(this._tableName);
    } catch (error) {
      return false;
    }
  }

  public async getByKey(key: number): Promise<T> {
    const tx = this._db.transaction(this._tableName, 'readonly');
    const store = tx.objectStore(this._tableName);
    const result = await store.get(key);
    return result as T;
  }

  public async getByIndex(indexName: string, indexValue: any): Promise<T> {
    const tx = this._db.transaction(this._tableName, 'readonly');
    const store = tx.objectStore(this._tableName);
    const index = store.index(indexName);
    const result = await index.get(IDBKeyRange.only(indexValue));
    return result as T;
  }

  public async getAll(): Promise<T[]> {
    const tx = this._db.transaction(this._tableName, 'readonly');
    const store = tx.objectStore(this._tableName);
    const result = await store.getAll();
    return result as T[];
  }

  public async getLastEntry(): Promise<T> {
    const tx = this._db.transaction(this._tableName, 'readonly');
    const store = tx.objectStore(this._tableName);
    var request = store.openCursor(null, 'prev');
    return await request.then((event: any) => {
      return event.value;
    });
  }

  public async put(value: T): Promise<T> {
    const tx = this._db.transaction(this._tableName, 'readwrite');
    const store = tx.objectStore(this._tableName);
    const key = await store.put(value);
    const result = await store.get(key);
    return result as T;
  }

  public async addOrUpdate(value: T): Promise<T> {
    let storedValue = await this.getByIndex(this._indexName, (value as any)[this._indexName]);
    storedValue = { ...storedValue, ...value };
    return await this.put(storedValue);
  }

  public async addBulk(values: T[]): Promise<T[]> {
    var results: T[] = [];
    for (const value of values) {
      results.push(await this.addOrUpdate(value));
    }
    return results;
  }

  public async deleteByKey(key: number): Promise<boolean> {
    const tx = this._db.transaction(this._tableName, 'readwrite');
    const store = tx.objectStore(this._tableName);
    const result = await store.get(key);

    if (!result) {
      return false;
    }
    await store.delete(key);
    return true;
  }

  public async deleteByPropertyAndValue(property: string, value: any): Promise<boolean> {
    const tx = this._db.transaction(this._tableName, 'readwrite');
    const store = tx.objectStore(this._tableName);

    var request = store.openCursor(null);
    return await request.then(function deleteCursor(cursor: any): any {
      if (!cursor) return;

      if (cursor.value[property] === value) store.delete(cursor.primaryKey);

      return cursor.continue().then(deleteCursor);
    });
  }

  public async deleteLowerEntriesByPropertyAndValue(property: string, value: any, isDate = false): Promise<void> {
    const tx = this._db.transaction(this._tableName, 'readwrite');
    const store = tx.objectStore(this._tableName);

    var request = store.openCursor(null);
    return await request.then(function deleteCursor(cursor: any): any {
      if (!cursor) return;

      var cursorValue = property.split('.').reduce(function (a, b) {
        return a[b];
      }, cursor.value);

      if (isDate) {
        cursorValue = moment(cursorValue);
        value = moment(value);
      }

      if (cursorValue && cursorValue < value) store.delete(cursor.primaryKey);

      return cursor.continue().then(deleteCursor);
    });
  }

  public async deleteLowerIndexEntriesByValue(indexName: string, value: any): Promise<void> {
    const tx = this._db.transaction(this._tableName, 'readwrite');
    const store = tx.objectStore(this._tableName);
    const index = store.index(indexName);

    var keyRangeValue = IDBKeyRange.upperBound(value);
    var request = index.openKeyCursor(keyRangeValue);
    return await request.then(function deleteCursor(cursor: any): any {
      if (!cursor) return;

      store.delete(cursor.primaryKey);
      return cursor.continue().then(deleteCursor);
    });
  }
}
