import { DonneesEstimationImmobiliereDto } from '@/shared/dtos/donnees-estimation-immobiliere.dto';
import { EtudeMarcheElementComparaisonDto } from '@/shared/dtos/etude-marche-element-comparaison.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';
import { EstimationDpeResponse } from '@/shared/dtos/estimation-dpe-response.dto';
import { EstimationDpeInput } from '@/shared/dtos/estimation-dpe.dto';
import { DossierPriceHubble } from '@/shared/models/dossier/dossier-pricehubble.model';
import { ScenarioRenovationDto } from '@/shared/dtos/scenario-renovation.dto';

export class DonneesEstimationImmobiliereApiService extends BaseDtoApiService<DonneesEstimationImmobiliereDto> {
  constructor() {
    super('donneesEstimationImmobiliere');
  }

  public authenticate(): Promise<string> {
    return axios.get(`${this._endpoint}/authenticate`).then((response: any) => {
      return response.data.result as string;
    });
  }

  public getComparables(dossierId: string): Promise<EtudeMarcheElementComparaisonDto[]> {
    return axios.get(`${this._endpoint}/${dossierId}/comparables`).then((response: any) => {
      return response.data.result as EtudeMarcheElementComparaisonDto[];
    });
  }

  public getEstimation(dossierId: string, form: EstimationDpeInput): Promise<EstimationDpeResponse> {
    return axios.post(`${this._endpoint}/estimation/${dossierId}`, form).then((response: any) => {
      return response.data.result as EstimationDpeResponse;
    });
  }

  public getDossierByRapportId(rapportId: string): Promise<DossierPriceHubble> {
    return axios.get(`${this._endpoint}/rapports/${rapportId}/dossier`).then((response: any) => {
      return response.data.result as DossierPriceHubble;
    });
  }

  public getScenarioEstimation(dossierId: string): Promise<ScenarioRenovationDto> {
    return axios.get(`${this._endpoint}/scenariosrenovation/${dossierId}/dossier`).then((response: any) => {
      return response.data.result as ScenarioRenovationDto;
    });
  }

  public deleteAllScenarioRenovationByDossierId(dossierId: string): Promise<void> {
    return axios.delete(`${this._endpoint}/scenarioRenovation/${dossierId}/dossier`);
  }

  public removeComparables(dossierId: string): Promise<void> {
    return axios.delete(`${this._endpoint}/${dossierId}/comparables`);
  }
}
