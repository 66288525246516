export enum NatureBienEnum {
  HabitatIndividuel = 2937066,
  HabitatCollectif = 55352,
  HabitatException = 2937067,
  Terrain = 55445,
  TerresCultives = 2937068,
  ImmeubleRapport = 2937069,
  ImmeubleProfessionnel = 2912434,
  ResidenceDeServicesTourisme = 2937070,
  ParkingBox = 2912228,
}
