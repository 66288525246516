import { ContratTypeVisiteEnum } from '@/shared/enums/contrat-type-visite.enum';
import { NatureVisiteEnum } from '@/shared/enums/nature-visite.enum';

export function getNatureVisiteFromContratTypeVisiteId(contratTypeVisiteId: number): NatureVisiteEnum {
  switch (contratTypeVisiteId) {
    default:
    case ContratTypeVisiteEnum.InterieurEtExterieur:
      return NatureVisiteEnum.Complete;

    case ContratTypeVisiteEnum.ExterieurUniquement:
      return NatureVisiteEnum.Exterieure;

    case ContratTypeVisiteEnum.SurPieces:
      return NatureVisiteEnum.SurPieces;

    case ContratTypeVisiteEnum.Partielle:
      return NatureVisiteEnum.Partielle;
  }
}

export function getContratTypeVisiteIdFromNatureVisiteEnum(natureVisite: NatureVisiteEnum): number {
  switch (natureVisite) {
    default:
    case NatureVisiteEnum.Complete:
      return ContratTypeVisiteEnum.InterieurEtExterieur;
    case NatureVisiteEnum.Exterieure:
      return ContratTypeVisiteEnum.ExterieurUniquement;
    case NatureVisiteEnum.SurPieces:
      return ContratTypeVisiteEnum.SurPieces;
    case NatureVisiteEnum.Partielle:
      return ContratTypeVisiteEnum.Partielle;
  }
}
