import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { PropertyDossier } from './property.model';

export class DossierPriceHubble {
  id: string;
  title: string;
  property: PropertyDossier;
  dealType: string;
  selectedOffers: string[];
  selectedTransactions: string[];
  countryCode: string;

  constructor() {
    this.id = '';
    this.title = '';
    this.property = new PropertyDossier();
    this.dealType = undefined;
    this.selectedOffers = [];
    this.selectedTransactions = [];
    this.countryCode = '';
  }
}
