import axios from 'axios';

export class ReferentielApiService {
  public urlApi: string = '/referentiel';

  public getReferentielDpe(): Promise<Array<{ [key: string]: string }>> {
    return axios.get(`${this.urlApi}/dpe`).then((response: any) => {
      return response.data.result as Array<{ [key: string]: string }>;
    });
  }

  public getAnneeConstruction(): Promise<{ [key: number]: string }> {
    return axios.get(`${this.urlApi}/anneeConstruction`).then((response: any) => {
      return response.data.result as { [key: number]: string };
    });
  }
}
