import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ComponentSection } from '@/shared/models/component-section';
import ThemeMixin from '@/mixins/theme.mixin';

@Component({})
export default class PrestationExpertiseMenu extends Mixins(ThemeMixin) {
  @Prop() public componentsSections: ComponentSection[];
  @Prop() public currentComponentSection: ComponentSection;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onMenuItemClick(componentSection: ComponentSection): void {
    throw new Error('Not implemented');
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
