


























































































































import { Component, Mixins, Vue } from 'vue-property-decorator';

import DeviceMixin from '@/mixins/device.mixin';
import { ModeleEmailDto } from '@/shared/dtos/modele-email.dto';
import BackButton from '@/shared/components/back-button.vue';
import HtmlTextarea from '@/shared/components/html-textarea.vue';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import store from '@/shared/store';
import roles from '@/shared/constants/roles.const';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { EmailCommandeDto } from '@/shared/dtos/email-commande.dto';
import { MissionDto } from '@/shared/dtos/mission.dto';

@Component({
  name: 'PrestationExpertiseContact',
  components: {
    BackButton,
    Spinner,
    HtmlTextarea,
  },
})
export default class PrestationExpertiseContact extends Mixins(DeviceMixin) {
  public omereMissionId: number;
  public modelesEmails: ModeleEmailDto[] = [];
  public isLoading = false;
  public selectedObjet: number;
  public objets: any[] = [];
  public objet = '';
  public corps = '';
  public isDestinataireMandantChecked = false;
  public isDestinataireExpertChecked = false;
  public isDestinataireControleurChecked = false;
  public autreDestinataire = '';
  public isCcMandantChecked = false;
  public isCcExpertChecked = false;
  public isCcControleurChecked = false;
  public autreCc = '';
  public isCciMandantChecked = false;
  public isCciExpertChecked = false;
  public isCciControleurChecked = false;
  public autreCci = '';
  public isExpert = store.user.roles.includes(roles.Expert);
  public isExpertMission = false;
  public isSoustraitant = store.user.roles.includes(roles.SousTraitant);
  public isAdminBPCEEI = store.user.roles.includes(roles.AdminBPCEEI);
  public isSoustraitantAvance = store.user.roles.includes(roles.SousTraitantAvance);
  public emailsCommande: EmailCommandeDto[] = [];

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    this.omereMissionId = +this.$route.params.omereMissionId;

    // Si l'utilisateur est controleur et s'il est également l'expert de cette mission
    if (store.user.roles.includes(roles.Controleur)) {
      const mission: MissionDto = await Vue.prototype.$missionStoreService.getByIndex('id', +this.omereMissionId);
      if (mission != null && mission.expert.id === store.user.omereContactId) {
        this.isExpertMission = true;
      }
    }

    this.emailsCommande = this.$route.meta.emailsCommande as EmailCommandeDto[];
    this.modelesEmails = Vue.prototype.$localStorageService.getModelesEmails();

    this.objets = this.modelesEmails.map((modelEmail: ModeleEmailDto) => {
      return { value: modelEmail.id, text: modelEmail.objet };
    });

    this.selectedObjet = this.modelesEmails[0].id;
    const selectedModelEmail = this.modelesEmails.find((modelEmail: ModeleEmailDto) => modelEmail.id === this.selectedObjet);
    this.corps = selectedModelEmail.corps;
    this.objet = selectedModelEmail.objet;

    if (!this.isAdminBPCEEI && (this.isExpert || this.isSoustraitant || this.isExpertMission || this.isSoustraitantAvance)) {
      this.isDestinataireMandantChecked = true;
      this.isCcExpertChecked = true;
      this.isCcControleurChecked = true;
    }
  }

  //#endregion

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onObjetChange(value: any): void {
    this.selectedObjet = value;
    this.objet = this.modelesEmails.find((modelEmail: ModeleEmailDto) => modelEmail.id === this.selectedObjet).objet;
    this.corps = this.modelesEmails.find((modelEmail: ModeleEmailDto) => modelEmail.id === this.selectedObjet).corps;
  }

  public async onSubmit(event: any): Promise<void> {
    event.preventDefault();

    if (
      !this.isDestinataireMandantChecked &&
      !this.isDestinataireExpertChecked &&
      !this.isDestinataireControleurChecked &&
      !this.autreDestinataire.length
    ) {
      Vue.prototype.$notificationService.warn('Vous devez saisir au moins un destinataire');
      return;
    }

    const autres = (this.autreDestinataire + this.autreCc + this.autreCci).replaceAll(' ', '');
    const nbArobases = (autres.match(/@/g) || []).length;
    const nbSeparators = (autres.match(/;/g) || []).length;

    if (nbArobases > 1 && nbArobases !== nbSeparators + 1) {
      Vue.prototype.$notificationService.warn(
        `Les adresses e-mail saisies dans les champs Autre(s) doivent être séparées par des points virgules`
      );
      return;
    } else {
      var emails = autres.split(';');
      const malformedEmail = emails.find((email: string) => !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\\w+)*(.\w{2,})+$/g));

      if (malformedEmail) {
        Vue.prototype.$notificationService.warn(`Une ou plusieurs adresses e-mail ne sont pas au bon format`);
        return;
      }
    }

    this.isLoading = true;

    const logMessage = `Envoi d'un email pour la mission id : ${this.omereMissionId}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseContact');

    Vue.prototype.$emailCommandeApiService
      .addCustom(
        this.omereMissionId,
        this.objet,
        this.corps,
        this.isDestinataireMandantChecked,
        this.isDestinataireExpertChecked,
        this.isDestinataireControleurChecked,
        this.autreDestinataire,
        this.isCcMandantChecked,
        this.isCcExpertChecked,
        this.isCcControleurChecked,
        this.autreCc,
        this.isCciMandantChecked,
        this.isCciExpertChecked,
        this.isCciControleurChecked,
        this.autreCci
      )
      .then(async (res: any) => {
        this.$bvToast.show('validation-toast');
        setTimeout(() => {
          Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.BACK);
          this.$bvToast.hide('validation-toast');
        }, 2000);
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseContact');
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de l'envoi de l'email.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertiseContact');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
