import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';
import { RapportProprieteDto } from '@/shared/dtos/rapport-propriete.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class RapportExpertiseApiService extends BaseDtoApiService<RapportExpertiseDto> {
  constructor() {
    super('rapportsexpertises');
  }

  public updateProprietes(id: number, proprietes: RapportProprieteDto): Promise<void> {
    return axios.put(`${this._endpoint}/${id}/proprietes`, proprietes);
  }

  public apposerVisa(id: number, rapport: RapportExpertiseDto): Promise<void> {
    return axios.put(`${this._endpoint}/${id}/apposervisa`, rapport);
  }

  public refuserVisa(id: number, rapport: RapportExpertiseDto): Promise<void> {
    return axios.put(`${this._endpoint}/${id}/refuservisa`, rapport);
  }

  public getDonneesEstimationImmobiliere(id: number): Promise<any> {
    return axios.get(`${this._endpoint}/${id}/donneesEstimationImmobiliere`).then((response: any) => {
      return response.data.result;
    });
  }

  public getCommodites(id: number): Promise<any> {
    return axios.get(`${this._endpoint}/${id}/commodites`).then((response: any) => {
      return response.data.result;
    });
  }

  public updateCategorieActif(id: number, categorieActifId: number): Promise<void> {
    return axios.put(`${this._endpoint}/${id}/categorieActif/${categorieActifId}`);
  }

  public updateDonneesEstimationImmobiliere(id: number, input: DonneesEstimationImmobiliereUpdateInputDto): Promise<void> {
    return axios.put(`${this._endpoint}/${id}/donneesEstimationImmobiliere/`, input);
  }
}
