import { required, requiredIf, minLength, helpers } from 'vuelidate/lib/validators';
import { hasFourchetteValeur, hasValeurVenteRapide, hasValeurVenteForcee } from '@/helpers/prestation-expertise.helper';

// Validation pour pouvoir déposer la ValeurSpotPlus
const valeurSpotPlusValidation = {
  mission: {
    dateVisite: {
      required,
    },
    prestationExpertise: {
      contratCategorieDActifId: { required },
      contratTypeBienId: {
        customRequired(value) {
          return this.businessEntries.contratTypeBiens.filter((b) => b.parentId == this.valeurSpotPlus.contratCategorieDActifId).length > 0
            ? helpers.req(value)
            : true;
        },
      },
      geographiqueSituationGenerale: {
        required,
      },
      geographiqueSituationParticuliere: {
        required,
      },
      appreciationGeographique: {
        required,
      },
      referencesCadastrales: {
        required,
        minLength: minLength(1),
        $each: {
          section: { required },
          parcelle: { required },
          superficie: {},
        },
      },
      piecesJointes: {
        PlanCadastre: {
          required,
        },
        PhotoBien: {
          required,
        },
      },
      surfaceHabitable: {
        required,
      },
      adequationBienVsDemande: {
        required,
      },
      avisExpert: {
        required,
      },
      reductionMetriqueTransaction: {
        required,
      },
      valeurVenale: {
        required,
      },
      valeurVenaleArrondie: {
        customRequired(value) {
          return !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenaleFourchetteBasseArrondie: {
        customRequired(value) {
          return hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenaleFourchetteHauteArrondie: {
        customRequired(value) {
          return hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteRapide: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteRapideArrondie: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) && !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteRapideFourchetteBasseArrondie: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteRapideFourchetteHauteArrondie: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteRapideAbattement: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteForcee: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteForceeArrondie: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) && !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteForceeFourchetteBasseArrondie: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteForceeFourchetteHauteArrondie: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      valeurVenteForceeAbattement: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) ? helpers.req(value) : true;
        },
      },
      appreciationNiveauLiquidite: {
        required,
      },
      conclusionDelaiDeVenteMoyenId: {
        required,
      },
    },
    contratGroup: [
      'mission.prestationExpertise.contratCategorieDActifId',
      'mission.prestationExpertise.contratTypeBienId',
      'mission.dateVisite',
    ],
    situationGeographiqueGroup: [
      'mission.prestationExpertise.geographiqueSituationGenerale',
      'mission.prestationExpertise.geographiqueSituationParticuliere',
      'mission.prestationExpertise.appreciationGeographique',
    ],
    descriptionGeneraleGroup: [
      'mission.prestationExpertise.referencesCadastrales',
      'mission.prestationExpertise.piecesJointes.PlanCadastre',
      'mission.prestationExpertise.piecesJointes.PhotoBien',
      'mission.prestationExpertise.surfaceHabitable',
      'mission.prestationExpertise.adequationBienVsDemande',
    ],
    analyseBienGroup: ['mission.prestationExpertise.avisExpert', 'mission.prestationExpertise.reductionMetriqueTransaction'],
    conclusionGroup: [
      'mission.prestationExpertise.valeurVenale',
      'mission.prestationExpertise.valeurVenaleArrondie',
      'mission.prestationExpertise.valeurVenaleFourchetteBasseArrondie',
      'mission.prestationExpertise.valeurVenaleFourchetteHauteArrondie',
      'mission.prestationExpertise.valeurVenteRapide',
      'mission.prestationExpertise.valeurVenteRapideArrondie',
      'mission.prestationExpertise.valeurVenteRapideFourchetteBasseArrondie',
      'mission.prestationExpertise.valeurVenteRapideFourchetteHauteArrondie',
      'mission.prestationExpertise.valeurVenteRapideAbattement',
      'mission.prestationExpertise.valeurVenteForcee',
      'mission.prestationExpertise.valeurVenteForceeArrondie',
      'mission.prestationExpertise.valeurVenteForceeFourchetteBasseArrondie',
      'mission.prestationExpertise.valeurVenteForceeFourchetteHauteArrondie',
      'mission.prestationExpertise.valeurVenteForceeAbattement',
      'mission.prestationExpertise.appreciationNiveauLiquidite',
      'mission.prestationExpertise.conclusionDelaiDeVenteMoyenId',
    ],
  },
};

export { valeurSpotPlusValidation };
