














































































import { Component, Vue } from 'vue-property-decorator';
import BasicLayout from '@/views/layouts/basic.layout.vue';
import HeaderTitle from '@/shared/components/header-title.vue';
import CardMission from '@/components/card-mission.vue';
import { MissionsPerDay } from '@/shared/models/missions-per-day';
import { MissionDto } from '@/shared/dtos/mission.dto';

import moment from 'moment';
import Agenda from './agenda';
import AgendaLoadingMessage from '@/components/agenda-loading-message.vue';

@Component({
  name: 'AgendaDesktop',
  components: {
    BasicLayout,
    HeaderTitle,
    CardMission,
    AgendaLoadingMessage,
  },
})
export default class AgendaDesktop extends Agenda {
  public today: moment.Moment;
  public currentDate: moment.Moment;
  public missionsPerDay: MissionsPerDay[] = [];

  //#region LIFE CYCLES
  public created(): void {
    this.today = moment();
    this.currentDate = moment();
  }

  public async beforeMount(): Promise<void> {
    await this.getDonneesTechniques();

    const storedMissions: MissionDto[] = await Vue.prototype.$missionStoreService.getAll();

    this.setMissionsPlanifiees(storedMissions);
    this.setMissionsAPlanifier(storedMissions);

    await this.initDays();
    await this.getDonneesAgenda();
    await this.initDays();
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public async onLastWeekClick(): Promise<void> {
    this.currentDate.add(-7, 'days');

    await this.initDays();
  }

  public async onNextWeekClick(): Promise<void> {
    this.currentDate.add(7, 'days');

    await this.initDays();
  }

  //#endregion

  //#region FUNCTIONS
  private async initDays(): Promise<void> {
    this.missionsPerDay = [];

    for (let i = 0; i < 5; i++) {
      const day = moment(this.currentDate.clone().startOf('week')).add(i, 'days');
      const missionPerDay = {
        day: day,
        missions: this.missionsPlanifiees.filter((mission: MissionDto) => day.isSame(moment(mission.dateVisite), 'days')),
      };

      this.missionsPerDay.push(missionPerDay);
    }
  }

  //#endregion
}
