import { MissionForListDto } from '@/shared/dtos/liste-mission.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class MissionForListApiService extends BaseDtoApiService<MissionForListDto> {
  constructor() {
    super('missions');
  }

  public getAllByOmereContactId(omereContactId: number): Promise<MissionForListDto[]> {
    return axios.get(`${this._endpoint}/contact/${omereContactId}/list/all`).then((data: any) => this.convertDatas(data));
  }

  public getLastByOmereContactId(omereContactId: number): Promise<MissionForListDto[]> {
    return axios.get(`${this._endpoint}/contact/${omereContactId}/list/last`).then((data: any) => this.convertDatas(data));
  }

  public getLastOrAllByOmereContactId(omereContactId: number, selection: string): Promise<MissionForListDto[]> {
    if (selection === 'last') return this.getLastByOmereContactId(omereContactId);

    return this.getAllByOmereContactId(omereContactId);
  }
}
