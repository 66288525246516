































import { Component, Inject, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DistributionInterieureEtage from '@/components/rapport-expertise/distribution-interieur-etage.vue';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

@Component({
  name: 'DistributionInterieure',
  components: {
    DistributionInterieureEtage,
  },
})
export default class DistributionInterieure extends mixins(RapportExpertiseSectionMixin) {
  public fields: string[] = ['label', 'superficie', 'action'];

  @Prop() public surfacesChanged: Function;

  @Inject('confirmDelete') public confirmDelete: any;

  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS
  public onEtageTypeClicked(type: any): void {
    this.rapport.distributionInterieureEtages.push({
      typeBusinessItemId: type.id,
      customLabel: null,
      distributionInterieureSurfaces: [],
    });
  }
  //#endregion

  //#region FUNCTIONS

  public deleteEtage(index: any) {
    this.confirmDelete('ce niveau').then(() => {
      this.rapport.distributionInterieureEtages.splice(index, 1);
      this.surfacesChanged();
    });
  }

  //#endregion
}
