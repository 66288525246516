import { AnnexeDto } from '@/shared/dtos/annexe.dto';
import { PrestationExpertiseAnnexeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-annexe.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';
export class AnnexeApiService extends BaseDtoApiService<AnnexeDto | PrestationExpertiseAnnexeDto> {
  constructor() {
    super('annexes');
  }

  public getAnnexe(id: number): Promise<any> {
    return axios.get(`${this._endpoint}/${id}`, {
      responseType: 'blob',
    });
  }

  public addFile(
    tableId: number,
    ficheId: number,
    annexe: PrestationExpertiseAnnexeDto
  ): Promise<AnnexeDto | PrestationExpertiseAnnexeDto> {
    return axios
      .post(`${this._endpoint}/${tableId}/fiche/${ficheId}`, this.toJson(annexe))
      .then((response: any) => this.convertData(response));
  }
}
