























import { Component, Mixins } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import BackButton from '@/shared/components/back-button.vue';
import store from '@/shared/store';
import roles from '@/shared/constants/roles.const';

@Component({
  name: 'PrestationExpertiseLayout',
  components: { BackButton },
})
export default class PrestationExpertiseLayout extends Mixins(DeviceMixin) {
  public isSoustraitant = store.user.roles.includes(roles.SousTraitant);
  public comeFromLoginPage = store.comeFromLoginPage;

  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
