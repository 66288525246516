import { PrestationExpertiseDto } from '@/shared/dtos/prestation-expertise/prestation-expertise.dto';
import { TypePrestationExpertiseEnum } from '@/shared/enums/type-prestation-expertise.enum';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { BaseDtoApiService } from './base-dto-api.service';

export class PrestationExpertiseApiService extends BaseDtoApiService<PrestationExpertiseDto> {
  constructor() {
    super('prestationsexpertises');
  }

  public getDateModificationUtcByMissionIdAndTypePrestationExpertise(
    missionId: number,
    typePrestationExpertise: TypePrestationExpertiseEnum
  ): Promise<Moment> {
    return axios
      .get(`${this._endpoint}/mission/${missionId}/typeprestation/${typePrestationExpertise}/datemodificationutc`)
      .then((response: any) => {
        return moment(response.data.result);
      });
  }
}
