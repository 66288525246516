





























import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import VueNumeric from 'vue-numeric';
import { InputModeDirective } from '@/shared/directives/input-mode.directive';
import DeviceMixin from '@/mixins/device.mixin';
Vue.directive('input-mode', InputModeDirective);
@Component({
  name: 'NumericNullable',
  components: {
    VueNumeric,
  },
})
export default class NumericNullable extends Mixins(DeviceMixin) {
  @Prop({ required: false }) public value: number;
  @Prop() public title: string;
  @Prop({ default: true }) public titleBold: boolean;
  @Prop({ default: true }) public state: boolean;
  @Prop({ default: 'right' }) public align: string;
  @Prop() public currency: string;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get valueWrapped() {
    return this.value || '';
  }
  set valueWrapped(val: any) {
    var output = null;
    if (val != '') output = val;
    this.$emit('input', output);
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onKeypress(event: any) {
    var evt = event ? event : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;

    const shouldNotAddDecimal = function (evt: any) {
      var val = evt.target.value;
      var decimalIndex = val.indexOf(',');
      return decimalIndex > -1 && (decimalIndex < evt.target.selectionStart || decimalIndex >= evt.target.selectionEnd);
    };

    // 46 -> '.'
    if (charCode === 46) {
      // empêche de rentrer une deuxième virgule
      if (shouldNotAddDecimal(evt)) {
        evt.preventDefault();
        return false;
      }

      // insère une virgule à l'endroit où le point aurait été inséré
      var val = evt.target.value;
      var selectionStart = evt.target.selectionStart;
      val = val.substr(0, selectionStart) + ',' + val.substr(evt.target.selectionEnd);
      evt.target.value = val;
      evt.target.selectionStart = evt.target.selectionEnd = selectionStart + 1;

      evt.preventDefault();
      return false;
    }

    // empêche de rentrer une deuxième virgule
    // 44 -> ','
    if (charCode === 44) {
      if (shouldNotAddDecimal(evt)) {
        evt.preventDefault();
        return false;
      }
    }

    // empêche tout caractère non numeric et non virgule
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 44) {
      evt.preventDefault();
      return false;
    }

    return true;
  }

  public onChange(event: any): void {
    this.$emit('change', event.target.value.replace(',', '.'));
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
