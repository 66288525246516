


























import { RapportExpertiseBusinessEntryRelationDto } from '@/shared/dtos/rapport-expertise-business-entry-relation.dto';
import { RapportExpertiseBusinessEntryDto } from '@/shared/dtos/rapport-expertise-business-entry.dto';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Checkboxs',
})
export default class Checkboxs extends Vue {
  @Prop() public value: RapportExpertiseBusinessEntryRelationDto[];
  @Prop() public list: RapportExpertiseBusinessEntryDto[];
  @Prop() public rapportId: number;
  @Prop() public isDisabled: boolean;
  @Prop() public title: string;
  @Prop({ default: true }) public titleBold: boolean;
  @Prop({ default: true }) public state: boolean;
  @Prop() public valuesToDisabled: number[];

  public baseURL: string = Vue.prototype.$config.baseUrlApi;
  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS

  public onToggleClick(id: number): void {
    if (this.value.map((v: any) => v.businessEntrySelectedId).includes(id)) {
      var index = this.value.findIndex((v: any) => v.businessEntrySelectedId == id);
      this.value.splice(index, 1);
    } else {
      this.value.push({
        rapportExpertiseId: this.rapportId,
        businessEntrySelectedId: id,
      });
    }

    this.$emit('input', this.value);
  }

  //#endregion

  //#region FUNCTIONS

  public isSelected(id: number): boolean {
    return this.value.map((v: any) => v.businessEntrySelectedId).includes(id);
  }

  public isItemDisabled(id: number): boolean {
    return this.isDisabled || this.valuesToDisabled?.includes(id);
  }

  //#endregion
}
