


















import EtudeDeMarcheSectionMixin from '@/mixins/etude-de-marche-section.mixin';
import { Vue, Component, Mixins } from 'vue-property-decorator';
@Component({
  name: 'PrestationExpertiseEtudeDeMarcheModeleRapport',
})
export default class PrestationExpertiseEtudeDeMarcheModeleRapport extends Mixins(EtudeDeMarcheSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  public onModeleDownloadClick(): void {
    let link = document.createElement('a');
    link.href = Vue.prototype.$config.baseUrl + '/templates/Modele_rapport_etude_de_marche.xlsx';
    link.download = 'Modele_rapport_etude_de_marche.xlsx';
    document.body.appendChild(link); // fix for Firefox
    link.click();
    link.remove();
  }
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
