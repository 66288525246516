
























































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import Checkboxs from '@/shared/components/checkboxs.vue';

import {
  isFiscaleSourceTaxeFonciereDocumentConsulte,
  isContratTypePrestationNotCertificatExpertise,
} from '@/helpers/rapport-expertise.helper';
import Radios from '@/shared/components/radios.vue';
import TableRadios from '@/shared/components/table-radios.vue';
import { RapportExpertiseBusinessEntryRelationDto } from '@/shared/dtos/rapport-expertise-business-entry-relation.dto';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';

@Component({
  name: 'PrestationExpertiseRapportConclusionAnnexes',
  components: {
    Checkboxs,
    Radios,
    TableRadios,
  },
})
export default class PrestationExpertiseRapportConclusionAnnexes extends Mixins(RapportExpertiseSectionMixin) {
  public annexeEnvironnementsToDisabled: number[] = [BusinessEntryEnum.DPE];

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get hasPlanCadastral(): boolean {
    return (
      this.rapport.piecesJointesRapport &&
      this.rapport.piecesJointesRapport.PlanCadastre &&
      this.rapport.piecesJointesRapport.PlanCadastre.length > 0
    );
  }

  get isfiscaleDocumentConsulte(): boolean {
    return isFiscaleSourceTaxeFonciereDocumentConsulte(this.rapport.fiscaleSourceTaxeFonciereId);
  }

  get isNotCertificat(): boolean {
    return isContratTypePrestationNotCertificatExpertise(this.rapport.contratTypePrestationId);
  }

  get annexeCadastralStatut(): number {
    return this.hasPlanCadastral ? AnnexeStatutEnum.Obtenu : AnnexeStatutEnum.NonObtenu;
  }

  get annexePlanLocalUrbanismeStatut(): number {
    return this.rapport.urbanistiquePlanLocalUrbanismeDisponibilite ? AnnexeStatutEnum.Obtenu : AnnexeStatutEnum.NonDemande;
  }

  get annexeJuridiqueDocumentsConsultes(): RapportExpertiseBusinessEntryRelationDto[] {
    return this.rapport.juridiqueDocumentConsultes;
  }

  //#endregion

  //#region WATCH
  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
