






















































































import { Component, Mixins, Prop } from 'vue-property-decorator';

import PrestationExpertiseSectionMixin from '@/mixins/prestation-expertise-section.mixin';

import ChoicePopup from '@/shared/components/choice-popup.vue';

@Component({
  name: 'PrestationExpertiseContexteIntervention',
  components: {
    ChoicePopup,
  },
})
export default class PrestationExpertiseContexteIntervention extends Mixins(PrestationExpertiseSectionMixin) {
  @Prop({ default: false }) isShowSpecificites: boolean;
  @Prop({ default: true }) canUpdateCategorieDActif: boolean;

  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
