export function urlToData(url: any, callback: any, callbackError: any = null): void {
  var xhr = new XMLHttpRequest();
  (xhr.onerror = function () {
    if (callbackError != null) callbackError();
  }),
    (xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    });
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
