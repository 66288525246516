var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.componentsSections && _vm.currentComponentSection)?_c('div',{staticClass:"prestation-expertise-sections-component",style:(_vm.cssVars)},[(_vm.isMobile)?_vm._l((_vm.componentsSections),function(componentSection){return _c('section',{key:componentSection.slug,staticClass:"mobile"},[(
          componentSection.properties !== undefined && componentSection.properties !== undefined
            ? componentSection.properties.isReadOnly
            : _vm.isReadOnly
        )?_c('div',{staticClass:"backdrop"}):_vm._e(),(_vm.currentComponentSection.slug === componentSection.slug)?_c('div',{staticClass:"prestation-expertise-sections-component-item"},[_c('div',{staticClass:"prestation-expertise-sections-component-item-title"},[_c('h2',[_vm._v(_vm._s(componentSection.name))])]),_c('div',{staticClass:"prestation-expertise-sections-component-item-content"},[_c(componentSection.componentName,_vm._g(_vm._b({tag:"component",model:{value:(_vm.mission),callback:function ($$v) {_vm.mission=$$v},expression:"mission"}},'component',Object.assign({}, _vm.commonComponentProperties, componentSection.properties),false),Object.assign({}, componentSection.outputs)))],1)]):_vm._e()])}):_vm._e(),(_vm.isBrowser)?[_c('h1',[_vm._v(_vm._s(_vm.mission.typePrestationExpertiseLibelle))]),_vm._l((_vm.componentsSections),function(parentComponentSection){return _c('section',{key:parentComponentSection.slug},[_c('h2',[_vm._v(" "+_vm._s(parentComponentSection.name)+" ")]),_vm._l((parentComponentSection.children),function(componentSection){return _c('div',{key:componentSection.slug,staticClass:"prestation-expertise-sections-component-item desktop",attrs:{"id":componentSection.slug}},[_c('div',{staticClass:"prestation-expertise-sections-component-item-content"},[(
              componentSection.properties !== undefined && componentSection.properties !== undefined
                ? componentSection.properties.isReadOnly
                : _vm.isReadOnly
            )?_c('div',{staticClass:"backdrop"}):_vm._e(),_c(componentSection.componentName,_vm._g(_vm._b({tag:"component",model:{value:(_vm.mission),callback:function ($$v) {_vm.mission=$$v},expression:"mission"}},'component',Object.assign({}, _vm.commonComponentProperties, componentSection.properties),false),Object.assign({}, componentSection.outputs)))],1)])})],2)})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }