











































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import DistributionInterieure from '@/components/rapport-expertise/distribution-interieur.vue';
import ExpSurface from '@/components/rapport-expertise/exp-surface.vue';
import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';

import {
  isContratTypeVisiteInterieurEtExterieur,
  isContratTypeVisiteSurPieces,
  isPrestationsDependanceTypeAutre,
  isPrestationsSurfaceAnnexeTypeAutre,
  isSourceDesSurfacesActeNotarie,
} from '@/helpers/rapport-expertise.helper';
import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
import PrestationExpertiseRapportDescriptionParticuliereAvisExpert from './prestation-expertise-rapport-description-particuliere-avis-expert.vue';

@Component({
  name: 'PrestationExpertiseRapportDescriptionParticuliereSurface',
  components: {
    DistributionInterieure,
    ExpSurface,
    RapportExpertiseFileUploader,
    NumericNullable,
    ChoicePopup,
    PrestationExpertiseRapportDescriptionParticuliereAvisExpert,
  },
})
export default class PrestationExpertiseRapportDescriptionParticuliereSurface extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get isContratTypeVisiteInterieurEtExterieur(): boolean {
    return isContratTypeVisiteInterieurEtExterieur(this.rapport.contratTypeVisiteId);
  }

  get isContratTypeVisiteSurPieces(): boolean {
    return isContratTypeVisiteSurPieces(this.rapport.contratTypeVisiteId);
  }

  get isSourceDesSurfacesActeNotarie(): boolean {
    return isSourceDesSurfacesActeNotarie(this.rapport.particuliereSourceDesSurfacesId);
  }

  //#endregion

  //#region WATCH
  @Watch('rapport.particuliereSourceDesSurfacesId')
  public particuliereSourceDesSurfacesIdChanged(): void {
    this.updateSurfaceTotaleHabitable();
  }

  @Watch('rapport.particuliereSurfaceTotaleHabitable')
  public particuliereSurfaceTotaleHabitableChanged(value: any): void {
    if (!this.isTypeDeBienTerrain) {
      this.rapport.evaluationSurfaceOuSuperficie = value;
    }
  }

  //#endregion

  //#region EVENTS
  public async onParticuliereSurfaceTotaleHabitableChange(value: any) {
    if (this.isMobile || isNullOrEmpty(value) || value === 0) return;
    var input = new DonneesEstimationImmobiliereUpdateInputDto();
    input.livingArea = value;
    await Vue.prototype.$rapportExpertiseApiService.updateDonneesEstimationImmobiliere(this.rapport.id, input);
  }
  //#endregion

  //#region FUNCTIONS
  public isPrestationsSurfaceAnnexeTypeAutre(prestationsSurface: any): boolean {
    return isPrestationsSurfaceAnnexeTypeAutre(prestationsSurface);
  }

  public isPrestationsDependanceTypeAutre(prestationsDependance: any): boolean {
    return isPrestationsDependanceTypeAutre(prestationsDependance);
  }

  public async updateSurfaceTotaleHabitable(): Promise<void> {
    if (!this.isSourceDesSurfacesActeNotarie) {
      let surfaceTotale = 0;
      this.rapport.distributionInterieureEtages.forEach((etage: any) => {
        etage.distributionInterieureSurfaces.forEach((surface: any) => {
          surfaceTotale = surfaceTotale + surface.superficie;
        });
      });

      this.rapport.particuliereSurfaceTotaleHabitable = surfaceTotale;
      await this.onParticuliereSurfaceTotaleHabitableChange(this.rapport.particuliereSurfaceTotaleHabitable);
    }
  }

  //#endregion
}
