import Vue from 'vue';
import router from '@/router';
import abp from 'abp-web-resources';
import store from '@/shared/store';

export class ExternalAuthenticationService {
  constructor() {}

  public async loginInToOmereAndRedirectToMission(
    token: string,
    missionId: string,
    username: string,
    nonce: string,
    timestamp: string
  ): Promise<any> {
    const loginParams = {
      token,
      missionId,
      username,
      nonce,
      timestamp,
    };

    await Vue.prototype.$logStoreService.info(
      `Connexion depuis Omere pour l'utilisateur ${username} - START`,
      'loginInToOmereAndRedirectToMission'
    );

    return new Promise((resolve, reject) => {
      Vue.axios
        .post('api/tokenauth/authenticatefromomere', loginParams, {
          baseURL: Vue.prototype.$config.baseUrlApi,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async (response) => {
          store.user = response.data.result.user;
          if (store.user) {
            var lastLoggedUserId = localStorage.getItem('userId');

            if (lastLoggedUserId && +lastLoggedUserId !== store.user.id) {
              Vue.prototype.$missionStoreService.clear();
              Vue.prototype.$localStorageService.setLastPullDate(null);
            }

            localStorage.setItem('userId', store.user.id.toString());
          }

          abp.auth.setToken(response.data.result.accessToken, undefined);
          abp.utils.setCookieValue('enc_auth_token', response.data.result.encryptedAccessToken, undefined, abp.appPath);

          localStorage.setItem('permissions', JSON.stringify(response.data.result.permissions));
          localStorage.setItem('comeFromLoginPage', JSON.stringify(false));

          await Vue.prototype.$logStoreService.info(
            `Connexion directe depuis Omere pour l'utilisateur ${username} - END`,
            'loginInToOmereAndRedirectToMission'
          );

          resolve(response);
        })
        .catch(async (error) => {
          abp.auth.clearToken();
          abp.utils.setCookieValue('enc_auth_token', undefined, undefined, abp.appPath);

          localStorage.removeItem('permissions');

          router.push({ path: '/compte/connexion' });

          reject(error);
        });
    });
  }
}
