




























import { Component, Prop, Vue } from 'vue-property-decorator';
import ExtendableList from '@/shared/components/extendable-list.vue';

@Component({
  name: 'ExtendableListString',
  components: {
    ExtendableList,
  },
})
export default class ExtendableListString extends Vue {
  @Prop() public value: any;
  @Prop() public rapportId: number;
  @Prop() public vertical: boolean;
  @Prop() public texteAjouter: string;
  @Prop() public validation: any;

  public isInitializing: boolean;

  //#region LIFE CYCLES

  mounted() {
    this.init();
  }

  updated() {
    this.init();
  }
  //#endregion

  //#region COMPUTED
  get list() {
    return this.value;
  }
  set list(newList: any) {
    this.value = newList;
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  public onItemAdded(): void {
    this.clean();
    if (this.isInitializing) {
      this.$nextTick(() => {
        const refInput: any = this.$refs.input;
        refInput[refInput.length - 1].$el.focus();
      });
    }
  }

  public OnRemoveItemClick(index: number): void {
    this.list.splice(index, 1);
    if (this.list.length == 0) {
      (this.$refs.list as ExtendableList).addItem();
    }
    this.$emit('input', this.list);
  }

  //#endregion

  //#region FUNCTIONS
  private init(): void {
    if (this.list.length == 0) {
      (this.$refs.list as ExtendableList).addItem();
      this.$emit('input', this.list);
    }
    this.isInitializing = true;
  }

  public clean(): void {
    var i = this.list.length - 1;
    while (--i >= 0) {
      if (this.list[i] === undefined || this.list[i].value === undefined || (this.list[i].value = this.list[i].value.trim()) === '') {
        this.list.splice(i, 1);
        this.$emit('input', this.list);
      }
    }
  }

  //#endregion
}
