


















































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
import RapportExpertiseDpe from '@/shared/components/rapport-expertise/rapport-expertise-dpe.vue';
import ScenarioRenovation from '@/shared/components/rapport-expertise/rapport-scenario-renovation.vue';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';
import { RenovationScenarioDetailed, ScenarioRenovationDto } from '@/shared/dtos/scenario-renovation.dto';
import { FeatureAccessClient } from '@/shared/models/feature-access-client.model';
import { features } from 'process';
import { EventBus } from '@/main';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';

@Component({
  name: 'PrestationExpertiseRapportDescriptionParticuliereBatiment',
  components: {
    VisaDisplay,
    RapportExpertiseFileUploader,
    RadioBoolean,
    ChoicePopup,
    RapportExpertiseDpe,
    ScenarioRenovation,
  },
})
export default class PrestationExpertiseRapportDescriptionParticuliereBatiment extends Mixins(RapportExpertiseSectionMixin) {
  public yearRange: any = {};
  public defaultTextDropDownYearRange = "Sélectionnez une plage d'années";
  public textDropDownYearRange = this.defaultTextDropDownYearRange;
  public accessScenarioRenovation: FeatureAccessClient[];
  public isScenarioRenovationAllowed: boolean = false;

  //#region LIFE CYCLES

  async mounted() {
    await Vue.prototype.$permissionService
      .getFeatureAccessByClient(this.mission.societeNom)
      .then(async (subjectMessage: FeatureAccessClient[]) => {
        if (subjectMessage != null) {
          this.accessScenarioRenovation = subjectMessage;
          this.isScenarioRenovationAllowed = subjectMessage.some(
            (access) => access.feature === 'ScenarioRenovation' && this.mission.societeNom == access.clientName
          );
        }
      });

    await this.loadReferentiel();
    if (this.rapport.isAnneeConstructionEstimation)
      this.textDropDownYearRange = this.yearRange[this.rapport.caracteristiquesAnneeConstruction];
  }
  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH
  @Watch('typeDeBien')
  public typeDeBienChanged(newVal: any): void {
    if (this.isTypeDeBienAppartement) {
      this.rapport.caracteristiqueAssainissementId = null;
      this.rapport.caracteristiquesMitoyennete = null;
      this.rapport.caracteristiquesMitoyenneteCoteId = null;
    }

    // on clean les équipements & agréments non-valides au changement de type de bien
    for (let i = 0; i < this.rapport.prestationsEquipements.length; i++) {
      let equipement = this.businessEntries.prestationsEquipements.filter(
        (e: any) => e.id == this.rapport.prestationsEquipements[i].businessEntrySelectedId
      )[0];

      if (equipement.categorieActifType != 0 && equipement.categorieActifType != newVal) this.rapport.prestationsEquipements.splice(i--, 1);
    }

    for (let j = 0; j < this.rapport.prestationsAgrements.length; j++) {
      let agrement = this.businessEntries.prestationsAgrements.filter(
        (e: any) => e.id == this.rapport.prestationsAgrements[j].businessEntrySelectedId
      )[0];

      if (agrement.categorieActifType != 0 && agrement.categorieActifType != newVal) this.rapport.prestationsAgrements.splice(j--, 1);
    }
  }

  @Watch('rapport.caracteristiquesMitoyennete')
  public caracteristiquesMitoyenneteChanged(value: boolean): void {
    if (value === false) this.rapport.caracteristiquesMitoyenneteCoteId = null;
  }

  //#endregion

  //#region EVENTS
  public async onCaracteristiquesAnneeConstructionChange(value: number) {
    if (this.isMobile || isNullOrEmpty(value)) return;

    this.textDropDownYearRange = this.defaultTextDropDownYearRange;
    this.rapport.isAnneeConstructionEstimation = false;
    this.rapport.caracteristiquesAnneeConstruction = value;

    var input = new DonneesEstimationImmobiliereUpdateInputDto();
    input.buildingYear = value;
    input.isAnneeConstructionEstimation = true;
    await Vue.prototype.$rapportExpertiseApiService.updateDonneesEstimationImmobiliere(this.rapport.id, input);
  }
  public async onCaracteristiquesAnneeConstructionClick() {
    this.textDropDownYearRange = this.defaultTextDropDownYearRange;
    this.rapport.isAnneeConstructionEstimation = false;
    this.rapport.caracteristiquesAnneeConstruction = null;
  }

  //value = obtenu/non obtenu
  public async onAnnexeDpeEmit(value: any): Promise<void> {
    await Vue.prototype.$missionApiService.deleteAllScenarioRenovationByRapportId(this.mission.prestationExpertise.id);
    const annexeDPE = this.rapport.annexeEnvironnements.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
    if (annexeDPE) annexeDPE.businessEntryQualificationId = value;

    EventBus.$emit('GetScenarioRenovationEmit', this.rapport.caracteristiquesDPEId);
  }

  public async selectRange(value: string, yearApproximate: number) {
    if (this.isMobile || isNullOrEmpty(value)) return;

    this.textDropDownYearRange = value;
    this.rapport.isAnneeConstructionEstimation = true;
    this.rapport.caracteristiquesAnneeConstruction = yearApproximate;

    var input = new DonneesEstimationImmobiliereUpdateInputDto();
    input.buildingYear = yearApproximate;
    input.isAnneeConstructionEstimation = true;
    await Vue.prototype.$rapportExpertiseApiService.updateDonneesEstimationImmobiliere(this.rapport.id, input);
  }

  //#endregion

  //#region FUNCTIONS

  private async loadReferentiel() {
    await Vue.prototype.$referentielService
      .getAnneeConstruction()
      .then(async (refs: { [key: number]: string }) => {
        this.yearRange = refs;
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération des périodes de construction.`);
        await Vue.prototype.$logStoreService.error(`Referentiel - ERROR`, error, 'Périodes de construction');
      });
  }

  //#endregion
}
