



















import { Component } from 'vue-property-decorator';
import PrestationExpertiseMenu from '@/components/prestation-expertise/prestation-expertise-menu';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import { ComponentSection } from '@/shared/models/component-section';

@Component({
  name: 'PrestationExpertiseMobileMenu',
  components: {
    BDropdown,
    BDropdownItem,
  },
})
export default class PrestationExpertiseMobileMenu extends PrestationExpertiseMenu {
  public isMenuOpened = false;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onMenuToggleClick(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  public onMenuItemClick(componentSection: ComponentSection): void {
    this.isMenuOpened = false;

    document.querySelector('main').scrollTo({
      top: 0,
      left: 0,
    });

    this.$emit('componentSectionChanged', componentSection);
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
