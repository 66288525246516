











































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import Suggestions from 'v-suggestions';
import PrestationExpertiseFileUploader from '@/shared/components/prestation-expertise/prestation-expertise-file-uploader.vue';

import 'v-suggestions/dist/v-suggestions.css';
import { Guid } from 'guid-typescript';
import { urlToData } from '@/shared/utilities/url.utility';
import { addressInputChange, geocode } from '@/shared/utilities/google-maps.utility';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';

@Component({
  name: 'prestation-expertise-google-maps-street',
  components: {
    Suggestions,
    PrestationExpertiseFileUploader,
  },
})
export default class PrestationExpertiseGoogleMapsStreet extends Vue {
  private _satelliteMap: any = null;
  private _roadMap: any = null;
  private _street: any = null;
  private _satelliteMapMarker: any = null;

  @Inject('addPieceJointe') public addPieceJointe: any;

  @Prop() public address: string;
  @Prop() public latitude: number;
  @Prop() public longitude: number;
  @Prop() public typeCapturedPieceJointe: string;
  @Prop() public piecesJointes: { [index: string]: PrestationExpertisePieceJointeDto[] };
  @Prop() public validation: any;
  @Prop({ default: 'Capturer et ajouter aux photos' }) public label: string;
  @Prop({ default: true }) multiple: boolean;

  public googleSatelliteMapId: string;
  public googleRoadMapId: string;
  public googleStreetId: string;
  public suggestedAddress: string = null;
  public autoCompleteService: any = null;
  public apiKey: string = Vue.prototype.$config.googleMapApiKey;
  public sessionToken: any = null;
  public geocoderService: any = null;
  public options: any = {
    placeholder: 'Géolocaliser une adresse',
    inputClass: 'v-suggestions-input col mb-3 search',
  };

  public isSatelliteMapDisplayed = false;

  //#region LIFE CYCLES
  public beforeMount(): void {
    const id = Guid.create();
    this.googleSatelliteMapId = 'google-satellite-map-' + id;
    this.googleRoadMapId = 'google-road-map-' + id;
    this.googleStreetId = 'google-street-' + id;
    this.suggestedAddress = this.address;
  }

  public mounted(): void {
    this.initGoogleMapsAndStreetServicesAndComponents();
  }

  //#endregion

  //#region COMPUTED

  get canCapture(): boolean {
    return this.multiple || (!this.multiple && this.filteredPiecesJointes.length < 1);
  }

  get filteredPiecesJointes(): PrestationExpertisePieceJointeDto[] {
    return this.piecesJointes[this.typeCapturedPieceJointe] || [];
  }

  //#endregion

  //#region WATCH
  @Watch('address')
  public addressValueChanged(value: any): void {
    this.suggestedAddress = value;
    this.onAddressSelected(value);
  }

  //#endregion

  //#region EVENTS
  public onAddressSelected(address: any): any {
    this.geocode({ address }).then(this.setSuggestedAddress).then(this.setPosition).then(this.setMarker);
  }

  public async onAddressInputChange(value: string): Promise<string[]> {
    return await addressInputChange(value, this.autoCompleteService, this.sessionToken);
  }

  public toDataURL(url: any, callback: any): void {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  public onCaptureGoogleStreeViewClick(): void {
    if (!this.canCapture) {
      return;
    }

    const imageName = `google-street-view-${Guid.create().toString()}.jpg`;
    const streetPosition = this._street.getPosition();

    let staticMapUrl: string;

    if (this.isSatelliteMapDisplayed) {
      const mapCenter = this._satelliteMap.getCenter();
      const mapType = this._satelliteMap.getMapTypeId();

      staticMapUrl =
        `https://maps.googleapis.com/maps/api/staticmap` +
        `?center=${mapCenter.lat()},${mapCenter.lng()}` +
        `&zoom=${this._satelliteMap.getZoom()}` +
        `&size=640x400` +
        `&key=${this.apiKey}` +
        `&scale=2` +
        `&maptype=${mapType}`;
    } else {
      staticMapUrl =
        'https://maps.googleapis.com/maps/api/streetview' +
        `?size=640x400` +
        `&location=${streetPosition.lat()},${streetPosition.lng()}` +
        `&fov=${180 / Math.pow(2, this._street.getZoom())}` +
        `&heading=${this._street.getPov().heading}` +
        `&pitch=${this._street.getPov().pitch}` +
        `&key=${this.apiKey}`;
    }

    urlToData(staticMapUrl, async (dataUrl: any) => {
      await this.addPieceJointe(this.typeCapturedPieceJointe, dataUrl, imageName);
    });
  }

  public onToggleTabClick(value: boolean): void {
    this.isSatelliteMapDisplayed = value;
  }

  //#endregion

  //#region FUNCTIONS
  private initGoogleMapsAndStreetServicesAndComponents(): void {
    this.geocoderService = new Vue.prototype.$googleMapsApi.Geocoder();
    this.sessionToken = new Vue.prototype.$googleMapsApi.places.AutocompleteSessionToken();
    this.autoCompleteService = new Vue.prototype.$googleMapsApi.places.AutocompleteService();

    const coordinates = { lat: this.latitude, lng: this.longitude };

    this._satelliteMap = new Vue.prototype.$googleMapsApi.Map(document.getElementById(this.googleSatelliteMapId), {
      center: coordinates,
      zoom: 14,
      mapTypeId: 'hybrid',
      mapTypeControl: false,
      streetViewControl: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });

    this.setMarker({
      geometry: { location: { lat: this.latitude, lng: this.longitude } },
    });

    this._roadMap = new Vue.prototype.$googleMapsApi.Map(document.getElementById(this.googleRoadMapId), {
      center: coordinates,
      zoom: 14,
      mapTypeId: 'roadmap',
      mapTypeControl: false,
    });

    this._street = new Vue.prototype.$googleMapsApi.StreetViewPanorama(document.getElementById(this.googleStreetId), {
      position: coordinates,
    });

    this._roadMap.setStreetView(this._street);

    this._street.addListener('position_changed', () => {
      this.geocode({ location: this._street.getPosition() }).then(this.setSuggestedAddress);
    });
  }

  private setPosition(place: any) {
    this._street.setPosition(place.geometry.location);
    this._roadMap.setCenter(place.geometry.location);
    this._satelliteMap.setCenter(place.geometry.location);

    return place;
  }

  private geocode(parameters: any): any {
    return geocode(this.geocoderService, parameters);
  }

  private setSuggestedAddress(place: any): any {
    const adresseGoogleMapsForm: any = {
      street_number: 'short_name',
      route: 'long_name',
      postal_code: 'short_name',
      locality: 'long_name',
      country: 'long_name',
    };

    const result: any = [];
    for (const address of place.address_components) {
      const addressType: any = address.types[0];
      if (adresseGoogleMapsForm[addressType]) {
        result[addressType] = address[adresseGoogleMapsForm[addressType]];
      }
    }

    let addressString = '';
    if (result.street_number) {
      addressString += ` ${result.street_number}`;
    }

    if (result.route) {
      addressString += ` ${result.route}`;
    }

    if (result.postal_code) {
      addressString += ` ${result.postal_code}`;
    }

    if (result.locality) {
      addressString += ` ${result.locality}`;
    }

    if (result.country) {
      addressString += ` ${result.country}`;
    }

    this.suggestedAddress = addressString.substring(1);

    return place;
  }

  public setMarker(geolocalisation: any): any {
    if (this._satelliteMapMarker) {
      this._satelliteMapMarker.setMap(null);
      this._satelliteMapMarker = null;
    }

    return new Promise<void>((resolve: any) => {
      const location = geolocalisation.geometry.location;

      this._satelliteMapMarker = new Vue.prototype.$googleMapsApi.Marker({
        map: this._satelliteMap,
        position: location,
        draggable: false,
      });
      resolve();
    });
  }

  //#endregion
}
