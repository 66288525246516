





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

@Component({
  name: 'Radios',
  components: {
    BModal,
  },
})
export default class Radios extends Vue {
  @Prop() public value: any;
  @Prop() public list: any;
  @Prop() public validation: Boolean;
  @Prop() public canDeselect: Boolean;
  @Prop() public isDisabled: Boolean;
  @Prop() public title: String;
  @Prop({ default: true }) public titleBold: boolean;
  @Prop({ default: true }) public state: boolean;

  public baseURL: string = Vue.prototype.$config.baseUrlApi;
  public valueToValidate: any = null;

  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS
  public onToggleClick(itemValue: any): void {
    if (this.validation) {
      this.valueToValidate = itemValue;
      this.$emit('onShowModalValidation');
    } else {
      if (this.canDeselect && this.value == itemValue) {
        this.$emit('input', null);
      } else {
        this.$emit('input', itemValue);
      }

      this.$emit('onCloseChoicePopup');
    }
  }

  //#endregion

  //#region FUNCTIONS
  public submitValidation(): void {
    this.$emit('input', this.valueToValidate);
    this.valueToValidate = null;
  }

  public cancelValidation(): void {
    this.valueToValidate = null;
  }

  public getIconCount(item: any): number {
    return item.iconRepetition != null && item.iconRepetition > 0 ? item.iconRepetition : 1;
  }

  //#endregion
}
