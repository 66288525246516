import { required, helpers } from 'vuelidate/lib/validators';
const etatAvancementTravauxValidation = {
  mission: {
    dateVisite: {
      required,
    },
    prestationExpertise: {
      contratCategorieDActifId: { required },
      contratTypeBienId: {
        customRequired(value) {
          return this.businessEntries.contratTypeBiens.filter((b) => b.parentId == this.etatAvancementTravaux.contratCategorieDActifId)
            .length > 0
            ? helpers.req(value)
            : true;
        },
      },
      piecesJointes: {
        PhotoBien: {
          required,
        },
      },
      avisExpert: {
        required,
      },
      pointsInspectionsRelations: {
        $each: {
          etat: { required },
        },
      },
      estConclusionValide: {
        required,
      },
      etatAvancement: {
        required,
      },
    },
    contratGroup: [
      'mission.prestationExpertise.contratCategorieDActifId',
      'mission.prestationExpertise.contratTypeBienId',
      'mission.dateVisite',
    ],
    bienGroup: ['mission.prestationExpertise.piecesJointes.PhotoBien'],
    pointsInspectionsGroup: ['mission.prestationExpertise.pointsInspectionsRelations'],
    commentairesGroup: ['mission.prestationExpertise.avisExpert'],
    conclusionGroup: ['mission.prestationExpertise.estConclusionValide', 'mission.prestationExpertise.etatAvancement'],
  },
};

export { etatAvancementTravauxValidation };
