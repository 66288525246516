



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import { mixins } from 'vue-class-component';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

@Component({
  name: 'TableSurfaces',
  components: {
    NumericNullable,
  },
})
export default class TableSurfaces extends mixins(RapportExpertiseSectionMixin) {
  @Prop() public referenceDataSurfaceTypes: any;
  @Prop() public surfaceTypeIsAutre: Function;
  @Prop() public surfaces: any;
  @Prop() public surfacesChanged: Function;

  public fields: any = [
    {
      key: 'type',
      class: 'table-column-type',
    },
    {
      key: 'customLabel',
      class: 'table-column-customLabel',
    },
    {
      key: 'commentaire',
      class: 'table-column-commentaire',
    },
    {
      key: 'superficie',
      class: 'table-column-superficie',
      thClass: 'table-head-superficie',
    },
    {
      key: 'action',
      class: 'table-column-action',
    },
  ];

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get superficieTotale() {
    return this.surfaces.reduce((sum: any, curr: any) => sum + curr.superficie, 0).toLocaleString('fr-FR', { minimumFractionDigits: 2 });
  }

  get surfaceTableItems() {
    return this.surfaces.map((s: any) => {
      if (s.commentaire && !s._showDetails)
        Object.assign(s, {
          _showDetails: true,
        });
      else if (s._showDetails == null)
        Object.assign(s, {
          _showDetails: false,
        });
      return s;
    });
  }

  //#endregion

  //#region EVENTS

  public onSurfaceTypeClicked(type: any): void {
    this.surfaces.push({
      typeBusinessItemId: type.id,
      customLabel: null,
      commentaire: null,
      superficie: null,
    });
  }

  public onDeleteSurfaceClick(index: number) {
    this.surfaces.splice(index, 1);
    this.surfacesChanged();
  }

  public onDeleteCommentaireClick(index: number) {
    this.surfaces[index].commentaire = null;
    this.onHideDetailsClick(index);
  }

  public onShowDetailsClick(index: number): void {
    this.surfaces[index]._showDetails = true;
    this.$forceUpdate(); // LT ajouté parce que b-table ne semble pas réagir et après moults essais, j'ai craqué
  }

  public onHideDetailsClick(index: number): void {
    this.surfaces[index]._showDetails = false;
    this.$forceUpdate();
  }

  public onItemChange(item: any): void {
    if (!this.surfaceTypeIsAutre(item)) {
      item.customLabel = null;
    }
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
