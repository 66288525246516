






import { RapportExpertiseNoteSectionDto } from '@/shared/dtos/rapport-expertise-section-note.dto';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'VisaSectionStatus',
})
export default class VisaSectionStatus extends Vue {
  @Prop({ required: true }) public note: RapportExpertiseNoteSectionDto;

  //#region LIFE CYCLES
  //#endregion

  //#region COMPUTED
  get display(): boolean {
    if (this.$can('Save', 'Notes')) return true;
    if (this.note && this.note.isValid === false && this.$can('Get', 'Notes')) return true;
    return false;
  }

  get title(): string {
    if (this.note && this.note.isValid === false) return 'Cette section a été refusée';
    if (this.note && this.note.isValid) return 'Cette section a été acceptée';

    return "Cette section n'a pas été notée";
  }

  get icon(): string {
    if (this.note && this.note.isValid === false) return 'times';
    if (this.note && this.note.isValid) return 'check';

    return 'ban';
  }

  get className(): any {
    return {
      'text-warning': this.note && this.note.isValid === false,
      'text-success': this.note && this.note.isValid,
    };
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
