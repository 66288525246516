import abp from 'abp-web-resources';
import { UserLoginInfoDto } from '@/shared/dtos/user-login-info.dto';

export default {
  user: null as UserLoginInfoDto,
  comeFromLoginPage: JSON.parse(localStorage.getItem('comeFromLoginPage')),
  isLoading: false,
  loadingProgression: null as number,
  isLogged: abp.auth.getToken() != undefined && abp.auth.getToken() != '',
};
