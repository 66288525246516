








import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'PrestationExpertiseRapportAvisExpert',
})
export default class PrestationExpertiseRapportAvisExpert extends Vue {
  @Prop() public value: string;
  @Prop() public validationError: any;
  @Prop() public placeHolder: string;
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED

  get avisExpert() {
    return this.value;
  }

  set avisExpert(val: any) {
    this.$emit('input', val);
  }

  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
