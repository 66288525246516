










import { Component, Vue } from 'vue-property-decorator';
import { contratTypePrestationColorDefault } from '@/helpers/rapport-expertise.helper';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import store from '@/shared/store';
import { PendingQueueStatusEnum } from '@/shared/enums/pending-queue-status.enum';

@Component({
  name: 'app',
  components: {
    Spinner,
  },
})
export default class App extends Vue {
  public isLoading: boolean = false;
  public contratTypePrestationColorDefault = contratTypePrestationColorDefault;
  public pendingQueueStatus = 0;
  public pendingQueueStatusEnum = PendingQueueStatusEnum;
  public store = store;
  public currentQueueItemIndex = 0;
  public nbQueueItems = 0;
  public replayingProgress = 0;

  //#region LIFE CYCLES
  mounted() {
    this.handleSWMessages();
  }

  handleSWMessages(): void {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        switch (event.data.type) {
          default:
            break;
        }
      });
    }
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  async accept() {
    await Vue.prototype.$workbox.messageSW({ type: 'SKIP_WAITING' });
  }

  //#endregion
}
