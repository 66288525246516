





















































import BasicLayout from './layouts/basic.layout.vue';
import { SortAriaLabelDirective } from '@/shared/directives/sort-aria-label.directive';
import { Component, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/shared/components/header-title.vue';
import { getTypePrestationExpertiseEnumName } from '@/shared/utilities/enums.utility';
import store from '@/shared/store';
import { TypePrestationExpertiseEnum } from '@/shared/enums/type-prestation-expertise.enum';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { StatutVisaEnum } from '@/shared/enums/visa-statut.enum';

Vue.directive('sort-aria-label', SortAriaLabelDirective);

@Component({
  name: 'MissionsAController',
  components: {
    BasicLayout,
    HeaderTitle,
  },
})
export default class MissionsAController extends Vue {
  public store = store;
  public missions: MissionDto[] = null;

  public fields: any = [
    {
      key: 'reference',
      label: 'Référence',
      sortable: true,
    },
    {
      key: 'dateVisite',
      label: 'Date mission',
      sortable: true,
    },
    {
      key: 'contactNomPrenom',
      label: 'Mandant',
      sortable: true,
    },
    {
      key: 'societeNom',
      label: 'Client',
      sortable: true,
    },
    {
      key: 'expertise.expert',
      label: 'CDM',
      sortable: true,
    },
    {
      key: 'typePrestationExpertise',
      label: 'Objet',
      sortable: true,
      formatter: (value: TypePrestationExpertiseEnum) => {
        return getTypePrestationExpertiseEnumName(value);
      },
    },
    {
      key: 'bien.adresseComplete',
      label: 'Adresse',
      sortable: true,
    },
    {
      key: 'expertise.dataRapport.valeurVenaleMin',
      label: 'Valeur min.',
      sortable: true,
    },
    {
      key: 'expertise.dataRapport.valeurVenaleMax',
      label: 'Valeur max.',
      sortable: true,
    },
    {
      key: 'expertise.suiviMission.statutVisa',
      label: 'Visée',
      sortable: true,
      formatter: (value: any, key: any, item: MissionDto) => {
        return item.expertise.suiviMission.statutVisa === StatutVisaEnum.Accepte ? 'OUI' : 'NON';
      },
    },
    {
      key: 'expert.nomComplet',
      label: 'Expert',
      sortable: true,
    },
    {
      key: 'openMission',
      label: '',
      sortable: false,
      formatter: (value: any, key: any, item: MissionDto) => {
        return '/missions/' + item.id + '/' + item.typePrestationExpertiseString;
      },
    },
  ];

  public perPage = 10;
  public currentPage = 1;

  //#region LIFE CYCLES
  beforeMount(): void {
    this.missions = this.$route.meta.missions as MissionDto[];
  }
  //#endregion

  //#region COMPUTED
  get rows(): number {
    return this.missions.length;
  }
  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
