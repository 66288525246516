












































import { Component, Inject, Mixins, Prop } from 'vue-property-decorator';

import PrestationExpertiseSectionMixin from '@/mixins/prestation-expertise-section.mixin';

import GoogleMap from '@/shared/components/google-map.vue';
import { MapStyle } from '@/shared/models/google-map/map-style.model';

@Component({
  name: 'PrestationExpertiseSituationAdresse',
  components: {
    GoogleMap,
  },
})
export default class PrestationExpertiseSituationAdresse extends Mixins(PrestationExpertiseSectionMixin) {
  @Inject('updateGeolocalisation') public updateGeolocalisation: any;

  @Prop({ default: true }) public isCaptureDisplayed: boolean;
  @Prop({ required: true }) public showCaptureAerienne: boolean;

  styles: MapStyle[] = [{ elementType: 'all', featureType: 'poi', stylers: [{ visibility: 'off' }] }];

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onEmitAdresseChanged(value: any): void {
    this.prestationExpertise.geographiqueAdresse = value;
  }

  public onEmitCoordinatesChanged(latitude: any, longitude: any): void {
    if (this.prestationExpertise.geographiqueLatitude != latitude || this.prestationExpertise.geographiqueLongitude != longitude) {
      this.prestationExpertise.geographiqueLatitude = latitude;
      this.prestationExpertise.geographiqueLongitude = longitude;
      this.prestationExpertise.geographiqueSituationGenerale = null;
      this.prestationExpertise.geographiqueSituationParticuliere = null;
      this.prestationExpertise.geographiqueVueAerienne = null;
      this.updateGeolocalisation();
    }
  }

  public onEmitLatitudeChanged(value: any): void {
    if (this.prestationExpertise.geographiqueLatitude != value) {
      this.prestationExpertise.geographiqueLatitude = value;
    }
  }

  public onEmitLongitudeChanged(value: any): void {
    if (this.prestationExpertise.geographiqueLongitude != value) {
      this.prestationExpertise.geographiqueLongitude = value;
    }
  }

  public onEmitSituationGeneraleCaptured(value: any): void {
    this.prestationExpertise.geographiqueSituationGenerale = value;
  }

  public onEmitSituationParticuliereCaptured(value: any): void {
    this.prestationExpertise.geographiqueSituationParticuliere = value;
  }

  public onEmitVueAerienneCaptured(value: any): void {
    this.prestationExpertise.geographiqueVueAerienne = value;
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
