import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import { BaseDtoApiService } from './base-dto-api.service';
import axios from 'axios';
import { FeatureAccessClient } from '@/shared/models/feature-access-client.model';

export class PermissionsService {
  constructor() {}

  public getFeatureAccessByClient(clientName: string): Promise<FeatureAccessClient[]> {
    return axios.get(`/featureaccess/${clientName}`).then((response: any) => {
      return response.data.result as FeatureAccessClient[];
    });
  }
}
