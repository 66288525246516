import { ExpertiseDto } from '@/shared/dtos/expertise.dto';
import { TypePrestationExpertiseEnum } from '@/shared/enums/type-prestation-expertise.enum';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class ExpertiseApiService extends BaseDtoApiService<ExpertiseDto> {
  constructor() {
    super('expertises');
  }

  public deposer(expertiseId: number, missionId: number, typePrestationExpertise: TypePrestationExpertiseEnum): Promise<void> {
    return axios.put(`${this._endpoint}/deposer/${expertiseId}/mission/${missionId}`, typePrestationExpertise, {
      headers: { 'content-type': 'application/json' },
    });
  }

  public getRapportById(id: number): Promise<any> {
    return axios.get(`${this._endpoint}/${id}/rapport`, {
      responseType: 'blob' as 'json',
    });
  }
}
