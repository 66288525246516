



















































import { MissionDoublonDto } from '@/shared/dtos/mission-doublon.dto';
import store from '@/shared/store';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'PrestationExpertiseRapportIndicateurs',
  components: {},
})
export default class PrestationExpertiseRapportIndicateurs extends Vue {
  @Prop() doublons: MissionDoublonDto[];
  @Prop() nbAnnexes: number;

  //#region LIFE CYCLES

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onViewRapportClick(expertiseId: number): void {
    store.isLoading = true;
    Vue.prototype.$expertiseApiService
      .getRapportById(expertiseId)
      .then((response: any) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        var blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public onDownloadRapportClick(expertiseId: number): void {
    store.isLoading = true;
    Vue.prototype.$expertiseApiService
      .getRapportById(expertiseId)
      .then((response: any) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });

        var filename = response.headers['content-disposition'].split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();

        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  //#endregion
}
