






import { Component, Vue } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
@Component({
  name: 'Captcha',
  components: {
    VueRecaptcha,
  },
})
export default class Captcha extends Vue {
  public captchaApiKey: string = Vue.prototype.$config.recaptchaApiKey;

  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS

  public onVerifyClick(response: string): void {
    this.$emit('outputCaptchaClick', response);
  }

  //#endregion

  //#region FUNCTIONS
  public reset(): void {
    const captcha: any = this.$refs.recaptcha;
    if (captcha) captcha.reset();
  }
  //#endregion
}
