import { EtudeDeMarcheDto } from '@/shared/dtos/etude-de-marche.dto';
import { Component, Mixins } from 'vue-property-decorator';
import PrestationExpertiseSectionMixin from './prestation-expertise-section.mixin';
import { RapportExpertiseNoteSectionEnum } from '@/shared/enums/rapport-expertise-note-section.enum';
import { getRapportExpertiseNoteSectionEnumName } from '@/shared/utilities/enums.utility';

@Component({
  name: 'EtudeDeMarcheSectionMixin',
})
export default class EtudeDeMarcheSectionMixin extends Mixins(PrestationExpertiseSectionMixin) {
  public rapportExpertiseNoteSectionEnum = RapportExpertiseNoteSectionEnum;
  public getRapportExpertiseNoteSectionEnumName = getRapportExpertiseNoteSectionEnumName;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get etudeDeMarche(): EtudeDeMarcheDto {
    return this.prestationExpertise as EtudeDeMarcheDto;
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
