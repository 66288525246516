





























































import { Component, Vue } from 'vue-property-decorator';

import PrestationExpertiseLayout from '@/views/layouts/prestation-expertise.layout.vue';
import PrestationExpertise from '@/views/prestation-expertise/prestation-expertise';
import PrestationExpertiseSections from '@/components/prestation-expertise/prestation-expertise-sections.vue';
import PrestationExpertiseDesktopMenu from '@/components/prestation-expertise/prestation-expertise-desktop-menu.vue';
import PrestationExpertiseMobileMenu from '@/components/prestation-expertise/prestation-expertise-mobile-menu.vue';
import PrestationExpertiseHeaderActions from '@/components/prestation-expertise/prestation-expertise-header-actions.vue';

import { etatAvancementTravauxValidation } from '@/validations/etat-avancement-travaux.validation';
import { EtatAvancementTravauxDto } from '@/shared/dtos/etat-avancement-travaux.dto';
import { ComponentSection } from '@/shared/models/component-section';
import { PrestationExpertisePointInspectionDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-point-inspection.dto';
import { PointInspectionCategorieEnum } from '@/shared/enums/point-inspection-categorie.enum';
import { getPointInspectionCategorieEnumName } from '@/shared/utilities/enums.utility';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { PrestationExpertiseProprietesDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-proprietes.dto';
import { MergeDto } from '@/shared/dtos/merge.dto';
import PrestationExpertiseMissionMerge from '@/shared/components/prestation-expertise/prestation-expertise-mission-merge.vue';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';

@Component({
  name: 'PrestationExpertiseEtatAvancementTravaux',
  components: {
    // Layout
    PrestationExpertiseLayout,
    PrestationExpertiseSections,
    PrestationExpertiseDesktopMenu,
    PrestationExpertiseMobileMenu,
    PrestationExpertiseHeaderActions,
    PrestationExpertiseMissionMerge,
  },
  validations: etatAvancementTravauxValidation,
})
export default class PrestationExpertiseEtatAvancementTravaux extends PrestationExpertise {
  constructor() {
    // Cannot pass componentsSections in the parent bescause @Component doesn't support properties in contructor...
    super();
  }

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    if (this.hasAccess) {
      this.initComponentSections();

      await this.initMergeDatas();
    }
  }

  public mounted(): void {
    // Watch à cet endroit là pour éviter de le trigger dès le début et MAJ la date de modification sans le vouloir
    // VueJS semble mettre à jour l'objet mission
    setTimeout(() => {
      this.$watch(
        'etatAvancementTravaux.proprietes.estEditionForcee',
        async () => {
          this._needToByPasseHasPrestationExpertiseChanged = true;
          const logMessage = `Sauvegarde des propriétés: ${this.mission.reference}`;
          this.saveInStorage(true);

          await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertiseEtatAvancementTravaux');

          Vue.prototype.$prestationExpertiseProprietesApiService
            .update((this.etatAvancementTravaux.proprietes as PrestationExpertiseProprietesDto).id, this.etatAvancementTravaux.proprietes)
            .then(async () => {
              await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertiseEtatAvancementTravaux');
              Vue.prototype.$notificationService.success(`Les propriétés ont été sauvegardées avec succès.`);
            })
            .catch(async (error: any) => {
              Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la sauvegarde des propriétés.`);
              await Vue.prototype.$logStoreService.info(`${logMessage} - ERROR`, error, 'PrestationExpertiseEtatAvancementTravaux');
            });
        },
        { deep: true }
      );
    }, 50);
  }

  //#endregion

  //#region COMPUTED
  get etatAvancementTravaux(): EtatAvancementTravauxDto {
    return this.mission.prestationExpertise as EtatAvancementTravauxDto;
  }

  set etatAvancementTravaux(value: EtatAvancementTravauxDto) {
    this.mission.prestationExpertise = value;
  }

  get color(): string {
    return '#00ab9d';
  }
  //#endregion

  //#region WATCH
  //#endregion

  //#region EVENTS
  //#endregion

  //#region FUNCTIONS
  protected cleanBeforeSaveInStorage(missionToClean: MissionDto): void {}

  protected async cleanBeforeSave(): Promise<void> {}

  private initComponentSections(): void {
    var pointsInspections: PrestationExpertisePointInspectionDto[] = Vue.prototype.$localStorageService.getPointsInspections();
    var isHorsAir = this.mission.motifPrestationExpertise === "Hors d'air";

    var childrenPointsInspections: ComponentSection[] = isHorsAir
      ? this.getComponentsSectionsForHorsAir(pointsInspections)
      : this.getComponentsSectionsForHorsEau(pointsInspections);

    this.componentsSections = [
      {
        slug: 'rapport',
        name: 'rapport',
        isOnlyForDesktop: false,
        children: [
          {
            slug: 'rapport-proprietes',
            name: 'propriétés',
            isOnlyForDesktop: false,
            componentName: 'PrestationExpertiseProprietes',
            properties: {
              proprietes: this.etatAvancementTravaux.proprietes as PrestationExpertiseProprietesDto,
              prestationExpertiseId: this.etatAvancementTravaux.id,
              isReadOnly: false,
            },
            permission: 'SavePropriete|Rapport',
          },
        ],
        permission: 'SavePropriete|Rapport',
      },
      {
        slug: 'contrat',
        name: 'contrat',
        children: [
          {
            slug: 'contrat-mission',
            name: 'mission',
            isActive: true,
            componentName: 'PrestationExpertiseEtatAvancementTravauxContratMission',
          },
        ],
        validationName: 'contratGroup',
      },
      {
        slug: 'bien',
        name: 'bien',
        children: [
          {
            slug: 'photos-bien',
            name: 'photos du bien',
            isActive: true,
            componentName: 'PrestationExpertiseEtatAvancementTravauxBien',
          },
        ],
        validationName: 'bienGroup',
      },
      {
        slug: 'points-inspections',
        name: `points d'inspection`,
        children: childrenPointsInspections,
        validationName: 'pointsInspectionsGroup',
      },
      {
        slug: 'commentaires',
        name: 'commentaires',
        children: [
          {
            slug: 'avis-expert',
            name: `Avis de l'expert`,
            isActive: true,
            componentName: 'PrestationExpertiseEtatAvancementTravauxAvisExpert',
          },
        ],
        validationName: 'commentairesGroup',
      },
      {
        slug: 'conclusion',
        name: 'conclusion',
        children: [
          {
            slug: 'conclusion',
            name: `conclusion`,
            isActive: true,
            componentName: 'PrestationExpertiseEtatAvancementTravauxConclusion',
          },
        ],
        validationName: 'conclusionGroup',
      },
    ];
  }

  protected touchDatas(): boolean {
    this.$v.mission.prestationExpertise.$touch();
    this.$v.mission.prestationExpertise.pointsInspectionsRelations.$touch();
    this.$v.mission.prestationExpertise.piecesJointes.$touch();

    return (
      this.$v.mission.prestationExpertise.$invalid ||
      this.$v.mission.prestationExpertise.pointsInspectionsRelations.$invalid ||
      this.$v.mission.prestationExpertise.piecesJointes.$invalid
    );
  }

  private getComponentsSectionsForHorsAir(pointsInspections: PrestationExpertisePointInspectionDto[]): ComponentSection[] {
    return [
      {
        slug: 'menuiseries-exterieures',
        name: 'Menuiseries extérieures',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.MenuiseriesExterieures),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.MenuiseriesExterieures),
        },
      },
      {
        slug: 'electricite',
        name: 'Electricité',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.Electricite),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.Electricite),
        },
      },
      {
        slug: 'plomberie',
        name: 'Plomberie',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.Plomberie),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.Plomberie),
        },
      },
      {
        slug: 'chauffage',
        name: 'Chauffage',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.Chauffage),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.Chauffage),
        },
      },
      {
        slug: 'isolation',
        name: 'Isolation',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.Isolation),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.Isolation),
        },
      },
      {
        slug: 'finitions',
        name: 'Finitions',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.Finitions),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.Finitions),
        },
      },
    ];
  }

  private getComponentsSectionsForHorsEau(pointsInspections: PrestationExpertisePointInspectionDto[]): ComponentSection[] {
    return [
      {
        slug: 'gros-oeuvre',
        name: 'Gros oeuvre',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.GrosOeuvre),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.GrosOeuvre),
        },
      },
      {
        slug: 'raccordement',
        name: 'Raccordement',
        isActive: true,
        componentName: 'PrestationExpertiseEtatAvancementTravauxPointInspection',
        properties: {
          nom: getPointInspectionCategorieEnumName(PointInspectionCategorieEnum.Raccordement),
          pointsInspections: pointsInspections.filter((x) => x.categorie === PointInspectionCategorieEnum.Raccordement),
        },
      },
    ];
  }

  public async onEmitMergeMission(merges: MergeDto[]): Promise<void> {
    if (merges.length === 1 && merges[0].fieldName === 'TypePrestationExpertise') {
      const mergeTypePrestationExpertise = merges[0];
      return await this.changeTypePrestationExpertise(mergeTypePrestationExpertise, 'PrestationExpertiseEtatAvancementTravaux');
    }

    /* A Reactiver s'il y a des champs à merger
    merges.forEach((merge: MergeDto) => {
    });

    Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.REFRESH_VALEURS_DATA);
    this._hasMissionChanged = true;
    await this.saveInStorage();*/
  }

  //#endregion
}
