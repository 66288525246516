



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import DatePicker from '@/shared/components/date-picker.vue';
import store from '../store';
@Component({
  name: 'DateVisite',
  components: {
    DatePicker,
  },
})
export default class DateVisite extends Vue {
  @Prop({ default: 'Date de la visite' }) public label: string;
  @Prop() public mission: any;
  @Prop() public validation: any;
  @Prop({ default: true }) public isWarnMandant: boolean;

  public reason: string = null;
  public reasonState: any = null;

  //#region LIFE CYCLES
  public beforeMount(): void {
    this.reason = `Bonjour,\n\nLe rendez-vous est modifié pour la raison suivante :\n\n\nCordialement,\n\n${store.user.surname} ${store.user.name}`;
  }
  //#endregion

  //#region COMPUTED

  //#endregion

  //#region EVENTS
  public async onCancelVisiteClick(event: any): Promise<void> {
    if (!this.checkFormValidity()) {
      event.preventDefault();
      return;
    }

    store.isLoading = true;
    const logMessage = `Annulation du RDV attaché à la mission: ${this.mission.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertise');

    Vue.prototype.$missionApiService
      .annulerRDV(this.mission.id, this.reason)
      .then(async () => {
        Vue.prototype.$notificationService.success(`Le RDV a bien été annulé et le mail envoyé au mandant`);
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertise');
        this.mission.dateVisite = null;
        this.reason = null;
        this.$bvModal.hide('cancelVisiteModal');
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Erreur lors de l'annulation du RDV ou l'envoi du mail au mandant`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertise');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  //#endregion

  //#region FUNCTIONS
  public checkFormValidity(): boolean {
    const isValid = (this.$refs.form as any).checkValidity();
    this.reasonState = isValid;
    return isValid;
  }
  //#endregion
}
