export function isNotNullOrEmpty(value: string | number): boolean {
  return value !== undefined && value !== null && value !== '';
}

export function isNullOrEmpty(value: string | number): boolean {
  return value === undefined || value === null || value === '' || value === 'null';
}

//Ne conserve que les lettre et les chiffres
export function toOnlyTextAndNumberLower(input: string): string {
  return input.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}
