










































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { AnnexeDto } from '@/shared/dtos/annexe.dto';
import store from '@/shared/store';
import DeviceMixin from '@/mixins/device.mixin';
@Component({
  name: 'PanelAnnexes',
})
export default class PanelAnnexes extends Mixins(DeviceMixin) {
  @Prop({ default: false }) public isVisible: boolean;
  @Prop() public annexes: AnnexeDto[];

  public fields: any = [
    'nom',
    {
      key: 'action',
      label: '',
    },
  ];

  //#region LIFE CYCLES
  public created(): void {
    document.addEventListener('keydown', this.onEscapeKeyPress);
  }

  public destroyed(): void {
    document.removeEventListener('keydown', this.onEscapeKeyPress);
  }
  //#endregion

  //#region COMPUTED
  get list(): any {
    return this.annexes;
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onEscapeKeyPress(e: any): void {
    if (e.keyCode === 27) {
      this.onCloseClick();
    }
  }

  public onCloseClick(): void {
    this.$emit('visibleChanged', false);
  }

  public async onViewAnnexeClick(id: number): Promise<void> {
    // En mobile avec l'iphone, on ne peut pas simplement voir le fichier, il faut le télécharger pour le consulter
    if (this.isMobile) {
      await this.onDownloadAnnexeClick(id);
      return;
    }

    store.isLoading = true;
    await Vue.prototype.$annexeApiService
      .getAnnexe(id)
      .then((response: any) => {
        const mimeType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: mimeType });
        var blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.error(`Erreur lors du téléchargement du document ${id} - ERROR`, error, 'Annexe');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public async onDownloadAnnexeClick(id: number): Promise<void> {
    store.isLoading = true;
    await Vue.prototype.$annexeApiService
      .getAnnexe(id)
      .then((response: any) => {
        const disposition = response.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]);

        const mimeType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: mimeType });

        const navigator: any = window.navigator;
        if (navigator && navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link); // fix for Firefox
          link.click();
          link.remove();
        }
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.error(`Erreur lors du téléchargement du document ${id} - ERROR`, error, 'Annexe');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  //#endregion

  //#region FUNCTIONS
  //#endregion
}
