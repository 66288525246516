


















import { RapportExpertiseNoteSectionDto } from '@/shared/dtos/rapport-expertise-section-note.dto';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Visa',
})
export default class Visa extends Vue {
  @Prop() public value: RapportExpertiseDto;
  @Prop() public businessEntries: any;
  @Prop() public isNoteReadOnly: boolean;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get rapport() {
    return this.value;
  }

  get canVisa() {
    return (
      this.$can('Save', 'Notes') &&
      this.rapport.notes &&
      Object.values(this.rapport.notes).every(
        (note: RapportExpertiseNoteSectionDto) =>
          note && note.isValid !== null && (note.isValid || (note.isValid === false && note.commentaire != null && note.commentaire != ''))
      )
    );
  }
  //#endregion

  //#region EVENTS
  public onApposerVisaClick(): void {
    this.$emit('onApposerVisaClick');
  }

  public onRefuserVisaClick(): void {
    this.$emit('onRefuserVisaClick');
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
