



















































import { Component, Prop, Vue } from 'vue-property-decorator';

import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';

import { Settings } from 'luxon';
Settings.defaultLocale = 'fr';

@Component({
  name: 'DatePicker',
  components: { Datepicker, Datetime },
})
export default class DatePicker extends Vue {
  @Prop() public value: string;
  @Prop({ default: 'dd/MM/yyyy', required: false }) public format: string;
  @Prop() public title: string;
  @Prop({ default: true }) public titleBold: boolean;
  @Prop({ default: true }) public state: boolean;
  @Prop({ default: false }) public hasTime: boolean;
  @Prop({ default: true }) public hasCancelButton: boolean;
  @Prop({ default: false }) public disabled: boolean;

  public fr: any;
  public dateInput: any = null;

  //#region LIFE CYCLES
  created() {
    this.fr = fr;
  }

  mounted() {
    // C'est pas ouf, mais pour régler les soucis de typeable & format qui ne fonctionnent pas ensemble
    // voir https://github.com/charliekassel/vuejs-datepicker/pull/633
    // on override les méthodes inputBlurred & parseTypedDate à la mano
    if (!this.hasTime) {
      this.dateInput = (this.$refs.datepicker as any).$children[0];
      this.dateInput.inputBlurred = this.newInputBlurred;
      this.dateInput.parseTypedDate = this.newParseTypedDate;
    }
  }

  //#endregion

  //#region COMPUTED

  get date() {
    return this.value;
  }

  set date(val: string) {
    const value = val ? moment(val).format() : null;
    this.$emit('input', value);
  }

  //#endregion

  //#region EVENTS

  public onClearDatePickerClick(): void {
    this.date = null;
  }

  public onShowCalendarClick(e: any): void {
    if (this.hasTime) {
      (this.$refs.datetimepicker as any).open(e);
    } else {
      (this.$refs.datepicker as any).showCalendar();
    }
  }

  //#endregion

  //#region FUNCTIONS

  public newParseTypedDate(event: any): void {
    // close calendar if escape or enter are pressed
    if (
      [
        27, // escape
        13, // enter
      ].includes(event.keyCode)
    ) {
      this.dateInput.input.blur();
    }

    if (this.dateInput.typeable) {
      var parseableDate = this.parseableDate(this.dateInput.input.value, this.dateInput.format);
      var parsedDate = Date.parse(parseableDate);
      if (!isNaN(parsedDate)) {
        this.dateInput.typedDate = this.dateInput.input.value;
        this.dateInput.$emit('typedDate', new Date(parsedDate));
      }
    }
  }

  public newInputBlurred(): void {
    var parseableDate = this.parseableDate(this.dateInput.input.value, this.dateInput.format);
    if (isNaN(Date.parse(parseableDate))) {
      this.dateInput.clearDate();
      this.dateInput.input.value = null;
      this.dateInput.typedDate = null;
    }
    this.dateInput.$emit('closeCalendar');
  }

  public parseableDate(datestr: any, formatstr: any): string {
    if (!(datestr && formatstr)) {
      return datestr;
    }

    // moment a besoin d'un format en majuscule (MM/YYYY)
    return moment(datestr, formatstr.toUpperCase()).format();
  }
  //#endregion
}
