import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class PrestationExpertiseVersionHistoryApiService extends BaseDtoApiService<PrestationExpertiseVersionHistoryDto> {
  constructor() {
    super('prestationexpertiseversionhistories');
  }

  public checkForUpdateAndConflictByPrestationExpertiseIds(prestationExpertiseIds: number[]): Promise<number> {
    return axios.post(`${this._endpoint}/status`, prestationExpertiseIds).then((response: any) => response.data.result);
  }
}
