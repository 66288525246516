







import { Component, Prop, Vue } from 'vue-property-decorator';
import Radios from '@/shared/components/radios.vue';
import { PointInspectionEtatEnumToBusinessEntries } from '@/shared/utilities/enums.utility';
import { PrestationExpertisePointInspectionRelationDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-point-inspection-relation.dto';
@Component({
  name: 'PointInspection',
  components: {
    Radios,
  },
})
export default class PointInspection extends Vue {
  @Prop() nom: string;
  @Prop() pointInspection: PrestationExpertisePointInspectionRelationDto;
  @Prop() state: any;

  public etats = PointInspectionEtatEnumToBusinessEntries();

  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
