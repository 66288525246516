import { EmailCommandeDto } from './../shared/dtos/email-commande.dto';
import Vue from 'vue';

export const getEmailsCommandeByMissionIdResolver = async (to: any, from: any, next: any) => {
  Vue.prototype.$emailCommandeApiService
    .getManyWithRoute(`mission/${to.params.omereMissionId}`)
    .then((emailsCommande: EmailCommandeDto[]) => {
      to.meta.emailsCommande = emailsCommande;
      next();
    });
};
