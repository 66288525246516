





























import { Component, Mixins, Vue } from 'vue-property-decorator';

import BasicLayout from './layouts/basic.layout.vue';
import HeaderTitle from '@/shared/components/header-title.vue';

import { orderBy } from 'lodash';
import moment from 'moment';
import { Log } from '@/shared/models/log';
import { SeverityLevelEnum } from '@/shared/enums/severity-level.enum';
import DeviceMixin from '@/mixins/device.mixin';
import store from '@/shared/store';

@Component({
  name: 'Diagnostic',
  components: {
    BasicLayout,
    HeaderTitle,
  },
})
export default class Diagnostic extends Mixins(DeviceMixin) {
  public fields: any[] = [];
  public filters: any = {
    date: '',
    level: '',
    message: '',
    error: '',
    info: '',
  };
  public logs: Log[] = [];
  public perPage = 20;
  public currentPage = 1;

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    this.fields = [
      {
        key: 'date',
        sortable: true,
        formatter: (date: Date) => {
          return date ? moment(date).format('DD/MM/YYY HH:mm:ss') : null;
        },
      },
      {
        key: 'level',
        sortable: true,
        formatter: (level: SeverityLevelEnum) => {
          return SeverityLevelEnum[level];
        },
      },
      {
        key: 'message',
      },
      {
        key: 'error',
      },
      {
        key: 'info',
      },
    ];

    this.logs = await Vue.prototype.$logStoreService.getAll();
    this.logs = orderBy(this.logs, ['date'], ['desc']);
  }

  //#endregion

  //#region COMPUTED
  get rows(): number {
    return this.logs.length;
  }

  get filtered(): Log[] {
    if (this.logs.length) {
      const filtered = this.logs.filter((item: any) => {
        return Object.keys(this.filters).every((key) => {
          let itemValue = item[key];

          if (key === 'level') {
            itemValue = SeverityLevelEnum[itemValue];
          }

          if (!itemValue) return true;

          return itemValue.toString().toLowerCase().includes(this.filters[key].toLowerCase());
        });
      });

      return filtered;
    } else
      return [
        {
          id: null,
          date: null,
          level: null,
          message: null,
          error: null,
          info: null,
        },
      ];
  }
  //#endregion

  //#region EVENTS
  public async onSendDiagnosticsApplicationsButtonClick(): Promise<void> {
    const logs = await Vue.prototype.$logStoreService.getAll();

    store.isLoading = true;
    Vue.prototype.$diagnosticApplicationApiService
      .addLogs(JSON.stringify(logs))
      .then(() => {
        Vue.prototype.$notificationService.success(`Envoi des diagnostics de l'application effectué avec succès.`);
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de l'envoi des diagnostics de l'application.`);
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
