











import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TableSurfaces from '@/components/rapport-expertise/table-surfaces.vue';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

@Component({
  name: 'ExpSurface',
  components: {
    TableSurfaces,
  },
})
export default class ExpSurface extends mixins(RapportExpertiseSectionMixin) {
  @Prop() public referenceDataSurfaceTypes: any;
  @Prop() public surfaceTypeIsAutre: Function;
  @Prop() public surfaces: any;
}
