import { EmailCommandeDto } from '@/shared/dtos/email-commande.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class EmailCommandeApiService extends BaseDtoApiService<EmailCommandeDto> {
  constructor() {
    super('emailscommandes');
  }

  public addCustom(
    missionId: number,
    objet: string,
    corps: string,
    isDestinataireMandantChecked: boolean,
    isDestinataireExpertChecked: boolean,
    isDestinataireControleurChecked: boolean,
    autreDestinataire: string,
    isCcMandantChecked: boolean,
    isCcExpertChecked: boolean,
    isCcControleurChecked: boolean,
    autreCc: string,
    isCciMandantChecked: boolean,
    isCciExpertChecked: boolean,
    isCciControleurChecked: boolean,
    autreCci: string
  ) {
    return axios
      .post(this._endpoint, {
        missionId,
        objet,
        corps,
        isDestinataireMandantChecked,
        isDestinataireExpertChecked,
        isDestinataireControleurChecked,
        autreDestinataire,
        isCcMandantChecked,
        isCcExpertChecked,
        isCcControleurChecked,
        autreCc,
        isCciMandantChecked,
        isCciExpertChecked,
        isCciControleurChecked,
        autreCci,
      })
      .then((response: any) => this.convertData(response));
  }
}
