






















import { Component, Mixins } from 'vue-property-decorator';

import ValeurSpotPlusSectionMixin from '@/mixins/valeur-spot-plus-section.mixin';

import NumericNullable from '@/shared/components/numeric-nullable.vue';

@Component({
  name: 'PrestationExpertiseValeurSpotPlusAnalyseDuBienCommentaires',
  components: {
    NumericNullable,
  },
})
export default class PrestationExpertiseValeurSpotPlusAnalyseDuBienCommentaires extends Mixins(ValeurSpotPlusSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
