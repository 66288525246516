import { Label } from './../shared/models/label';
import { RapportExpertiseBusinessEntryDto } from './../shared/dtos/rapport-expertise-business-entry.dto';
import { NatureBien } from '@/shared/models/nature-bien';
import biens from '../referentiels/biens.json';
export class BienService {
  constructor() {}

  public getBiens(): NatureBien[] {
    return biens;
  }

  public getTypesBiensByNatureBienId(natureBienId: number): Label[] {
    const bien: NatureBien = biens.find((x) => x.id === natureBienId);
    if (bien) {
      return bien.types;
    }

    return [];
  }

  public getCategorieActifsAsBusinessEntries(): RapportExpertiseBusinessEntryDto[] {
    return biens.map((x: NatureBien) => new RapportExpertiseBusinessEntryDto(x.id, x.title));
  }

  public getTypesBiensAsBusinessEntries(): RapportExpertiseBusinessEntryDto[] {
    const typesBien: RapportExpertiseBusinessEntryDto[] = [];

    biens.forEach((natureBien: NatureBien) => {
      typesBien.push(...natureBien.types.map((type: Label) => new RapportExpertiseBusinessEntryDto(type.id, type.title, natureBien.id)));
    });

    return typesBien;
  }

  public getTypesBiensAsBusinessEntriesByBienId(bienId: number): RapportExpertiseBusinessEntryDto[] {
    const bien: NatureBien = biens.find((x) => x.id === bienId);
    if (bien) {
      return bien.types.map((x: Label) => new RapportExpertiseBusinessEntryDto(x.id, x.title, bien.id));
    }

    return [];
  }
}
