

















import EtatAvancementSectionMixin from '@/mixins/etat-avancement-travaux-section.mixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  name: 'PrestationExpertiseEtatAvancementTravauxAvisExpert',
  components: {},
})
export default class PrestationExpertiseEtatAvancementTravauxAvisExpert extends Mixins(EtatAvancementSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
