




























































































































































































































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import ExtendableList from '@/shared/components/extendable-list.vue';
import ExtendableListString from '@/shared/components/extendable-list-string.vue';
import ElementComparaison from '@/components/element-comparaison.vue';
import { isElementComparaisonTransaction, isEvaluationMethodeRetenueComparaisonEtRendement } from '@/helpers/rapport-expertise.helper';

import SimpleChart from '@/shared/components/simple-chart.vue';
import MultipleChart from '@/shared/components/multiple-chart.vue';
import { TypeDonneesEstimationImmobiliereEnum } from '@/shared/enums/donnees-estimation-immobiliere-type.enum';
import { PriceHubbleDto } from '@/shared/dtos/price-hubble.dto';
import { EtudeMarcheElementComparaisonDto } from '@/shared/dtos/etude-marche-element-comparaison.dto';
import { DonneesEstimationImmobiliereDto } from '@/shared/dtos/donnees-estimation-immobiliere.dto';
import { SubjectMessage } from '@/shared/models/subject-message';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { orderBy } from 'lodash';

@Component({
  name: 'PrestationExpertiseRapportMarcheEtudeDeMarche',
  components: {
    VisaDisplay,
    ExtendableList,
    ExtendableListString,
    ElementComparaison,
    SimpleChart,
    MultipleChart,
  },
})
export default class PrestationExpertiseRapportMarcheEtudeDeMarche extends Mixins(RapportExpertiseSectionMixin) {
  public donneesEstimationImmobiliere: DonneesEstimationImmobiliereDto = null;

  public showPriceHubbleIframe: boolean = false;
  public dossierId: string = null;
  public linkPriceHubbleUrl: string = null;
  public charts: any = null;

  //#region LIFE CYCLES
  public beforeMount(): void {
    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.REFRESH_DONNEES_ESTIMATION_IMMOBILIERES) {
        this.donneesEstimationImmobiliere = subjectMessage.message;
        if (this.donneesEstimationImmobiliere) {
          this.initIframe();
          this.charts = this.donneesEstimationImmobiliere.charts;
        }
      }
    });

    this.orderByForEtudeMarcheElementsComparaisons();
  }

  //#endregion

  //#region COMPUTED
  get isEvaluationMethodeRetenueComparaisonEtRendement(): boolean {
    return isEvaluationMethodeRetenueComparaisonEtRendement(this.rapport.evaluationMethodeRetenueId);
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public async onShowModalClick(): Promise<void> {
    await Vue.prototype.$donneesEstimationImmobiliereApiService
      .authenticate()
      .then(async (accessToken: string) => {
        let iframe = this.$refs.priceHubbleIframe as HTMLIFrameElement;
        iframe.src = `${Vue.prototype.$config.priceHubbleIframeBaseUrl}my-dash?access_token=${accessToken}`;

        // Bug 1839 : solution de recontournement pour avoir correctement les comparables (solution proposée par PriceHubble eux-mêmes)
        setTimeout(() => {
          iframe.src = `${Vue.prototype.$config.priceHubbleIframeBaseUrl}dossier/${this.dossierId}/similar?access_token=${accessToken}`;
        }, 2000);
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.error(`Erreur lors de la connexion à PriceHubble - ERROR`, error, 'PriceHubble');
      });
  }

  public onSearchComparableClick(): void {
    Vue.prototype.$donneesEstimationImmobiliereApiService
      .getComparables(this.dossierId)
      .then((comparables: EtudeMarcheElementComparaisonDto[]) => {
        comparables.forEach((x) => (x.rapportExpertiseId = +this.rapport.id));
        const listElementsComparaisonsVente = comparables.filter((x) => x.vente);
        if (listElementsComparaisonsVente.length > 0) {
          const elementComparaisonVenteRef: ExtendableList = this.$refs.elementComparaisonVente as unknown as ExtendableList;
          elementComparaisonVenteRef.addItemsToList(listElementsComparaisonsVente);
        }

        const listElementsComparaisonsLocation = comparables.filter((x) => !x.vente);
        if (listElementsComparaisonsLocation.length > 0) {
          const elementComparaisonLocationRef: ExtendableList = this.$refs.elementComparaisonLocation as unknown as ExtendableList;
          elementComparaisonLocationRef.addItemsToList(listElementsComparaisonsLocation);
        }

        Vue.prototype.$donneesEstimationImmobiliereApiService.removeComparables(this.dossierId);

        this.orderByForEtudeMarcheElementsComparaisons();

        (this.$refs.priceHubbleModal as any).hide();
      });
  }

  //#endregion

  //#region FUNCTIONS

  private orderByForEtudeMarcheElementsComparaisons(): void {
    this.rapport.etudeMarcheElementsComparaisonsVentes = orderBy(this.rapport.etudeMarcheElementsComparaisonsVentes, (x) => x.distance);

    this.rapport.etudeMarcheElementsComparaisonsLocations = orderBy(
      this.rapport.etudeMarcheElementsComparaisonsLocations,
      (x) => x.distance
    );
  }

  public isElementComparaisonTransaction(elementComparaison: any): boolean {
    return isElementComparaisonTransaction(elementComparaison);
  }

  public getProprietairesLocatairesOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartProprietairesLocatairesOptions(this.charts.proprietairesLocataires);
  }

  public getMaisonsAppartementsOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartMaisonsAppartementsOptions(this.charts.maisonsAppartements);
  }
  public getNombrePiecesOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartNombrePiecesOptions(this.charts.nombrePieces);
  }
  public getSurfaceLogementsOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartSurfaceLogementsOptions(this.charts.surfaceLogements);
  }
  public getNombreEntreprisesOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartNombreEntreprisesOptions(this.charts.nombreEntreprises);
  }
  public getImpotFortuneOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartImpotFortuneOptions(this.charts.impotFortune);
  }

  public getParcLogementsMaisonsOptions(dataset: any): any {
    if (!this.charts) return null;
    return (window as any).getChartParcLogementsOptions(dataset, 'maisons');
  }

  public getParcLogementsAppartementsOptions(dataset: any): any {
    if (!this.charts) return null;
    return (window as any).getChartParcLogementsOptions(dataset, 'appartements');
  }

  public getCadresEmployesOptions(dataset: any): any {
    if (!this.charts) return null;
    return (window as any).getChartCadresEmployesOptions(dataset);
  }

  public getPrixLoyerOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartPrixLoyerOptions(this.charts.prixLoyer);
  }

  private initIframe(): void {
    this.showPriceHubbleIframe =
      this.donneesEstimationImmobiliere.type === TypeDonneesEstimationImmobiliereEnum.PriceHubbleV1 &&
      this.$can('RechercheComparable', 'Rapport');
    if (this.showPriceHubbleIframe) {
      this.dossierId = (this.donneesEstimationImmobiliere as PriceHubbleDto).dossierId;
      this.linkPriceHubbleUrl = Vue.prototype.$config.priceHubbleIframeBaseUrl;
    }
  }

  //#endregion
}
