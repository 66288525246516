import { Component, Mixins } from 'vue-property-decorator';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';
import PrestationExpertiseSectionMixin from '@/mixins/prestation-expertise-section.mixin';
import {
  isContratTypePrestationNotCertificatExpertise,
  isRapportVersionGreaterOrEqualsTo,
  isRapportVersionLowerOrEqualsTo,
} from '@/helpers/rapport-expertise.helper';
import { RapportExpertiseNoteSectionEnum } from '@/shared/enums/rapport-expertise-note-section.enum';
import { getRapportExpertiseNoteSectionEnumName } from '@/shared/utilities/enums.utility';

@Component({
  name: 'RapportExpertiseSectionMixin',
})
export default class RapportExpertiseSectionMixin extends Mixins(PrestationExpertiseSectionMixin) {
  public rapportExpertiseNoteSectionEnum = RapportExpertiseNoteSectionEnum;
  public getRapportExpertiseNoteSectionEnumName: any = getRapportExpertiseNoteSectionEnumName;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get rapport(): RapportExpertiseDto {
    return this.prestationExpertise as RapportExpertiseDto;
  }

  get isRapport(): boolean {
    return this.rapport != null ? isContratTypePrestationNotCertificatExpertise(this.rapport.contratTypePrestationId) : null;
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  public isRapportVersionGreaterOrEqualsTo(version: number) {
    return isRapportVersionGreaterOrEqualsTo(this.rapport, version);
  }

  public isRapportVersionLowerOrEqualsTo(version: number) {
    return isRapportVersionLowerOrEqualsTo(this.rapport, version);
  }

  //#endregion
}
