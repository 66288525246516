import { MissionDto } from '@/shared/dtos/mission.dto';
import store from '@/shared/store';
import Vue from 'vue';

export const getMissionsAControlerByOmereContactIdResolver = (to: any, from: any, next: any) => {
  Vue.prototype.$missionApiService.getAControlerByOmereContactId(store.user.omereContactId).then((missions: MissionDto[]) => {
    to.meta.missions = missions;
    next();
  });
};
