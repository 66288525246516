












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ExtendableList',
})
export default class ExtendableList extends Vue {
  @Prop() public list: any;
  @Prop() public rapportId: number;
  @Prop({ default: 'Ajouter' }) public texteAjouter: string;
  @Prop() public cssClass: string;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  public addItem(): void {
    this.list.push(this.initNewItem());
    this.$emit('input', this.list);
    this.$emit('itemAdded');
  }

  public deleteItem(index: number): void {
    this.list.splice(index, 1);
    this.$emit('input', this.list);
    this.$emit('itemDeleted');
  }

  public initNewItem(): any {
    return {
      id: 0,
      value: '',
      rapportExpertiseId: this.rapportId,
    };
  }

  public addItemsToList(items: any): void {
    for (let i = 0; i < items.length; i++) {
      this.list.push(items[i]);
    }
    this.$emit('input', this.list);
    this.$emit('itemAdded');
  }
  //#endregion
}
