































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import Radios from '@/shared/components/radios.vue';
import {
  isEvaluationMethodeRetenueComparaisonEtRendement,
  isEvaluationMethodeRetenueRendement,
  isContratConditionOccupationOccupe,
  hasValeurApresTravaux,
  hasValeurLocative,
  isRapportVersionGreaterOrEqualsTo,
} from '@/helpers/rapport-expertise.helper';
import Decimal from 'decimal.js';
import { ContratHypotheseEvaluationEnum } from '@/shared/enums/contrat-hypothese-evaluation.enum';
import { RapportExpertiseBusinessEntryDto } from '@/shared/dtos/rapport-expertise-business-entry.dto';
import { EvaluationMethodeRetenueEnum } from '@/shared/enums/evaluation-methode-retenue.enum';

@Component({
  name: 'PrestationExpertiseRapportEvaluationEvaluation',
  components: {
    VisaDisplay,
    NumericNullable,
    Radios,
  },
})
export default class PrestationExpertiseRapportEvaluationEvaluation extends Mixins(RapportExpertiseSectionMixin) {
  public evaluationTauxRendementRetenu: number = null;
  public isEvaluationMethodeRetenueDisabled: Boolean = false;

  //#region LIFE CYCLES
  public mounted(): void {
    if (this.rapport.evaluationTauxRendementRetenu)
      this.evaluationTauxRendementRetenu = new Decimal(this.rapport.evaluationTauxRendementRetenu).times(100).toNumber();
    if (!this.hasValeurApresTravaux) {
      this.rapport.evaluationSurfaceOuSuperficieApresTravaux = null;
      this.rapport.evaluationReductionMetriqueApresTravaux = null;
    }
    if (!this.hasValeurLocative) {
      this.rapport.evaluationValeurLocativeMensuelle = null;
      this.rapport.evaluationValeurLocativeChargesAnnuelles = null;
    }
  }
  //#endregion

  //#region COMPUTED
  get isEvaluationMethodeRetenueComparaisonEtRendement(): boolean {
    return isEvaluationMethodeRetenueComparaisonEtRendement(this.rapport.evaluationMethodeRetenueId);
  }

  get isEvaluationMethodeRetenueRendement(): boolean {
    return isEvaluationMethodeRetenueRendement(this.rapport.evaluationMethodeRetenueId);
  }

  get evaluationDroitEnregistrementDisplayText(): number {
    return new Decimal(this.rapport.evaluationDroitEnregistrement).times(100).toNumber();
  }

  get isContratConditionOccupationOccupe(): boolean {
    return isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId);
  }

  get hasValeurApresTravaux(): boolean {
    return hasValeurApresTravaux(this.mission);
  }

  get hasValeurLocative(): boolean {
    return hasValeurLocative(this.mission);
  }

  get titreSurfaceOuSuperficieApresTravaux(): string {
    if (this.isTypeDeBienTerrain) {
      return 'Superficie du terrain après travaux';
    } else {
      return 'Surface totale habitable après travaux';
    }
  }

  get evaluationMethodesRetenues(): RapportExpertiseBusinessEntryDto[] {
    let res = this.businessEntries.evaluationMethodeRetenue;
    this.isEvaluationMethodeRetenueDisabled = false;

    switch (this.rapport.contratHypotheseEvaluationId) {
      case ContratHypotheseEvaluationEnum.BienValoriseLibreDeTouteOccupationOuLocation:
      case ContratHypotheseEvaluationEnum.BienValoriseApresTravaux:
        this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.Comparaison;
        this.isEvaluationMethodeRetenueDisabled = true;
        res = this.businessEntries.evaluationMethodeRetenue.filter(
          (x: RapportExpertiseBusinessEntryDto) => x.id == EvaluationMethodeRetenueEnum.Comparaison
        );
        break;
      case ContratHypotheseEvaluationEnum.BienValoriseEnEtatEtCompteTenuDeSesConditionsOccupation:
      case ContratHypotheseEvaluationEnum.BienSupposeLoueALaValeurLocativeDeMarche:
        this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.ComparaisonEtRendement;
        res = this.businessEntries.evaluationMethodeRetenue.filter(
          (x: RapportExpertiseBusinessEntryDto) => x.id !== EvaluationMethodeRetenueEnum.Comparaison
        );
        break;
      case ContratHypotheseEvaluationEnum.Autre:
        this.rapport.evaluationMethodeRetenueId = EvaluationMethodeRetenueEnum.Comparaison;
        break;
      default:
        this.rapport.evaluationMethodeRetenueId = null;
    }

    return res;
  }

  //#endregion

  //#region WATCH
  @Watch('evaluationTauxRendementRetenu')
  public evaluationTauxRendementRetenuChanged(value: any): any {
    if (value) {
      this.rapport.evaluationTauxRendementRetenu = new Decimal(value).dividedBy(100).toNumber();
    } else {
      this.rapport.evaluationTauxRendementRetenu = null;
    }
  }

  @Watch('rapport.evaluationMethodeRetenueId')
  public evaluationMethodeRetenueIdChanged() {
    if (!this.isEvaluationMethodeRetenueComparaisonEtRendement) {
      this.evaluationTauxRendementRetenu = null;
      this.rapport.evaluationReductionMetriqueLocationRetenue = null;
    }
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
