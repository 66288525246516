





















import { Component, Inject, Mixins, Provide } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import VisaNote from '@/shared/components/visa-note.vue';
import Visa from '@/components/rapport-expertise/Visa.vue';

@Component({
  name: 'PrestationExpertiseRapportControleAppreciationGenerale',
  components: {
    VisaDisplay,
    VisaNote,
    Visa,
  },
})
export default class PrestationExpertiseRapportControleAppreciationGenerale extends Mixins(RapportExpertiseSectionMixin) {
  @Inject('apposerVisa') public apposerVisa: any;
  @Inject('refuserVisa') public refuserVisa: any;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onApposerVisaClick(): void {
    this.apposerVisa();
  }

  public onRefuserVisaClick(): void {
    this.refuserVisa();
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
