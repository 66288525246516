


































/* eslint-disable no-console */
import { Component, Vue } from 'vue-property-decorator';
import Spinner from 'vue-spinner-component/src/Spinner.vue';

@Component({
  name: 'AgendaModals',
  components: {
    Spinner,
  },
})
export default class AgendaModals extends Vue {}
