import axios from 'axios';
import Vue from 'vue';
import router from './../router';
import abp from 'abp-web-resources';

export class AuthentificationService {
  constructor() {}

  public logout(): void {
    axios
      .get('api/TokenAuth/logout', {
        baseURL: Vue.prototype.$config.baseUrlApi,
      })
      .then(() => {
        abp.auth.clearToken();
        abp.utils.setCookieValue('enc_auth_token', undefined, undefined, abp.appPath);
        localStorage.setItem('comeFromLoginPage', JSON.stringify(false));

        // Ces vérifications permettent d'éviter les erreurs au chargement de l'application, et les redirections en boucle
        if (router.app && window.location.pathname !== '/' && window.location.pathname !== '/compte/connexion') {
          router.app.$ability.update([]);
          location.href = '/';
        }
      });
  }
}
