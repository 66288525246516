import { IBaseDto } from '@/shared/dtos/ibase.dto';
import axios from 'axios';
import * as queryString from 'query-string';

export abstract class BaseDtoApiService<T extends IBaseDto> {
  constructor(protected readonly _endpoint: string) {}

  private getQueryString(queryParams: any): string {
    return queryParams ? `?${queryString.stringify(queryParams)}` : '';
  }

  //#region SERIALIZER
  protected fromJson(object: any): T {
    return object as T;
  }

  protected toJson(object: T): any {
    return object;
  }
  //#endregion

  //#region CONVERT
  protected convertData(response: any): T {
    return this.fromJson(response.data.result) as T;
  }

  protected convertDatas(response: any): T[] {
    return response.data.result.map((result: any) => this.fromJson(result) as T);
  }

  //#endregion

  //#region CREATE
  public add(item: T): Promise<T> {
    return axios.post(this._endpoint, this.toJson(item)).then((response: any) => this.convertData(response));
  }

  public addMany(items: T[]): Promise<T[]> {
    return axios
      .post(
        this._endpoint,
        items.map((item: T) => this.toJson(item))
      )
      .then((data: any) => this.convertDatas(data));
  }

  //#endregion

  //#region READ
  public get(id: number | string): Promise<T> {
    const url = `${this._endpoint}/${id}`;

    return axios.get<T>(url).then((response: any) => this.convertData(response));
  }

  public getWithParams(queryParams: any): Promise<T> {
    const url = `${this._endpoint}${this.getQueryString(queryParams)}`;

    return axios.get<T>(url).then((response: any) => this.convertData(response));
  }

  public getManyWithParams(queryParams: any): Promise<T[]> {
    const url = `${this._endpoint}${this.getQueryString(queryParams)}`;

    return axios.get<T[]>(url).then((response: any) => this.convertDatas(response));
  }

  public getWithRoute(route: string): Promise<T> {
    const url = `${this._endpoint}/${route}`;

    return axios.get<T>(url).then((response: any) => this.convertData(response));
  }

  public getManyWithRoute(route: string): Promise<T[]> {
    const url = `${this._endpoint}/${route}`;

    return axios.get<T[]>(url).then((response: any) => this.convertDatas(response));
  }

  public getWithRouteAndParams(route: string, queryParams: any = null): Promise<T> {
    const url = `${this._endpoint}/${route}${this.getQueryString(queryParams)}`;

    return axios.get<T>(url).then((response: any) => this.convertData(response));
  }

  public getManyWithRouteAndParams(route: string, queryParams: any = null): Promise<T[]> {
    const url = `${this._endpoint}/${route}${this.getQueryString(queryParams)}`;

    return axios.get<T[]>(url).then((response: any) => this.convertDatas(response));
  }

  public getAll(isInCache = false): Promise<T[]> {
    return axios.get<T[]>(this._endpoint).then((response: any) => this.convertDatas(response));
  }

  //#endregion

  //#region UPDATE
  public update(id: number | string, item: T): Promise<T> {
    return axios.put(`${this._endpoint}/${id}`, this.toJson(item)).then((response: any) => this.convertData(response));
  }

  //#endregion

  //#region DELETE
  public delete(id: number | string): Promise<any> {
    return axios.delete(`${this._endpoint}/${id}`);
  }

  //#endregion

  public addOrUpdate(item: T): Promise<T> {
    return item.id > 0 ? this.update(item.id, item) : this.add(item);
  }
}
