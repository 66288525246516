import { orderBy } from 'lodash';

export class JourFerieService {
  constructor() {}

  public getAll(): Promise<any> {
    return fetch('https://calendrier.api.gouv.fr/jours-feries/metropole.json')
      .then((response) => {
        return response.json();
      })
      .then((json: any) => {
        return orderBy(Object.keys(json), (x) => x);
      });
  }
}
