










import { Component, Mixins } from 'vue-property-decorator';

import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaNote from '@/shared/components/visa-note.vue';

@Component({
  name: 'PrestationExpertiseRapportConclusionControle',
  components: {
    VisaNote,
  },
})
export default class PrestationExpertiseRapportConclusionControle extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
