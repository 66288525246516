import { RechercheLocalisationMissionDto } from './recherche-localisation-mission.dto';

export class RechercheMissionDto {
  public numeroMission: string;
  public emprunteur: string;
  public natureBienId: number;
  public typeBienId: number;
  public duree: number;
  public localisation: RechercheLocalisationMissionDto;
  public valeurMin: number;
  public valeurMax: number;
  public surfaceHabitableMin: number;
  public surfaceHabitableMax: number;
  public surfaceTerrainMin: number;
  public surfaceTerrainMax: number;

  constructor() {
    this.natureBienId = 0;
    this.typeBienId = 0;
    this.duree = 12;
  }
}
