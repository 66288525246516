

































































import { Component, Vue } from 'vue-property-decorator';

import BasicLayout from './layouts/basic.layout.vue';
import axios from 'axios';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import { required } from 'vuelidate/lib/validators';
import abp from 'abp-web-resources';
import { contratTypePrestationColorDefault } from '@/helpers/rapport-expertise.helper';
import Captcha from '@/components/captcha.vue';
import store from '@/shared/store';

@Component({
  name: 'Login',
  components: {
    BasicLayout,
    Spinner,
    Captcha,
  },
  validations: {
    login: {
      userName: {
        required,
      },
      password: {
        required,
      },
      captchaResponse: {
        required,
      },
    },
  },
})
export default class Login extends Vue {
  login = {
    userName: '',
    password: '',
    captchaResponse: '',
  };

  contratTypePrestationColorDefault = contratTypePrestationColorDefault;
  showPassword = false;
  isCaptchaEnabled = true;

  //#region LIFE CYCLES
  created() {
    if (!Vue.prototype.$isProduction || !Vue.prototype.$config.isCaptchaEnabled) {
      this.isCaptchaEnabled = false;
      this.login.captchaResponse = '04709b78-c0b7-41c6-a3c0-2023e8377ba2-disabled';
    }
  }

  //#endregion

  //#region EVENTS

  public onSubmit(event: any) {
    event.preventDefault();
    if (this.$v.login.$invalid) {
      this.$v.login.$touch();
      return;
    }

    this.$bvModal.show('modal-spinner');
    axios
      .post('api/TokenAuth/Authenticate', this.login, {
        baseURL: Vue.prototype.$config.baseUrlApi,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((rep) => {
        this.$bvModal.hide('modal-spinner');

        var tokenExpireDate = new Date(new Date().getTime() + 1000 * rep.data.result.expireInSeconds);
        abp.auth.setToken(rep.data.result.accessToken, tokenExpireDate);
        abp.utils.setCookieValue('enc_auth_token', rep.data.result.encryptedAccessToken, tokenExpireDate, abp.appPath);

        localStorage.setItem('permissions', JSON.stringify(rep.data.result.permissions));
        localStorage.setItem('comeFromLoginPage', JSON.stringify(true));

        this.$router.push({ path: '/' });
        this.$router.go(0);
      })
      .catch(() => {
        this.$bvModal.hide('modal-spinner');
        const refCaptcha: any = this.$refs.captchaComponent;
        if (refCaptcha) {
          refCaptcha.reset();
        }
      });
  }

  onCaptchaClick(captchaResponse: any) {
    this.login.captchaResponse = captchaResponse;
  }
  onPasswordToggleClick() {
    this.showPassword = !this.showPassword;
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
