









































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Dropdowns',
})
export default class Dropdowns extends Vue {
  @Prop() public value: any;
  @Prop() public list: any;
  @Prop() public rapportId: number;
  @Prop() public title: string;
  @Prop({ default: true }) public titleBold: boolean;
  @Prop({ default: true }) public state: boolean;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get qualificationSelecteds() {
    let result: any = [];
    this.list.forEach((businessEntryQualifiable: any, index: number) => {
      var businessEntrySelected = this.value.find(function (element: any) {
        return element.businessEntrySelectedId == businessEntryQualifiable.businessEntry.id;
      });

      if (businessEntrySelected !== undefined) {
        result[index] = businessEntryQualifiable.businessEntryQualifications.find(function (qualification: any) {
          return qualification.id == businessEntrySelected.businessEntryQualificationId;
        });
      }
    });
    return result;
  }

  //#endregion

  //#region EVENTS

  public onAddItemClick(item: any, businessEntryQualification: any): void {
    if (this.value.map((v: any) => v.businessEntrySelectedId).includes(item.businessEntry.id)) {
      var businessEntrySelected = this.value.find(function (element: any) {
        return element.businessEntrySelectedId == item.businessEntry.id;
      });

      businessEntrySelected.businessEntryQualificationId = businessEntryQualification.id;
      this.$emit('updateListQualificationSelected');
    } else {
      this.value.push({
        id: 0,
        businessEntrySelectedId: item.businessEntry.id,
        businessEntryQualificationId: businessEntryQualification.id,
        rapportExpertiseId: this.rapportId,
      });
    }
  }

  public onDeleteItemClick(item: any) {
    if (this.value.length > 0) {
      if (this.value.map((v: any) => v.businessEntrySelectedId).includes(item.businessEntry.id)) {
        var index = this.value.findIndex((v: any) => v.businessEntrySelectedId == item.businessEntry.id);
        this.value.splice(index, 1);
      }
    }
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
