import { EtudeDeMarcheApiService } from './services/apis/etude-de-marche-api.service';
import { JourFerieService } from './services/jours-feries.services';
import ToggleButton from 'vue-js-toggle-button';
/* eslint-disable no-console */
/* eslint-disable no-alert */
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import router from './router';
import './http';
import VueMoment from 'vue-moment';
import moment from 'moment';
import { abilitiesPlugin } from '@casl/vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from './App.vue';
require('moment/locale/fr');

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faThermometerHalf,
  faWind,
  faFire,
  faTrash,
  faPlus,
  faTimes,
  faSquare,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faPencilAlt,
  faCheck,
  faBan,
  faHeart,
  faPaperPlane,
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faEye,
  faMapMarkerAlt,
  faArrowLeft,
  faCommentAlt,
  faCaretRight,
  faCar,
  faWalking,
  faChartPie,
  faExclamation,
  faSyncAlt,
  faStoreAlt,
  faSearchPlus,
  faHome,
  faList,
  faSignOutAlt,
  faEyeSlash,
  faEllipsisH,
  faBars,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faClone,
  faChevronLeft,
  faSave,
  faFileDownload,
  faArrowsAltH,
  faCog,
  faEnvelope,
  faPaperclip,
  faDownload,
  faBolt,
  faLeaf,
  faUpload,
  faThumbsUp,
  faThumbsDown,
  faCamera,
  faFolderOpen,
  faInfo,
  faThumbtack,
  faChevronDown,
  faChevronUp,
  faMagic,
  faRocket,
  faExclamationTriangle,
  faImage,
  faTint,
  faBuilding,
  faColumns,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCalendar, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import './styles/styles.scss';
import './registerServiceWorker';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueKonva from 'vue-konva';
import { MissionApiService } from './services/apis/mission-api.service';
import { SimpleMissionApiService } from './services/apis/simple-mission-api.service';
import { PrestationExpertiseApiService } from '@/services/apis/prestation-expertise-api.service';
import { RapportExpertiseApiService } from '@/services/apis/rapport-expertise-api.service';
import { PieceJointeRapportApiService } from './services/apis/piece-jointe-rapport-api.service';
import { ValeurSpotPlusApiService } from './services/apis/valeur-spot-plus-api.service';
import { PrestationExpertisePieceJointeApiService } from './services/apis/prestation-expertise-piece-jointe-api.service';
import { DonneesEstimationImmobiliereApiService } from '@/services/apis/donnees-estimation-immobiliere-api.service';
import { ExpertiseApiService } from '@/services/apis/expertise-api.service';
import { EmailCommandeApiService } from './services/apis/email-commande-api.service';
import { ExternalAuthenticationService } from './services/external-authentication.service';
import { ModeleEmailApiService } from './services/apis/modele-email-api.service';
import { LoadingProgressionService } from './services/loading-progression.service';
import { BienService } from './services/bien.service';
import { SynchronizationService } from './services/synchronization.service';
import { NotificationService } from './services/notification.service';
import { SubjectMessageService } from './services/subject-message.service';
import { LocalStorageService } from './services/local-storage.service';
import { IDBPDatabase, openDB } from 'idb';
import { MissionStoreService } from './services/store/mission-store.service.ts';
import { LogStoreService } from './services/store/log-store.service';
import { MissionForListApiService } from './services/apis/mission-for-list-api.service';
import { AnnexeApiService } from './services/apis/annexe-api.service';
import { DiagnosticApplicationApiService } from './services/apis/diagnostic-application-api.service';
import { PrestationExpertiseProprietesApiService } from './services/apis/prestation-expertise-proprietes-api.service';
import { PrestationExpertiseVersionHistoryApiService } from './services/apis/prestation-expertise-version-history-api.service';
import { AuthentificationService } from './services/authentification.service';

import store from './shared/store';
import loadGoogleMapsApi from 'load-google-maps-api';
import { PermissionsService } from './services/apis/permission.service';
import { ReferentielApiService } from './services/apis/referentiel-api.service';

dom.watch();

Vue.config.productionTip = false;

Vue.use(Vuelidate);

moment.updateLocale('fr', {
  calendar: {
    lastDay: '[hier à] LTS',
    sameDay: '[aujd. à] LTS',
    nextDay: '[demain à] LTS',
    lastWeek: 'ddd [der. à] LTS',
    nextWeek: 'ddd [à] LTS',
    sameElse: 'L [à] LTS',
  },
});

Vue.use(VueMoment, { moment });

Vue.use(abilitiesPlugin);
library.add(
  faThermometerHalf,
  faBuilding,
  faWind,
  faColumns,
  faTint,
  faFire,
  faLeaf,
  faBolt,
  faTrash,
  faPlus,
  faTimes,
  faSquare,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faPencilAlt,
  faCheck,
  faBan,
  faCalendarAlt,
  faHeart,
  faCircle,
  faPaperPlane,
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faMapMarkerAlt,
  faArrowLeft,
  faCommentAlt,
  faCaretRight,
  faCar,
  faWalking,
  faChartPie,
  faExclamation,
  faSyncAlt,
  faStoreAlt,
  faSearchPlus,
  faHome,
  faList,
  faSignOutAlt,
  faEllipsisH,
  faCalendar,
  faBars,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faClone,
  faChevronLeft,
  faSave,
  faFileDownload,
  faArrowsAltH,
  faCog,
  faEnvelope,
  faPaperclip,
  faDownload,
  faBolt,
  faLeaf,
  faUpload,
  faThumbsUp,
  faThumbsDown,
  faCamera,
  faFolderOpen,
  faList,
  faInfo,
  faThumbtack,
  faChevronDown,
  faChevronUp,
  faMagic,
  faRocket,
  faExclamationTriangle,
  faImage
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueKonva);
Vue.use(ToggleButton);

Vue.filter('currency', (value: any) => {
  return value != null
    ? value.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    : null;
});

// Services
Vue.prototype.$localStorageService = new LocalStorageService();
createObjectsStoresAndServices();

// APIs Services
Vue.prototype.$missionApiService = new MissionApiService();
Vue.prototype.$simpleMissionApiService = new SimpleMissionApiService();
Vue.prototype.$prestationExpertiseApiService = new PrestationExpertiseApiService();
Vue.prototype.$missionForListApiService = new MissionForListApiService();
Vue.prototype.$rapportExpertiseApiService = new RapportExpertiseApiService();
Vue.prototype.$pieceJointeRapportApiService = new PieceJointeRapportApiService();
Vue.prototype.$valeurSpotPlusApiService = new ValeurSpotPlusApiService();
Vue.prototype.$prestationExpertisePieceJointeApiService = new PrestationExpertisePieceJointeApiService();
Vue.prototype.$donneesEstimationImmobiliereApiService = new DonneesEstimationImmobiliereApiService();
Vue.prototype.$expertiseApiService = new ExpertiseApiService();
Vue.prototype.$emailCommandeApiService = new EmailCommandeApiService();
Vue.prototype.$externalAuthenticationService = new ExternalAuthenticationService();
Vue.prototype.$modeleEmailApiService = new ModeleEmailApiService();
Vue.prototype.$loadingProgressionService = new LoadingProgressionService();
Vue.prototype.$bienService = new BienService();
Vue.prototype.$annexeApiService = new AnnexeApiService();
Vue.prototype.$subjectMessageService = new SubjectMessageService();
Vue.prototype.$synchronizationService = new SynchronizationService();
Vue.prototype.$notificationService = new NotificationService();
Vue.prototype.$diagnosticApplicationApiService = new DiagnosticApplicationApiService();
Vue.prototype.$prestationExpertiseProprietesApiService = new PrestationExpertiseProprietesApiService();
Vue.prototype.$prestationExpertiseVersionHistoryApiService = new PrestationExpertiseVersionHistoryApiService();
Vue.prototype.$jourFiereService = new JourFerieService();
Vue.prototype.$etudeDeMarcheApiService = new EtudeDeMarcheApiService();
Vue.prototype.$authentificationService = new AuthentificationService();
Vue.prototype.$permissionService = new PermissionsService();
Vue.prototype.$referentielService = new ReferentielApiService();

// Error handling
Vue.config.errorHandler = async function (error: Error, vm: any, info: any) {
  console.error(error, vm, info);

  Vue.prototype.$notificationService.error(`Une erreur est survenue.`);

  store.isLoading = false;

  await Vue.prototype.$logStoreService.error(error?.message, error, info.toString());
};

window.onerror = async function (message: any, source: string, ligne: number, colone: number, error: Error) {
  console.error(message, source, ligne, colone, error);

  await Vue.prototype.$logStoreService.error(message.toString(), error, `Source: ${source} - Ligne: ${ligne} - Colonne: ${colone}}`);
};

// Déclare le bus d'événements pour la communication entre component
export const EventBus = new Vue();

// Initialisation
let configFile = 'app-config.json';
if (process.env.NODE_ENV !== 'production') {
  configFile = 'app-config.local.json';
}
fetch(getDocumentOrigin() + '/' + configFile).then((resp: any) => {
  resp.json().then((config: any) => {
    Vue.prototype.$config = config;
    Vue.prototype.$isProduction = process.env.NODE_ENV === 'production';
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = Vue.prototype.$config.baseUrlApiAppService;

    axios
      .get(config.baseUrlApiAppService + 'services/app/Session/GetCurrentLoginInformations')
      .then((response: any) => {
        if (response.data.result) {
          store.user = response.data.result.user;

          if (store.user) {
            var lastLoggedUserId = localStorage.getItem('userId');

            if (lastLoggedUserId && +lastLoggedUserId !== store.user.id) {
              Vue.prototype.$missionStoreService.clear();
              Vue.prototype.$localStorageService.setLastPullDate(null);
            }

            localStorage.setItem('userId', store.user.id.toString());
          }
        }

        new Vue({
          router,
          render: (h: any) => h(App),
        }).$mount('#app');
      })
      .catch((error) => {
        // En cas d'erreur on veut quand même lancer l'application pour permettre l'accès à la page d'accueil
        new Vue({
          router,
          render: (h: any) => h(App),
        }).$mount('#app');
      });

    loadGoogleMapsApi({
      key: Vue.prototype.$config.googleMapApiKey,
      libraries: ['places', 'geometry'],
    }).then(
      (googleMapApi: any) => {
        Vue.prototype.$googleMapsApi = googleMapApi;
      },
      async (error: any) => {
        Vue.prototype.$notificationService.error(`Erreur au chargement de la carte Google Maps.`);
        await Vue.prototype.$logStoreService.error(`Erreur au chargement de la carte Google Maps.`, error, 'Google Map Api');
      }
    );
  });
});

function getDocumentOrigin() {
  if (!document.location.origin) {
    const port = document.location.port ? ':' + document.location.port : '';
    return document.location.protocol + '//' + document.location.hostname + port;
  }

  return document.location.origin;
}

async function createObjectsStoresAndServices() {
  let db = await openDB('immoprod', 1, {
    upgrade(db: IDBPDatabase, oldVersion: number, newVersion: number) {
      console.log(`Database Versions : Old: ${oldVersion} - New : ${newVersion} `);

      if (!db.objectStoreNames.contains('missions')) {
        const store = db.createObjectStore('missions', { autoIncrement: true, keyPath: 'key' });
        store.createIndex('id', 'id', { unique: true });
      }

      switch (newVersion) {
        case 1: {
          const store = db.createObjectStore('logs', { autoIncrement: true, keyPath: 'key' });
          store.createIndex('id', 'id', { unique: true });
          break;
        }
        default:
          break;
      }

      db.onversionchange = (event) => {
        db.close();
      };
    },
    blocked() {
      alert(`La base de donnée locale n'est plus à jour. Veuillez recharger l'application`);
    },
    blocking() {
      alert(`La base de donnée locale n'est plus à jour. Veuillez recharger l'application`);
    },
    terminated() {
      alert(`La connexion à la base de données locale s'est interrompue pour une raison inconue. Veuillez recharger l'application`);
    },
  });

  Vue.prototype.$missionStoreService = new MissionStoreService(db);
  Vue.prototype.$logStoreService = new LogStoreService(db);
}
