






import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  name: 'HeaderTitle',
})
export default class HeaderTitle extends Vue {
  @Prop() public title: string;
}
