import { BaseStoreService } from './base-store.service';
import { IDBPDatabase } from 'idb';
import { MissionDto } from '@/shared/dtos/mission.dto';
import moment from 'moment';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';

export class MissionStoreService extends BaseStoreService<MissionDto> {
  constructor(db: IDBPDatabase) {
    super('missions', 'id', db);
  }

  public async getToPush(): Promise<MissionDto[]> {
    const storedMissions = await this.getAll();

    const missionsToPush = storedMissions.filter(
      (missionToPush: MissionDto) =>
        missionToPush.prestationExpertise &&
        missionToPush.prestationExpertise.dateModificationUtc &&
        missionToPush.prestationExpertise.dateModificationUtc.toString() !== '0001-01-01T00:00:00+00:00' &&
        (!missionToPush.prestationExpertise.dateDeSauvegardeUtc ||
          moment(missionToPush.prestationExpertise.dateModificationUtc).valueOf() >
            moment(missionToPush.prestationExpertise.dateDeSauvegardeUtc).valueOf()) &&
        missionToPush.etape === EtapeMissionEnum.AttribueeALExpert
    );

    return missionsToPush;
  }
}
