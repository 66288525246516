



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  name: 'HtmlTextarea',
})
export default class PointInspection extends Vue {
  @Prop() public value: string;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH
  @Watch('value')
  public hasValueChanged(newValue: string): void {
    if (document.activeElement == this.$el) {
      return;
    }

    this.$el.innerHTML = newValue;
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
