
















import DeviceMixin from '@/mixins/device.mixin';
import { MergeDto } from '@/shared/dtos/merge.dto';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { SubjectMessage } from '@/shared/models/subject-message';
import { getNatureVisiteEnumName, getTypePrestationExpertiseEnumName } from '@/shared/utilities/enums.utility';
import { Component, Mixins, Vue } from 'vue-property-decorator';
@Component({
  name: 'PrestationExpertiseMissionMerge',
  components: {},
})
export default class PrestationExpertiseMissionMerge extends Mixins(DeviceMixin) {
  public items: MergeDto[] = [];

  public fields: any[] = [
    {
      key: 'label',
      label: 'Champ',
    },
    {
      key: 'oldValue',
      label: 'Ancienne valeur',
      formatter: (value: any, key: any, item: MergeDto) => {
        if (value === null || value === undefined) return '';

        switch (item.fieldName) {
          case 'TypePrestationExpertise':
            return getTypePrestationExpertiseEnumName(value);
          case 'NatureVisite':
            return getNatureVisiteEnumName(value);
        }

        if (value === true) return 'Oui';
        if (value === false) return 'Non';

        return value;
      },
    },
    {
      key: 'newValue',
      label: 'Nouvelle valeur',
      formatter: (value: any, key: any, item: MergeDto) => {
        if (value === null || value === undefined) return '';

        switch (item.fieldName) {
          case 'TypePrestationExpertise':
            return getTypePrestationExpertiseEnumName(value);
          case 'NatureVisite':
            return getNatureVisiteEnumName(value);
        }

        if (value === true) return 'Oui';
        if (value === false) return 'Non';

        return value;
      },
    },
  ];
  //#region LIFE CYCLES
  public beforeMount(): void {
    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.SHOW_MERGE_MISSION_MODAL) {
        this.items = subjectMessage.message;
        this.$bvModal.show('mission-merge-modal');
      }
    });
  }
  //#endregion

  //#region COMPUTED
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onHandleAcceptMerge(): void {
    this.$emit('mergeAccepted', this.items);
    this.$bvModal.hide('mission-merge-modal');
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
