import { EtatAvancementTravauxDto } from '@/shared/dtos/etat-avancement-travaux.dto';
import PrestationExpertiseSectionMixin from '@/mixins/prestation-expertise-section.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { RapportExpertiseNoteSectionEnum } from '@/shared/enums/rapport-expertise-note-section.enum';
import { getRapportExpertiseNoteSectionEnumName } from '@/shared/utilities/enums.utility';
@Component({
  name: 'EtatAvancementTravauxSectionMixin',
})
export default class EtatAvancementTravauxSectionMixin extends Mixins(PrestationExpertiseSectionMixin) {
  public rapportExpertiseNoteSectionEnum = RapportExpertiseNoteSectionEnum;
  public getRapportExpertiseNoteSectionEnumName = getRapportExpertiseNoteSectionEnumName;

  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  get etatAvancementTravaux(): EtatAvancementTravauxDto {
    return this.prestationExpertise as EtatAvancementTravauxDto;
  }

  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
