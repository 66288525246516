







































































import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import DatePicker from '@/shared/components/date-picker.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import VisaDisplay from '@/shared/components/visa-display.vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
@Component({
  name: 'PrestationExpertiseRapportSituationLocativeLocative',
  components: {
    VisaDisplay,
    DatePicker,
    ChoicePopup,
    NumericNullable,
  },
})
export default class PrestationExpertiseRapportSituationLocativeLocative extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region WATCH
  @Watch('rapport.locativeLoyerMensuelBrut')
  public locativeLoyerMensuelBrutChanged(val: number): void {
    if (val) {
      this.rapport.locativeLoyerAnnuelBrut = val * 12;
    } else {
      this.rapport.locativeLoyerAnnuelBrut = null;
    }
  }

  @Watch('rapport.locativeLoyerAnnuelBrut')
  public locativeLoyerAnnuelBrutChanged(val: number): void {
    if (val) {
      this.rapport.locativeLoyerMensuelBrut = val / 12;
      if (this.rapport.locativeChargesNonRecuperables) {
        this.rapport.locativeLoyerAnnuelNet = val - this.rapport.locativeChargesNonRecuperables;
      }
    } else {
      this.rapport.locativeLoyerMensuelBrut = null;
    }
  }

  @Watch('rapport.locativeChargesNonRecuperables')
  public locativeChargesNonRecuperablesChanged(val: number): void {
    if (val) {
      this.rapport.locativeLoyerAnnuelNet = this.rapport.locativeLoyerAnnuelBrut - val;
    } else {
      this.rapport.locativeLoyerAnnuelNet = null;
    }
  }
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
