




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import store from '@/shared/store';

@Component({
  name: 'PrestationExpertiseVersionHistories',
  components: {},
})
export default class PrestationExpertiseVersionHistories extends Vue {
  @Prop() prestationExpertiseId: string;

  public prestationExpertiseVersionHistories: PrestationExpertiseVersionHistoryDto[] = [];
  public synchronizationLogsTableFields: any = [
    {
      key: 'lastPushVersion',
      label: 'lastPushVersion',
    },
    {
      key: 'phonePullVersion',
      label: 'phonePullVersion',
    },
    {
      key: 'phoneDateModificationUtc',
      label: 'phoneDateModificationUtc',
    },
    {
      key: 'desktopPullVersion',
      label: 'desktopPullVersion',
    },
    {
      key: 'desktopPushVersion',
      label: 'desktopPushVersion',
    },
    {
      key: 'desktopDateModificationUtc',
      label: 'desktopDateModificationUtc',
    },
  ];

  //#region LIFE CYCLES

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onShowSynchronizationLogsModal(): void {
    store.isLoading = true;

    Vue.prototype.$prestationExpertiseVersionHistoryApiService
      .getManyWithRoute(this.prestationExpertiseId)
      .then((prestationExpertiseVersionHistories: PrestationExpertiseVersionHistoryDto[]) => {
        this.prestationExpertiseVersionHistories = prestationExpertiseVersionHistories;

        store.isLoading = false;
      });
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
