import abp from 'abp-web-resources';
import Login from './views/login.vue';
import AgendaMobile from './views/agenda/agenda-mobile.vue';
import AgendaDesktop from './views/agenda/agenda-desktop.vue';
import Diagnostic from './views/diagnostic.vue';
import Missions from './views/missions.vue';
import Recherche from './views/recherche.vue';
import MissionsAControler from './views/missions-a-controler.vue';
import PrestationExpertiseRapport from './views/prestation-expertise/rapport/prestation-expertise-rapport.vue';
import PrestationExpertiseValeurSpotPlus from './views/prestation-expertise/valeur-spot-plus/prestation-expertise-valeur-spot-plus.vue';
import PrestationExpertiseEtatAvancementTravaux from './views/prestation-expertise/etat-avancement-travaux/prestation-expertise-etat-avancement-travaux.vue';
import PrestationExpertiseEtudeDeMarche from './views/prestation-expertise/etude-de-marche/prestation-expertise-etude-de-marche.vue';
import PrestationExpertiseContact from './views/prestation-expertise/prestation-expertise-contact.vue';
import Router from 'vue-router';
import Vue from 'vue';
import { getMissionByIdResolver } from './resolvers/get-mission-by-id.resolver';
import { getMissionsByOmereContactIdResolver } from './resolvers/get-missions-by-omere-contact-id.resolver';
import { getMissionsAControlerByOmereContactIdResolver } from './resolvers/get-missions-a-controler-by-omere-contact-id.resolver';
import store from '@/shared/store';
import { getEmailsCommandeByMissionIdResolver } from './resolvers/get-emails-commande-by-mission-id.resolver';
import { isBrowser } from 'mobile-device-detect';
import { MissionDto } from './shared/dtos/mission.dto';

Vue.use(Router);

let loading = false;
async function redirectToPrestationExpertiseResolver(to: any, from: any, next: any): Promise<any> {
  if (loading) return;

  loading = true;

  await Vue.prototype.$externalAuthenticationService
    .loginInToOmereAndRedirectToMission(
      to.query.token,
      to.query.missionId ?? to.query.missionid,
      to.query.username,
      to.query.nonce,
      to.query.timestamp
    )
    .then(() => {
      return Vue.prototype.$missionApiService.getTypePrestationExpertiseByMissionId(to.query.missionId);
    })
    .then((typePrestationExpertise: string) => {
      next({
        path: '/missions/' + to.query.missionId + '/' + typePrestationExpertise,
        replace: true,
      });
    })
    .catch(() => {});
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/agenda',
    },
    {
      path: '/diagnostic',
      component: Diagnostic,
      meta: {
        title: 'Diagnostic',
      },
    },
    {
      path: '/compte/connexion',
      component: Login,
      meta: {
        title: 'Connexion',
      },
      beforeEnter: async (to, from, next) => {
        if (abp.auth.getToken()) {
          next({
            path: '/',
            replace: true,
          });

          return;
        }

        next();
      },
    },
    {
      path: '/agenda',
      component: isBrowser ? AgendaDesktop : AgendaMobile,
      beforeEnter: async (to, from, next) => {
        if (!router.app.$ability.can('Agenda', 'Pages')) {
          store.isLoading = false;
          next({
            path: '/missions',
            replace: true,
          });
        }
        next();
      },

      meta: {
        title: 'Agenda',
      },
    },
    {
      path: '/recherche',
      component: Recherche,
      meta: {
        title: 'Recherche',
      },
    },
    {
      path: '/missions-a-controller',
      component: MissionsAControler,
      beforeEnter: async (to, from, next) => {
        if (!router.app.$ability.can('MissionsAControler', 'Pages')) {
          store.isLoading = false;
          return;
        }
        getMissionsAControlerByOmereContactIdResolver(to, from, next);
      },
      meta: {
        title: 'Liste des missions à contrôler',
      },
    },
    {
      path: '/missions',
      component: Missions,
      beforeEnter: getMissionsByOmereContactIdResolver,
      meta: {
        title: 'Liste des missions',
      },
    },
    {
      path: '/account/loginomere/mission',
      beforeEnter: redirectToPrestationExpertiseResolver,
      meta: {
        title: 'Connexion depuis Omère',
      },
    },
    {
      path: '/missions/:omereMissionId/RapportExpertise',
      beforeEnter: getMissionByIdResolver,
      component: PrestationExpertiseRapport,
      meta: {
        title: "Rapport d'expertise",
      },
    },
    {
      path: '/missions/:omereMissionId/CertificatExpertise',
      beforeEnter: getMissionByIdResolver,
      component: PrestationExpertiseRapport,
      meta: {
        title: "Certificat d'expertise",
      },
    },
    {
      path: '/missions/:omereMissionId/ValeurSpotPlus',
      beforeEnter: getMissionByIdResolver,
      component: PrestationExpertiseValeurSpotPlus,
      meta: {
        title: 'Valeur Spot +',
      },
    },
    {
      path: '/missions/:omereMissionId/EtatAvancementTravaux',
      beforeEnter: getMissionByIdResolver,
      component: PrestationExpertiseEtatAvancementTravaux,
      meta: {
        title: "Etat d'Avancement de Travaux",
      },
    },
    {
      path: '/missions/:omereMissionId/EtudeDeMarche',
      beforeEnter: getMissionByIdResolver,
      component: PrestationExpertiseEtudeDeMarche,
      meta: {
        title: 'Etude de marché',
      },
    },
    {
      path: '/missions/:omereMissionId/contact',
      component: PrestationExpertiseContact,
      beforeEnter: async (to, from, next) => {
        const mission: MissionDto = await Vue.prototype.$missionStoreService.getByIndex('id', +to.params.omereMissionId);
        if (router.app.$ability.can('EmailCommande', 'Pages') || mission.expert.id === store.user.omereContactId) {
          getEmailsCommandeByMissionIdResolver(to, from, next);
        } else {
          store.isLoading = false;
        }
      },
      meta: {
        title: 'Contact',
      },
    },
  ],
});

// obligé de le faire ici parce que quand on ouvre une nouvelle page directement dans l'URL, on n'arrive pas dans le beforeEach de App.vue (déclaré trop tard)
router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - BPCE Expertises immobilières' || 'BPCE Expertises immobilières';
  store.isLoading = true;

  Vue.prototype.$localStorageService.pushBackUrlHistory(from, to);

  // l'utilisateur est connecté
  if (abp.auth.getToken()) {
    const permissions: any = localStorage.getItem('permissions');
    router.app.$ability.update(JSON.parse(permissions));
    next();
  } else {
    if (to.fullPath == '/compte/connexion' || to.fullPath.toLowerCase().indexOf('/account/loginomere/mission') != -1) {
      next();
    } else {
      next({ path: '/compte/connexion' });
    }
  }
});

router.afterEach((to, from) => {
  store.isLoading = false;
});

export default router;
