










import { Component, Mixins } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';
import PrestationExpertiseRapportAvisExpert from '@/shared/components/prestation-expertise/prestation-expertise-rapport-avis-expert.vue';

@Component({
  name: 'PrestationExpertiseRapportDescriptionGeneraleAvisExpert',
  components: {
    PrestationExpertiseRapportAvisExpert,
  },
})
export default class PrestationExpertiseRapportDescriptionGeneraleAvisExpert extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
