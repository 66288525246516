import { isEqual } from 'lodash';
import moment from 'moment';

export function getOnlyDate(dateString: string | Date | number) {
  const dateParsed = new Date(dateString);

  return new Date(dateParsed.getFullYear(), dateParsed.getMonth(), dateParsed.getDate(), 0, 0, 0, 0);
}

export function getJoursOuvres(date1: Date, date2: Date, joursFeries: string[]) {
  // Le calcul doit commencer le lendemain
  const startDate = getOnlyDate(date1);
  const endDate = getOnlyDate(date2);

  if (isEqual(startDate, endDate)) return 0;

  let count = 1;
  const currDate = startDate;
  currDate.setDate(startDate.getDate() + 1);

  while (currDate < endDate) {
    const dayOfWeek = currDate.getDay();

    // 0 = sunday, 6 = saturday
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      const currDateFormatted = moment(currDate).format('YYYY-MM-DD');

      if (!joursFeries.includes(currDateFormatted)) {
        count++;
      }
    }

    currDate.setDate(currDate.getDate() + 1);
  }

  return count;
}
