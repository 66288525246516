export class PropertyDossier {
  buildingYear: number;
  livingArea: number;
  landArea?: number; // Optional property (nullable in C#)
  floorNumber: number;
  numberOfWindows: number;
  heatingType: string;
  hotWaterGenerationType: string;
  numberOfExternalFacingWalls: number;
  isolationRenovationPeriod: string;
  glazingType: string;
  airflowType: string;

  constructor() {
    this.buildingYear = 0;
    this.livingArea = 0;
    this.landArea = undefined;
    this.floorNumber = 0;
    this.numberOfWindows = 0;
    this.heatingType = '';
    this.hotWaterGenerationType = '';
    this.numberOfExternalFacingWalls = 0;
    this.isolationRenovationPeriod = '';
    this.glazingType = '';
    this.airflowType = '';
  }
}
