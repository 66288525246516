export enum TypePieceJointeRapportEnum {
  SectionTerrain = 1,
  SectionGrosOeuvre = 2,
  SectionSurface = 3,
  PlanCadastre = 4,
  Cuisine = 5,
  SalleDeBain = 6,
  ComparableVente = 7,
  ComparableLocation = 8,
}
