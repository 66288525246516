

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { RapportExpertiseBusinessEntryRelationDto } from '../dtos/rapport-expertise-business-entry-relation.dto';
import { RapportExpertiseBusinessEntryDto } from '../dtos/rapport-expertise-business-entry.dto';
import { AnnexeStatutEnum } from '../enums/annexe-statut.enum';

@Component({
  name: 'TableRadios',
  components: {},
})
export default class TableRadios extends Vue {
  @Prop() public value: RapportExpertiseBusinessEntryRelationDto[];
  @Prop() public list: RapportExpertiseBusinessEntryDto[];
  @Prop() public listQualifiable: RapportExpertiseBusinessEntryDto[];
  @Prop() public title: String;
  @Prop() public rapportId: number;
  @Prop({ default: true }) public state: boolean;
  @Prop() public isDisabled: boolean;
  @Prop() public valuesToDisabled: number[];

  //#region LIFE CYCLES
  public beforeMount(): void {
    if (this.isDisabled) return;

    if (this.list.length > 0 && this.value.length === 0) {
      var beNonDemande = this.listQualifiable.find((x) => x.id === AnnexeStatutEnum.NonDemande);

      this.list.forEach((item) => {
        this.value.push({
          rapportExpertiseId: this.rapportId,
          businessEntrySelectedId: item.id,
          businessEntryQualificationId: beNonDemande ? AnnexeStatutEnum.NonDemande : null,
        });
      });
    }
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region COMPUTED

  public variantItem(itemSelectedId: any, itemSelectedQualificationId: any): string {
    var selectedItem = this.value.find((x) => x.businessEntrySelectedId === itemSelectedId);
    if (selectedItem) {
      return selectedItem.businessEntryQualificationId === itemSelectedQualificationId ? 'primary' : 'light';
    }
  }

  //#endregion

  //#region EVENTS

  public onToggleClick(itemSelectedId: any, itemSelectedQualificationId: any): void {
    var selectedItem = this.value.find((x) => x.businessEntrySelectedId === itemSelectedId);
    if (selectedItem) {
      selectedItem.businessEntryQualificationId = itemSelectedQualificationId;
    }
  }
  //#endregion
  //#region FUNCTIONS

  public isItemDisabled(id: number): boolean {
    return this.isDisabled || this.valuesToDisabled?.includes(id);
  }
  //#endregion

  //#endregion
}
