import { Component, Mixins } from 'vue-property-decorator';
import PrestationExpertiseSectionMixin from '@/mixins/prestation-expertise-section.mixin';
import { RapportExpertiseNoteSectionEnum } from '@/shared/enums/rapport-expertise-note-section.enum';
import { getRapportExpertiseNoteSectionEnumName } from '@/shared/utilities/enums.utility';
import { ValeurSpotPlusDto } from '@/shared/dtos/valeur-spot-plus.dto';

@Component({
  name: 'ValeurSpotPlusSectionMixin',
})
export default class ValeurSpotPlusSectionMixin extends Mixins(PrestationExpertiseSectionMixin) {
  public rapportExpertiseNoteSectionEnum = RapportExpertiseNoteSectionEnum;
  public getRapportExpertiseNoteSectionEnumName = getRapportExpertiseNoteSectionEnumName;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get valeurSpotPlus(): ValeurSpotPlusDto {
    return this.prestationExpertise as ValeurSpotPlusDto;
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
