






import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  name: 'ValidationStatus',
})
export default class ValidationStatus extends Vue {
  @Prop({ default: true }) public condition: boolean;

  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
