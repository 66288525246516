import Vue from 'vue';
import Swal from 'sweetalert2';

export class NotificationService {
  constructor() {}

  private addToast(variant: string, message: string, title: string = undefined): void {
    const vm = new Vue();

    vm.$bvToast.toast(message, {
      title: title,
      variant: variant,
      noCloseButton: true,
      solid: true,
    });
  }

  public default(message: string, title: string = undefined): void {
    this.addToast('default', message, title);
  }

  public primary(message: string, title: string = undefined): void {
    this.addToast('primary', message, title);
  }

  public secondary(message: string, title: string = undefined): void {
    this.addToast('secondary', message, title);
  }

  public info(message: string, title: string = undefined): void {
    this.addToast('info', message, title);
  }

  public success(message: string, title: string = undefined): void {
    this.addToast('success', message, title);
  }

  public warn(message: string, title: string = undefined): void {
    this.addToast('warning', message, title);
  }

  public error(message: string, title: string = undefined, showToaster = false): void {
    if (showToaster) {
      Swal.fire({
        title: title,
        text: message,
        icon: 'error',
      });
    } else {
      this.addToast('danger', message, title);
    }
  }
}
