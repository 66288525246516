
























































import EtudeDeMarcheSectionMixin from '@/mixins/etude-de-marche-section.mixin';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import { Vue, Component, Inject, Mixins } from 'vue-property-decorator';
import store from '@/shared/store';
import { PrestationExpertiseAnnexeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-annexe.dto';

@Component({
  name: 'PrestationExpertiseEtudeDeMarcheRapport',
  components: {},
})
export default class PrestationExpertiseEtudeDeMarcheRapport extends Mixins(EtudeDeMarcheSectionMixin) {
  @Inject('confirmDelete') public confirmDelete: any;

  public fields: any = [
    {
      key: 'nom',
      label: 'Nom du document',
    },

    {
      key: 'view',
      label: '',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  get list(): any {
    return this.etudeDeMarche.annexes.filter((x) => !x.isDeleted);
  }
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  public addFileClick(): void {
    (this.$refs.fileUpload as any).click();
  }

  public handleFileUpload(e: any): void {
    this.processFileUpload(e.target.files);
  }

  public async onViewFileClick(pieceJointe: PrestationExpertisePieceJointeDto): Promise<void> {
    // En mobile avec l'iphone, on ne peut pas simplement voir le fichier, il faut le télécharger pour le consulter
    if (this.isMobile) {
      await this.onDownloadFileClick(pieceJointe);
      return;
    }

    if (pieceJointe.isNew) {
      return;
    }

    store.isLoading = true;
    await Vue.prototype.$annexeApiService
      .getAnnexe(pieceJointe.id)
      .then((response: any) => {
        const mimeType = response.headers['content-type'];
        this.openFile(response.data, mimeType);
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.error(`Erreur lors du téléchargement du document ${pieceJointe.nom} - ERROR`, error, 'Annexe');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public async onDownloadFileClick(pieceJointe: PrestationExpertisePieceJointeDto): Promise<void> {
    if (pieceJointe.isNew) {
      return;
    }

    store.isLoading = true;
    await Vue.prototype.$annexeApiService
      .getAnnexe(pieceJointe.id)
      .then((response: any) => {
        const disposition = response.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]);
        const mimeType = response.headers['content-type'];

        this.downloadFile(response.data, mimeType, filename);
      })
      .catch(async (error: any) => {
        await Vue.prototype.$logStoreService.error(`Erreur lors du téléchargement du document ${pieceJointe.nom} - ERROR`, error, 'Annexe');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public onDeleteFileClick(pieceJointe: PrestationExpertisePieceJointeDto): void {
    this.confirmDelete(`cette pièce jointe ${pieceJointe.nom} `).then(() => {
      pieceJointe.isDeleted = true;
    });
  }
  //#endregion
  //#region FUNCTIONS

  private async processFileUpload(files: File[]): Promise<void> {
    for (let file of files) {
      var buffer = await file.arrayBuffer();
      var base64Content = Buffer.from(buffer).toString('base64');

      const annexe: PrestationExpertiseAnnexeDto = {
        id: 0,
        nom: file.name,
        content: base64Content,
        isNew: true,
        isDeleted: false,
      };

      this.etudeDeMarche.annexes.push(annexe);
    }
  }

  private openFile(data: any, mimeType: string): void {
    const blob = new Blob([data], { type: mimeType });
    var blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }

  private downloadFile(data: any, mimeType: string, filename: string): void {
    const blob = new Blob([data], { type: mimeType });
    const navigator: any = window.navigator;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link); // fix for Firefox
      link.click();
      link.remove();
    }
  }
  //#endregion
}
