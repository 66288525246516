

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { RapportExpertiseNoteSectionDto } from '../dtos/rapport-expertise-section-note.dto';

@Component({
  name: 'VisaNote',
})
export default class VisaNote extends Vue {
  @Prop({ required: true }) public section: string;
  @Prop({ required: true }) public visa: RapportExpertiseNoteSectionDto;
  @Prop({ required: true }) public isReadonly: boolean;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  public get isValid(): boolean | null {
    return this.visa.isValid;
  }
  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
