import { DiagnosticApplicationDto } from '@/shared/dtos/diagnostic-application.dto';
import axios from 'axios';
import Vue from 'vue';
import { BaseDtoApiService } from './base-dto-api.service';

export class DiagnosticApplicationApiService extends BaseDtoApiService<DiagnosticApplicationDto> {
  constructor() {
    super('diagnosticsapplications');
  }

  public addLogs(logs: string): Promise<void> {
    return axios.post(this._endpoint, { appClientVersion: Vue.prototype.$config.appVersion, content: logs });
  }
}
