















































import { Component, Prop, Vue } from 'vue-property-decorator';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
import { RapportProprieteDto } from '@/shared/dtos/rapport-propriete.dto';
import PrestationExpertiseVersionHistories from '@/shared/components/prestation-expertise/prestation-expertise-version-histories.vue';

@Component({
  name: 'PrestationExpertiseRapportProprietes',
  components: {
    RadioBoolean,
    PrestationExpertiseVersionHistories,
  },
})
export default class PrestationExpertiseRapportProprietes extends Vue {
  @Prop() reference: string;
  @Prop() proprietes: RapportProprieteDto;
  @Prop() prestationExpertiseId: string;

  public referenceToCopy: string = '';
  public referenceToCopyState: boolean = null;

  //#region LIFE CYCLES

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onShowReferenceToCopyModal(): void {
    this.referenceToCopy = '';
  }

  public onHandleOk(event: Event) {
    // Prevent modal from closing
    event.preventDefault();
    // Trigger submit handler
    this.onHandleSubmit();
  }

  public onHandleSubmit(): void {
    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }

    this.$emit('onReferenceToCopyChanged', this.referenceToCopy);

    // Hide the modal manually
    this.$nextTick(() => {
      this.$bvModal.hide('reference-to-copy-modal');
    });
  }
  //#endregion

  //#region FUNCTIONS
  /* eslint-disable no-useless-escape */
  private checkFormValidity(): boolean {
    const valid =
      (this.$refs.form as any).checkValidity() &&
      (/^\d{1,10}\/\d{1}$/.test(this.referenceToCopy) || /^\d{1,10}[.]{1}[A-Za-z]{1}$/.test(this.referenceToCopy)) &&
      this.referenceToCopy !== this.reference;

    this.referenceToCopyState = valid;
    return valid;
  }

  //#endregion
}
