











import EtatAvancementSectionMixin from '@/mixins/etat-avancement-travaux-section.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import PrestationExpertiseFileUploader from '@/shared/components/prestation-expertise/prestation-expertise-file-uploader.vue';
@Component({
  name: 'PrestationExpertiseEtatAvancementTravauxBien',
  components: {
    PrestationExpertiseFileUploader,
  },
})
export default class PrestationExpertiseEtatAvancementTravauxBien extends Mixins(EtatAvancementSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
