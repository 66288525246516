





















import { EstimationDpeInput } from '@/shared/dtos/estimation-dpe.dto';
import { PriceHubbleDto } from '@/shared/dtos/price-hubble.dto';
import { Component, Inject, Mixins, Prop, Vue } from 'vue-property-decorator';
import { DossierPriceHubble } from '@/shared/models/dossier/dossier-pricehubble.model';
import { EstimationDpeResponse } from '@/shared/dtos/estimation-dpe-response.dto';
import { Renovation, RenovationScenarioDetailed, ScenarioRenovationDto } from '@/shared/dtos/scenario-renovation.dto';
import ApercuRenovation from './rapport-expertise-apercu-renovation.vue';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { toOnlyTextAndNumberLower } from '@/shared/utilities/string.utility';
import { EventBus } from '@/main';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';

@Component({
  components: {
    ApercuRenovation,
  },
})
export default class ScenarioRenovation extends Vue {
  @Prop() public rapport: RapportExpertiseDto;
  @Prop() public mission: MissionDto;

  public caracteristiqueEnergetiqueReferentiel: Record<string, string> = {};
  public dossier: DossierPriceHubble = new DossierPriceHubble();
  public isNoScenarioResponseByPriceHubble: boolean = false;

  //#region LIFE CYCLES

  public async mounted() {
    await this.loadDossier();
    await this.loadReferentiel();
    if (this.rapport.caracteristiquesDPEId != null) this.GetScenarioRenovation();
    EventBus.$on('GetScenarioRenovationEmit', (value: number | null) => {
      if (value == null || value == 0) this.mission.scenarioRenovation = null;
      else if (this.rapport.caracteristiquesDPEId != null) this.GetScenarioRenovation();
    });
  }

  beforeDestroy() {
    // Nettoyer l'écouteur d'événements pour éviter les fuites de mémoire
    EventBus.$off('GetScenarioRenovationEmit');
  }
  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH
  //#endregion
  //#region EVENTS

  //#endregion
  //#region FUNCTIONS

  private async loadDossier() {
    await Vue.prototype.$donneesEstimationImmobiliereApiService
      .getDossierByRapportId(this.rapport.id)
      .then(async (dossier: DossierPriceHubble) => {
        this.dossier = dossier;
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération du dossier.`);
        await Vue.prototype.$logStoreService.error(`Dossier - ERROR`, error, 'Dossier');
      });
  }

  private async loadReferentiel() {
    await Vue.prototype.$referentielService.getReferentielDpe().then((refs: Record<string, string>[]) => {
      this.caracteristiqueEnergetiqueReferentiel = refs.reduce((acc, current) => {
        return { ...acc, ...current };
      }, {} as Record<string, string>);
    });
  }

  public async GetScenarioRenovation() {
    await Vue.prototype.$donneesEstimationImmobiliereApiService
      .getScenarioEstimation(this.dossier.id)
      .then(async (scenarioRenovation: ScenarioRenovationDto) => {
        if (scenarioRenovation.renovationScenarios != null && scenarioRenovation.renovationScenarios.length > 0) {
          scenarioRenovation.renovationScenarios.forEach((x) => (x.toExport = x.salePriceDifference.value > 0));
          this.mission.scenarioRenovation = scenarioRenovation;
          this.mission.scenarioRenovation.renovationScenarios.forEach((rs) => {
            if (rs.renovations != null)
              rs.renovations.forEach((r) => {
                this.mapRenovationProperties(r);
              });
          });

          //On trie les scenarios en affichant ceux ayant la meilleure différence de prix #moulaga
          if (this.mission.scenarioRenovation != null)
            this.mission.scenarioRenovation.renovationScenarios.sort((a, b) => a.salePriceDifference.value - b.salePriceDifference.value);
        } else {
          this.isNoScenarioResponseByPriceHubble = true;
        }
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(
          `Une erreur est survenue lors de l'estimation du DPE. Assurez vous que l'adresse, le type du bien ainsi que le titre de l'annonce soit correctement complétés.`
        );
        await Vue.prototype.$logStoreService.error(`Estimation - ERROR`, error, 'Estimation');
      });
  }

  private async mapRenovationProperties(r: Renovation) {
    const normalizedType = toOnlyTextAndNumberLower(r.type);

    switch (normalizedType) {
      case 'floornumber':
        r.dossierValue = this.dossier.property.floorNumber.toString();
        break;
      case 'numberofwindows':
        r.dossierValue = this.dossier.property.numberOfWindows.toString();
        break;
      case 'heatingtype':
        r.dossierValue = this.dossier.property.heatingType.toString();
        break;
      case 'hotwatergenerationtype':
        r.dossierValue = this.dossier.property.hotWaterGenerationType;
        break;
      case 'numberofexternalfacingwalls':
        r.dossierValue = this.dossier.property.numberOfExternalFacingWalls.toString();
        break;
      case 'isolationrenovationperiod':
        r.dossierValue = this.dossier.property.isolationRenovationPeriod;
        break;
      case 'glazingtype':
        r.dossierValue = this.dossier.property.glazingType;
        break;
      case 'airflowtype':
        r.dossierValue = this.dossier.property.airflowType;
        break;
      case 'heatinggenerationtype':
        r.dossierValue = this.dossier.property.heatingType;
        break;
      default:
        await Vue.prototype.$logStoreService.error(`Scenario Renovation - ERROR - ${r.type} non référencé lors du mapping`);
        break;
    }
  }

  //#endregion
}
