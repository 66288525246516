import { TypeValeurEnum } from './../enums/type-valeur.enum';
import { NatureVisiteEnum } from './../enums/nature-visite.enum';
import { RapportExpertiseBusinessEntryDto } from './../dtos/rapport-expertise-business-entry.dto';
import { EtatPrestationExpertisePointInspectionEnum } from '../enums/etat-prestation-expertise-point-inspection.enum';
import { NatureBienEnum } from '../enums/nature-bien.enum';
import { RapportExpertiseNoteSectionEnum } from '../enums/rapport-expertise-note-section.enum';
import { StatutCommandeEnum } from '../enums/statut-commande.enum';
import { TypePrestationExpertiseEnum } from '../enums/type-prestation-expertise.enum';
import { PointInspectionCategorieEnum } from '../enums/point-inspection-categorie.enum';

function typePrestationExpertiseEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: number]: string } = {
    [TypePrestationExpertiseEnum.CertificatExpertise]: "Certificat d'expertise",
    [TypePrestationExpertiseEnum.EtatAvancementTravaux]: "Etat d'avancement de travaux",
    [TypePrestationExpertiseEnum.ExpertiseImmobiliereCFE]: 'Expertise immobilière CFE',
    [TypePrestationExpertiseEnum.ExpertiseCKV]: 'Expertise CKV',
    [TypePrestationExpertiseEnum.RapportExpertise]: "Rapport d'expertise",
    [TypePrestationExpertiseEnum.ReceptionLot]: "Réception d'un lot",
    [TypePrestationExpertiseEnum.ValeurSpot]: 'Valeur spot',
    [TypePrestationExpertiseEnum.ValeurSpotPlus]: 'Valeur spot +',
    [TypePrestationExpertiseEnum.VisiteComplementaire]: 'Visite complémentaire',
    [TypePrestationExpertiseEnum.EtudeMarche]: 'Etude de marché',
  };

  return enumDictionary;
}

export function getTypePrestationExpertiseEnumName(value: TypePrestationExpertiseEnum): string {
  return typePrestationExpertiseEnumToDictionary()[value] ?? '';
}

function statutCommandeEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: number]: string } = {
    [StatutCommandeEnum.Abandonnee]: 'Abandonnée',
    [StatutCommandeEnum.EnCours]: 'En cours',
    [StatutCommandeEnum.Terminee]: 'Terminée',
  };

  return enumDictionary;
}

export function getStatutCommandeEnumName(value: StatutCommandeEnum): string {
  return statutCommandeEnumToDictionary()[value] ?? '';
}

function natureBienEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: number]: string } = {
    [NatureBienEnum.HabitatCollectif]: 'Habitat individuel',
    [NatureBienEnum.HabitatCollectif]: 'Habitat collectif',
    [NatureBienEnum.HabitatException]: "Habitat d'exception",
    [NatureBienEnum.Terrain]: 'Terrain',
    [NatureBienEnum.TerresCultives]: 'Terres cultivées',
    [NatureBienEnum.ImmeubleRapport]: 'Immeuble de rapport',
    [NatureBienEnum.ImmeubleProfessionnel]: 'Immeuble professionnel',
    [NatureBienEnum.ResidenceDeServicesTourisme]: 'Résidence de services / tourisme',
  };

  return enumDictionary;
}

export function getNatureBienEnumName(value: NatureBienEnum): string {
  return natureBienEnumToDictionary()[value] ?? '';
}

function rapportExpertiseNoteSectionEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: number]: string } = {
    [RapportExpertiseNoteSectionEnum.SituationGeographique]: 'SituationGeographique',
    [RapportExpertiseNoteSectionEnum.DescriptionGenerale]: 'DescriptionGenerale',
    [RapportExpertiseNoteSectionEnum.DescriptionParticuliere]: 'DescriptionParticuliere',
    [RapportExpertiseNoteSectionEnum.SituationFiscale]: 'SituationFiscale',
    [RapportExpertiseNoteSectionEnum.SituationJuridique]: 'SituationJuridique',
    [RapportExpertiseNoteSectionEnum.SituationUrbanistique]: 'SituationUrbanistique',
    [RapportExpertiseNoteSectionEnum.EtudeDeMarche]: 'EtudeDeMarche',
    [RapportExpertiseNoteSectionEnum.Evaluation]: 'Evaluation',
    [RapportExpertiseNoteSectionEnum.Conclusion]: 'Conclusion',
    [RapportExpertiseNoteSectionEnum.Global]: 'Global',
    [RapportExpertiseNoteSectionEnum.SituationLocative]: 'SituationLocative',
  };

  return enumDictionary;
}

export function getRapportExpertiseNoteSectionEnumName(value: RapportExpertiseNoteSectionEnum): string {
  return rapportExpertiseNoteSectionEnumToDictionary()[value] ?? '';
}

export function pointInspectionEtatEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: string]: string } = {
    [EtatPrestationExpertisePointInspectionEnum.NonEffectue]: 'Non effectué',
    [EtatPrestationExpertisePointInspectionEnum.EnCours]: 'En cours',
    [EtatPrestationExpertisePointInspectionEnum.Termine]: 'Terminé',
  };
  return enumDictionary;
}

export function getPointInspectionEtatEnumName(value: EtatPrestationExpertisePointInspectionEnum): string {
  return pointInspectionEtatEnumToDictionary()[value] ?? '';
}

export function PointInspectionEtatEnumToBusinessEntries(): RapportExpertiseBusinessEntryDto[] {
  const enumValues = EtatPrestationExpertisePointInspectionEnum;
  return Object.keys(enumValues)
    .filter((value) => !isNaN(+value))
    .map((item: any) => {
      return new RapportExpertiseBusinessEntryDto(Number.parseInt(item), getPointInspectionEtatEnumName(item));
    });
}

export function PointInspectionCategorieEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: string]: string } = {
    [PointInspectionCategorieEnum.MenuiseriesExterieures]: 'Menuiseries extérieures',
    [PointInspectionCategorieEnum.Electricite]: 'Electricité',
    [PointInspectionCategorieEnum.Plomberie]: 'Plomberie',
    [PointInspectionCategorieEnum.Chauffage]: 'Chauffage',
    [PointInspectionCategorieEnum.Isolation]: 'Isolation',
    [PointInspectionCategorieEnum.Finitions]: 'Finitions',
    [PointInspectionCategorieEnum.GrosOeuvre]: 'Gros oeuvre',
    [PointInspectionCategorieEnum.Raccordement]: 'Raccordement',
  };
  return enumDictionary;
}

export function getPointInspectionCategorieEnumName(value: PointInspectionCategorieEnum): string {
  return PointInspectionCategorieEnumToDictionary()[value] ?? '';
}

function natureVisiteEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: number]: string } = {
    [NatureVisiteEnum.Complete]: 'Complète',
    [NatureVisiteEnum.Exterieure]: 'Extérieure',
    [NatureVisiteEnum.SurPieces]: 'Sur pièces',
    [NatureVisiteEnum.Partielle]: 'Partielle',
  };

  return enumDictionary;
}

export function getNatureVisiteEnumName(value: NatureVisiteEnum): string {
  return natureVisiteEnumToDictionary()[value] ?? '';
}

function typeValeurEnumToDictionary(): { [index: number]: string } {
  const enumDictionary: { [index: number]: string } = {
    [TypeValeurEnum.ValeurApresTravaux]: 'Valeur après travaux',
    [TypeValeurEnum.ValeurLocative]: 'Valeur locative',
    [TypeValeurEnum.ValeurVenale]: 'Valeur vénale',
    [TypeValeurEnum.ValeurVenteForcee]: 'Valeur en vente forcée',
    [TypeValeurEnum.ValeurVenteRapide]: 'Valeur en vente rapide',
  };

  return enumDictionary;
}

export function getTypeValeurEnumToDictionary(value: TypeValeurEnum): string {
  return typeValeurEnumToDictionary()[value] ?? '';
}
