import Vue from 'vue';

export const SortAriaLabelDirective = {
  bind: (el: any, binding: any, vnode: any) => {
    Vue.nextTick(() => {
      const parentElement = vnode.elm.parentElement;
      setAriaLabel(parentElement, binding.value);

      parentElement.addEventListener('click', function (event: any) {
        event.preventDefault();
        setAriaLabel(parentElement, binding.value);
      });

      parentElement.addEventListener('ontouchstart', function (event: any) {
        event.preventDefault();
        setAriaLabel(parentElement, binding.value);
      });

      parentElement.addEventListener('focusout', function (event: any) {
        event.preventDefault();
        setAriaLabel(parentElement, binding.value);
      });

      parentElement.addEventListener('keydown', function (event: any) {
        if (event.key == 'Enter') {
          event.preventDefault();
          setAriaLabel(parentElement, binding.value);
        }
      });

      function setAriaLabel(element: any, value: any) {
        const sort = element.ariaSort;

        if (sort == null) {
          element.tabIndex = -1;
          element.ariaHidden = 'true';

          return;
        }
        if (sort == 'none' || sort == 'descending') {
          element.ariaLabel = 'Colonne ' + value + ' - Cliquez pour trier par ordre ascendant';
        } else {
          element.ariaLabel = 'Colonne ' + value + ' - Cliquez pour trier par ordre descendant';
        }
      }
    });
  },
};
