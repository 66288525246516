import store from '@/shared/store';

export class LoadingProgressionService {
  private _loadingProgressChunks?: number = null;

  public start(chunks?: number): void {
    store.isLoading = true;
    store.loadingProgression = 0;
    this._loadingProgressChunks = chunks;
  }

  public stop(): void {
    store.isLoading = false;
    store.loadingProgression = null;
  }

  public progress(): void {
    store.loadingProgression += Math.trunc(100 / this._loadingProgressChunks);
  }
}
