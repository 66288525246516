/* eslint-disable no-console */

import { BaseStoreService } from './base-store.service';
import { IDBPDatabase } from 'idb';
import { Log } from '@/shared/models/log';
import { SeverityLevelEnum } from '@/shared/enums/severity-level.enum';
import { Guid } from 'guid-typescript';
import moment from 'moment';

export class LogStoreService extends BaseStoreService<Log> {
  constructor(db: IDBPDatabase) {
    super('logs', 'id', db);

    this.deleteLowerEntriesByPropertyAndValue('date', moment().subtract(3, 'days'), true);
  }

  private async addLog(level: SeverityLevelEnum, message: any, error: any = null, info: string = null): Promise<void> {
    if (error && typeof error === 'object') {
      error = {
        name: error.name,
        message: error.message,
        stack: error.stack,
      };
    }

    const log = {
      id: Guid.create().toString(),
      date: new Date(),
      level,
      message,
      error: error,
      info,
    } as Log;

    await this.put(log);

    switch (level) {
      case SeverityLevelEnum.DEBUG:
      case SeverityLevelEnum.INFORMATION:
        console.log(log.message);
        break;
      case SeverityLevelEnum.WARNING:
        console.warn(log.message);
        break;
      case SeverityLevelEnum.ERROR:
      case SeverityLevelEnum.CRITICAL:
        console.error(log.message, log.error);
        break;
      default:
        break;
    }
  }

  public async debug(message: any, info: string = null): Promise<void> {
    await this.addLog(SeverityLevelEnum.DEBUG, message, null, info);
  }

  public async info(message: any, info: string = null): Promise<void> {
    await this.addLog(SeverityLevelEnum.INFORMATION, message, null, info);
  }

  public async warn(message: any, info: string = null): Promise<void> {
    await this.addLog(SeverityLevelEnum.INFORMATION, message, null, info);
  }

  public async error(message: any, error: any = null, info: string = null): Promise<void> {
    await this.addLog(SeverityLevelEnum.ERROR, message, error, info);
  }

  public async critical(message: any, error: any = null, info: string = null): Promise<void> {
    await this.addLog(SeverityLevelEnum.CRITICAL, message, error, info);
  }
}
