











































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BasicLayout from '@/views/layouts/basic.layout.vue';
import HeaderTitle from '@/shared/components/header-title.vue';
import CardMission from '@/components/card-mission.vue';
import moment from 'moment';
import { VueHammer } from 'vue2-hammer';
import { MissionsPerDay } from '@/shared/models/missions-per-day';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import { HistoryStatusEnum } from '@/shared/enums/conflit-statut.enum';
import Agenda from './agenda';
import AgendaModals from '@/shared/components/agenda-modals.vue';
import PrestationExpertisePush from '@/components/prestation-expertise/prestation-expertise-push.vue';
Vue.use(VueHammer);

@Component({
  name: 'AgendaMobile',
  components: {
    BasicLayout,
    HeaderTitle,
    CardMission,
    AgendaModals,
    PrestationExpertisePush,
  },
})
export default class AgendaMobile extends Agenda {
  public today: moment.Moment;
  public currentDate: moment.Moment;
  public missionsPerDay: MissionsPerDay[] = [];
  public selectedMissionsPerDay: MissionsPerDay = null;

  //#region LIFE CYCLES
  public mounted(): void {
    this.checkForUpdateAndConflict();
  }

  public created(): void {
    this.today = moment();
    this.currentDate = moment();
    window.addEventListener('scroll', this.onScroll);
    this.initDays();
  }

  //#endregion

  //#region WATCH
  @Watch('missionsPlanifiees')
  public missionsPlanifieesChanged(): void {
    this.initDays();
  }

  //#endregion

  //#region EVENTS
  public async onPullButtonClick(): Promise<void> {
    await this.getDonneesTechniques();
    await this.getDonneesAgenda();
  }

  public onPushMissionsButtonClick(): void {
    this.$bvModal.show('modal-push-missions');
  }

  public onModalPushMissionsClose(): void {
    this.$bvModal.hide('modal-push-missions');
  }

  public onLastWeekClick(swipe = false): void {
    this.currentDate = moment(this.currentDate).add(-7, 'days');
    this.initDays(swipe);
  }

  public onNextWeekClick(swipe = false): void {
    this.currentDate = moment(this.currentDate).add(7, 'days');
    this.initDays(swipe);
  }

  public onDayClick(currentMissionsPerDay: MissionsPerDay) {
    const currentIndex = this.missionsPerDay.map((x) => x.day.format('DD/MM/YYYY')).indexOf(this.currentDate.format('DD/MM/YYYY'));
    Vue.delete(this.missionsPerDay[currentIndex], 'active');

    this.currentDate = currentMissionsPerDay.day;
    this.selectedMissionsPerDay = currentMissionsPerDay;
    const nextIndex = this.missionsPerDay.indexOf(currentMissionsPerDay);

    Vue.set(this.missionsPerDay[nextIndex], 'active', true);
  }

  public onScroll(event: Event): void {
    event.preventDefault();

    const tabPlanifieElement: Element = document.querySelector('.agenda-mobile-component-tab-planifie');
    if (!tabPlanifieElement) {
      return;
    }

    const isActive: boolean = tabPlanifieElement.parentElement.classList.contains('active');
    if (!isActive) return;

    const scrollingElement: Element = document.querySelector('.agenda-mobile-component-calendar');
    const isScrolling: boolean = scrollingElement.classList.contains('scrolling');

    if (document.scrollingElement.scrollTop < 200) {
      if (isScrolling) scrollingElement.classList.remove('scrolling');
    } else {
      if (!isScrolling) scrollingElement.classList.add('scrolling');
    }
  }

  public onSwipe(event: any): void {
    event.preventDefault();
    const currentIndex = this.missionsPerDay.indexOf(this.selectedMissionsPerDay);

    //gauche
    if (event.direction === 4) {
      let lastIndex = 4;
      if (currentIndex === 0) {
        this.onLastWeekClick(true);
      } else {
        lastIndex = currentIndex - 1;
      }

      const lastMission = this.missionsPerDay[lastIndex];
      this.onDayClick(lastMission);

      return;
    }

    //droite
    if (event.direction === 2) {
      let nextIndex = 0;
      if (currentIndex === 4) {
        this.onNextWeekClick(true);
      } else {
        nextIndex = currentIndex + 1;
      }

      const nextMission = this.missionsPerDay[nextIndex];
      this.onDayClick(nextMission);
      return;
    }
  }

  //#endregion

  //#region FUNCTIONS
  private initDays(swipe = false): void {
    var weekStart = this.currentDate.startOf('week');
    this.missionsPerDay = [];
    for (let i = 0; i < 5; i++) {
      const day = moment(weekStart).add(i, 'days');

      if (this.missionsPlanifiees && this.missionsPlanifiees.length) {
        this.missionsPerDay.push({
          day: day,
          missions: this.missionsPlanifiees.filter((mission: MissionDto) => day.isSame(moment(mission.dateVisite), 'days')),
        });
      }
    }

    if (!swipe) {
      if (this.missionsPerDay.filter((x) => x.day.isSame(this.today, 'date')).length > 0) {
        this.currentDate = moment();
      } else {
        this.currentDate = weekStart;
      }

      this.selectedMissionsPerDay = this.missionsPerDay.find((x) => x.day.isSame(this.currentDate, 'days'));
    }
  }

  private async checkForUpdateAndConflict(): Promise<void> {
    const storedMissions: MissionDto[] = await Vue.prototype.$missionStoreService.getAll();

    const prestationExpertiseIds: string[] = storedMissions
      .filter((mission: MissionDto) => mission.prestationExpertise)
      .map((mission: MissionDto) => mission.prestationExpertise.id.toString());

    await Vue.prototype.$prestationExpertiseVersionHistoryApiService
      .checkForUpdateAndConflictByPrestationExpertiseIds(prestationExpertiseIds)
      .then(async (prestationExpertiseVersionHistories: PrestationExpertiseVersionHistoryDto[]) => {
        const nb = prestationExpertiseVersionHistories.filter(
          (prestationExpertiseVersionHistory: PrestationExpertiseVersionHistoryDto) =>
            prestationExpertiseVersionHistory.historyStatus !== HistoryStatusEnum.UP_TO_DATE
        ).length;

        if (nb) {
          await this.$bvModal.msgBoxOk(`${nb} mission(s) plus récente(s), pensez à récupérer les dernières données`, {
            title: 'Des mises à jour disponibles !',
          });
        }
      });
  }

  //#endregion
}
