


















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  name: 'RadioBoolean',
})
export default class RadioBoolean extends Vue {
  @Prop() public value: boolean;
  @Prop({ default: 'Oui' }) public stringTrue: string;
  @Prop({ default: 'Non' }) public stringFalse: string;

  @Prop() public title: string;
  @Prop() public titleBold: { type: boolean; default: true };
  @Prop() public state: { type: boolean; default: true };

  @Prop() public isDisabled: Boolean;
  //#region LIFE CYCLES

  //#endregion

  //#region EVENTS
  public onToggleClick(itemValue: string): void {
    this.$emit('input', itemValue);
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
