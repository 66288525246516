



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'SimpleChart',
})
export default class SimpleChart extends Vue {
  @Prop() public title: string;
  @Prop() public name: string;
  @Prop() public options: Function;

  public legend: any = null;
  public chart: any = null;

  //#region LIFE CYCLES
  public mounted(): void {
    this.init();
  }

  public beforeUpdate(): void {
    this.update();
  }

  public beforeDestroy(): void {
    this.destroy();
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region COMPUTED

  get hasChart() {
    return this.options() != null;
  }

  get hasDataChart() {
    return this.hasChart && this.options().data != null && this.options().data.datasets.length > 0;
  }

  //#endregion

  //#region FUNCTIONS
  public init(): void {
    if (this.hasChart) {
      try {
        this.chart = new (window as any).Chart((this.$refs[this.name] as any).getContext('2d'), this.options());
        this.legend = this.chart.generateLegend();
      } catch (error) {
        // En cas d'erreur, on rejoue une deuxième fois. On est obligé de faire ça si on veut éviter des exceptions bizarroides.
        this.chart = null;
        this.init();
      }
    }
  }

  public update(): void {
    this.destroy();
    this.init();
  }

  public destroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  //#endregion
}
