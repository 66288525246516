import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PrestationExpertiseDto } from '@/shared/dtos/prestation-expertise/prestation-expertise.dto';
import DeviceMixin from '@/mixins/device.mixin';
import ThemeMixin from './theme.mixin';
import { getTypeDeBien, isTypeDeBienAppartement, isTypeDeBienMaison, isTypeDeBienTerrain } from '@/helpers/prestation-expertise.helper';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';
import store from '@/shared/store';
import roles from '@/shared/constants/roles.const';

@Component({
  name: 'PrestationExpertiseSectionMixin',
})
export default class PrestationExpertiseSectionMixin extends Mixins(DeviceMixin, ThemeMixin) {
  @Prop() public value: MissionDto;
  @Prop() public businessEntries: any;
  @Prop() public validation: any;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get mission(): MissionDto {
    return this.value;
  }

  get prestationExpertise(): PrestationExpertiseDto {
    return this.mission.prestationExpertise;
  }

  get isNoteReadOnly() {
    if (
      (store.user.roles.includes(roles.AdminBPCEEI) || store.user.roles.includes(roles.Controleur)) &&
      this.$can('Save', 'Notes') &&
      (this.mission.etape === EtapeMissionEnum.ValeursAViserVisa1 ||
        this.mission.etape === EtapeMissionEnum.ValeursAViserVisa2 ||
        this.mission.etape === EtapeMissionEnum.ExpertiseCompleteAViser)
    ) {
      return false;
    }

    return true;

    // Ancien code à garder pour référence si on emploi le visa tech
    // if (!this.$can('Save', 'Notes')) return true;

    // if (this.mission == null || this.mission.dateVisaTech != null) return true;

    // if (
    //   (this.mission.etape === EtapeMissionEnum.ValeursAViserVisa1 ||
    //     this.mission.etape === EtapeMissionEnum.ValeursAViserVisa2 ||
    //   this.mission.flagVisaTech === true
    // )
    //   return false;
    // if (this.mission.etape === EtapeMissionEnum.AttribueeALExpert && this.mission.flagVisaTech === true) return false;

    // return true;
  }

  get contratsTypesBiens(): any[] {
    return this.businessEntries.contratTypeBiens.filter((c: any) => c.parentId == this.prestationExpertise.contratCategorieDActifId);
  }

  get isTypeDeBienAppartement(): boolean {
    return isTypeDeBienAppartement(this.prestationExpertise, this.businessEntries);
  }

  get isTypeDeBienMaison(): boolean {
    return isTypeDeBienMaison(this.prestationExpertise, this.businessEntries);
  }

  get isTypeDeBienTerrain(): boolean {
    return isTypeDeBienTerrain(this.prestationExpertise, this.businessEntries);
  }

  get typeDeBien() {
    return getTypeDeBien(this.prestationExpertise, this.businessEntries);
  }
  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
