import Vue from 'vue';

export async function checkSearchStatus(status: any): Promise<void> {
  if (status != Vue.prototype.$googleMapsApi.places.PlacesServiceStatus.OK) {
    if (status === Vue.prototype.$googleMapsApi.places.PlacesServiceStatus.ZERO_RESULTS) {
      Vue.prototype.$notificationService.warn(`Votre recherche d'adresse n'a retourné aucun résultat`);
    } else {
      Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la recherche d'adresse`);
      await Vue.prototype.$logStoreService.error(
        `Une erreur est survenue lors de la recherche d'adresse - ${Vue.prototype.$googleMapsApi.places.PlacesServiceStatus[status]}`,
        null,
        'Google Maps Api'
      );
    }

    return;
  }
}

export function addressInputChange(value: string, autoCompleteService: any, sessionToken: any): Promise<string[]> {
  return new Promise((resolve) => {
    if (value && autoCompleteService) {
      return autoCompleteService.getPlacePredictions(
        {
          input: value,
          // types: ['address'],
          // on filtre sur les pays : France, Guadeloupe, Martinique, Guyane, La Réunion
          // mais la recherche gmaps est limitée à 5 pays sinon en plus on pourrait mettre les autres DOM-TOM :
          // "YT", "PM", "MF", "WF", "PF", "NC", "TF" (Mayotte, Saint Pierre et Miquelon, Saint Martin, Wallis et Futuna, Polynésie, Nouvelle Calédonie, Terres australes)
          componentRestrictions: { country: ['FR', 'GP', 'MQ', 'GF', 'RE'] },

          sessionToken: sessionToken,
        },
        async (predictions: any, status: any) => {
          await checkSearchStatus(status);
          resolve(predictions.map((p: any) => p.description));
        }
      );
    }

    resolve([]);
  });
}

export function geocode(geocoderService: any, parameters: any): any {
  return new Promise((resolve: any) => {
    geocoderService.geocode(parameters, (results: any, status: any) => {
      if (status === 'OK') {
        resolve(results[0]);
      } else {
        Vue.prototype.$notificationService.error(`L'adresse actuelle ne peut pas être géolocalisée.`);
      }
    });
  });
}
