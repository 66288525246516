






























































































































































import { Component, Inject, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { BTable, BTooltip } from 'bootstrap-vue';
import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import { ReferenceCadastraleDto } from '@/shared/dtos/reference-cadastrale.dto';
import { TypePieceJointeRapportEnum } from '@/shared/enums/type-piece-jointe-rapport.enum';
import axios from 'axios';
import { Guid } from 'guid-typescript';
import { urlToData } from '@/shared/utilities/url.utility';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import DeviceMixin from '@/mixins/device.mixin';
@Component({
  name: 'PrestationExpertiseRapportDescriptionGeneraleReferencesCadastrales',
  components: {
    BTable,
    BTooltip,
    NumericNullable,
    RapportExpertiseFileUploader,
    Spinner,
  },
})
export default class PrestationExpertiseRapportDescriptionGeneraleReferencesCadastrales extends Mixins(DeviceMixin) {
  @Inject('addPieceJointe') public addPieceJointe: any;
  @Inject('deletePieceJointeByIdAndType') public deletePieceJointeByIdAndType: any;

  // Reférences cadastrales
  @Prop() public value: ReferenceCadastraleDto[];
  @Prop() public rapportId: number;
  @Prop() public validation: any;

  // Plan cadastre
  @Prop() public latitude: number;
  @Prop() public longitude: number;
  @Prop() public piecesJointesRapport: any;
  @Prop() public zoom: number;

  // Private
  // Plan cadastre
  private codeInsee: number;
  private type = TypePieceJointeRapportEnum[TypePieceJointeRapportEnum.PlanCadastre];
  private boundingBox: number[] = [];
  private imageWidth = 400;
  private imageHeight = 400;

  // Public
  // References cadastrales
  public fields: any = [
    { key: 'section', label: 'Section' },
    { key: 'parcelle', label: 'Parcelle' },
    { key: 'superficie', label: 'Superficie' },
    { key: 'action', label: '' },
  ];

  // Plan cadastre
  public imagePlanCadastre: HTMLImageElement = null;
  public cadastreUrl: string = '';
  public cadastreBaseUrl: string = Vue.prototype.$config.cadastreBaseUrl;
  public selectedZoom: number = 0.00075;
  //public isPlanCadastreAutomatic = true;
  public isPlanCadastreAutomatic = false;
  public isPlanCadastreAnUploadedCanva = false;
  public zoomOptions = [
    { text: 'Large', value: 0.0025 },
    { text: 'Moyen', value: 0.00075 },
    { text: 'Rapproché', value: 0.0005 },
  ];
  public polygons: number[][] = [];
  public isPlanCadastreLoading = false;
  public apiKey: string = Vue.prototype.$config.googleMapApiKey;

  //#region LIFE CYCLES
  public async mounted(): Promise<void> {
    if (!this.referencesCadastrales.length) this.addReferencesCadastrales();
    // await this.initPlanCadastreType();
    // this.initZoom();
    // this.initPlanCadastre();

    // this.$watch(
    //   () => [this.latitude, this.longitude],
    //   () => {
    //     if (this.isPlanCadastreAutomatic) this.initPlanCadastre(true);
    //   },
    //   {
    //     deep: true,
    //   }
    // );
  }

  //#endregion

  //#region COMPUTED
  get referencesCadastrales(): ReferenceCadastraleDto[] {
    return this.value;
  }

  set referencesCadastrales(referencesCadastrales: ReferenceCadastraleDto[]) {
    this.value = referencesCadastrales;
  }

  get planCadastre(): any {
    return this.piecesJointesRapport[this.type];
  }

  //#endregion

  //#region WATCH
  @Watch('value', { deep: true })
  public async referencesCadastresChanged(): Promise<void> {
    this.referencesCadastrales.forEach((referenceCadastrale: ReferenceCadastraleDto) => {
      if (!referenceCadastrale.superficie) referenceCadastrale.superficie = referenceCadastrale.superficieGouvernement;
    });
  }

  @Watch('selectedZoom')
  public async selectedZoomChanged(): Promise<void> {
    if (this.zoom !== this.selectedZoom) {
      this.$emit('zoomPlanCadastreChanged', this.selectedZoom);
      this.setBoundingBox();
      this.drawPlanCadastreImage();
    }
  }

  //#endregion

  //#region EVENTS
  // Références cadastrales
  public onAddReferenceCadastraleClick(): void {
    this.addReferencesCadastrales();
  }

  public onDeleteReferenceCadastraleClick(index: number): void {
    this.referencesCadastrales.splice(index, 1);

    this.drawPolygons();
  }

  // Plan cadastre
  public onDisplayPlanCadastreManuallyClick(): void {
    this.cadastreUrl = null;
    this.isPlanCadastreAutomatic = false;
    this.clearPieceJointePlanCadastre();
  }

  public async onDisplayPlanCadastreAutoClick(): Promise<void> {
    this.isPlanCadastreAutomatic = true;
    this.isPlanCadastreLoading = false;
  }

  public async onGetCadastreForAddresseClick(): Promise<void> {
    this.isPlanCadastreAutomatic = true;
    this.isPlanCadastreLoading = false;
    this.clearPieceJointePlanCadastre();
    await this.initPlanCadastre(true);
  }

  public async onGetReferenceCadastraleClick(referenceCadastrale: ReferenceCadastraleDto): Promise<void> {
    if (!this.codeInsee) {
      Vue.prototype.$notificationService.warn(`Veuillez d'abord récupérer le plan de cadastre pour l'adresse.`);
    } else {
      this.getReferenceCadastralePolygon(referenceCadastrale);
    }
  }

  //#endregion

  //#region FUNCTIONS
  // Références cadastrales
  private addReferencesCadastrales(): void {
    this.referencesCadastrales.push({
      technicalId: Guid.create(),
      section: '',
      parcelle: '',
      superficie: null,
      rapportExpertiseId: this.rapportId,
      polygon: [],
    });
  }

  // Plan cadastre
  private clearPieceJointePlanCadastre(): void {
    if (this.planCadastre.length > 0) {
      this.imagePlanCadastre = null;
      this.deletePieceJointeByIdAndType(this.planCadastre[0].id, this.type);
    }
    this.piecesJointesRapport[this.type] = [];
  }

  private initPlanCadastreType(): void {
    if (this.planCadastre && this.planCadastre.length) {
      if (this.planCadastre[0].nom === 'PlanCadastreInspire') {
        this.isPlanCadastreAutomatic = true;
        this.isPlanCadastreAnUploadedCanva = true;
      } else {
        this.isPlanCadastreAutomatic = false;
        this.isPlanCadastreAnUploadedCanva = false;
      }
    } else {
      this.isPlanCadastreAutomatic = true;
      this.isPlanCadastreAnUploadedCanva = false; // The first time, the view is the automatic view but image has not been still been uploaded
    }
  }

  private initZoom(): void {
    if (this.zoom === null) {
      this.selectedZoom = this.zoomOptions[1].value;
      this.$emit('zoomPlanCadastreChanged', this.selectedZoom);
    } else {
      this.selectedZoom = this.zoom;
    }
  }

  private initPlanCadastre(forceRefresh = false): void {
    if (this.isPlanCadastreAutomatic || forceRefresh) {
      if (this.isMobile) {
        var parentWidth = (this.$refs.zoneplancadastre as Element).clientWidth;

        this.imageWidth = parentWidth;
        this.imageHeight = parentWidth;
      }

      this.setBoundingBox();
      this.getCodeInsee();
    }
  }

  private setBoundingBox(): void {
    // Longitude = X = 3.xx  ;  Latitude = Y = 43.xx
    this.boundingBox = [
      this.latitude - this.selectedZoom,
      this.longitude - this.selectedZoom,
      this.latitude + this.selectedZoom,
      this.longitude + this.selectedZoom,
    ];
  }

  private async getCodeInsee(): Promise<void> {
    this.isPlanCadastreLoading = true;
    axios
      .get(`https://geo.api.gouv.fr/communes?lat=${this.latitude}&lon=${this.longitude}&fields=nom,code&format=json&geometry=centre`)
      .then(async (response: any) => {
        if (response.data.length && response.data[0].code) {
          this.codeInsee = response.data[0].code;

          this.drawPlanCadastreImage();
        } else {
          Vue.prototype.$notificationService.error(`Les coordonnées géographiques ne correspondent à aucun code INSEE.`);
        }
      })
      .catch(async (error: any) => {
        this.isPlanCadastreLoading = false;
        var logMessage = `Une erreur est survenue lors de la récupération du code INSEE.`;
        Vue.prototype.$notificationService.error(logMessage);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'RapportExpertisePlanCadastre');
      });
  }

  private async drawPlanCadastreImage(): Promise<void> {
    this.isPlanCadastreLoading = true;
    this.cadastreUrl = `https://inspire.cadastre.gouv.fr/scpc/${this.codeInsee}.wms?service=wms&version=1.3&request=GetMap&layers=AMORCES_CAD,LIEUDIT,CP.CadastralParcel,BU.Building,CLOTURE,DETAIL_TOPO,HYDRO,VOIE_COMMUNICATION,BU.Building,BORNE_REPERE&format=image/png&crs=EPSG:4326&bbox=${this.boundingBox[0]},${this.boundingBox[1]},${this.boundingBox[2]},${this.boundingBox[3]}&width=${this.imageWidth}&height=${this.imageHeight}&styles=`;
    const image = new window.Image();

    urlToData(
      this.cadastreUrl,
      async (dataUrl: any) => {
        image.src = dataUrl;
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
          this.imagePlanCadastre = image;
        };

        for (let i = this.referencesCadastrales.length - 1; i >= 0; i--) {
          if (!this.referencesCadastrales[i].section && !this.referencesCadastrales[i].parcelle) {
            this.referencesCadastrales.splice(i, 1);
          }
        }

        await this.getReferencesCadastralesPolygons();
        this.isPlanCadastreLoading = false;
      },
      async () => {
        this.isPlanCadastreLoading = false;
        Vue.prototype.$notificationService.error(
          "Une erreur est survenue lors de la récupération de l'image du cadastre. Si l'erreur persiste, vous pouvez toujours ajouter manuellement un plan de cadastre."
        );
      }
    );
  }

  private async getReferencesCadastralesPolygons(start: number = 0): Promise<void> {
    const logMessage = `Une erreur est survenue lors de l'identification du cadastre. Si l'erreur persiste, vous pouvez toujours ajouter manuellement un plan de cadastre.`;

    this.isPlanCadastreLoading = true;

    await axios
      .get(`https://apicarto.ign.fr/api/cadastre/parcelle?code_insee=${this.codeInsee}${start ? `&_start=${start}` : ''}`)
      .then(async (response: any) => {
        if (response.data && !response.data.message && response.data.features && response.data.features.length) {
          // Pour chaque feature, on va isoler la liste des parcelles et leurs polygons pour tester l'inclusivité du point geographique
          response.data.features.forEach((feature: any) => {
            this.createReferenceCadastrale(feature, true);
          });

          if (start < response.data.totalFeatures && start + 1000 < response.data.totalFeatures) {
            await this.getReferencesCadastralesPolygons(start + 1000);
          } else {
            this.drawPolygons();
          }
        } else {
          Vue.prototype.$notificationService.error(logMessage);
        }
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(logMessage);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'RapportExpertisePlanCadastre');
      })
      .finally(() => {
        this.isPlanCadastreLoading = false;
      });
  }

  private async getReferenceCadastralePolygon(referenceCadastrale: ReferenceCadastraleDto): Promise<void> {
    const logMessage = `Une erreur est survenue lors de l'identification de la section ${referenceCadastrale.section} parcelle ${referenceCadastrale.parcelle}.  Êtes-vous sur d'avoir correctement saisi la référence cadastrale ?  Si l'erreur persiste, vous pouvez toujours ajouter manuellement un plan de cadastre.`;

    this.isPlanCadastreLoading = true;

    await axios
      .get(
        `https://apicarto.ign.fr/api/cadastre/parcelle?code_insee=${
          this.codeInsee
        }&section=${referenceCadastrale.section.toUpperCase()}&numero=${this.padWithZeros(+referenceCadastrale.parcelle, 4)}`
      )
      .then(async (response: any) => {
        if (response.data && !response.data.message && response.data.features && response.data.features.length) {
          this.createReferenceCadastrale(response.data.features[0]);

          this.drawPolygons();
        } else {
          Vue.prototype.$notificationService.error(logMessage);
        }
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(logMessage);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'RapportExpertisePlanCadastre');
      })
      .finally(() => {
        this.isPlanCadastreLoading = false;
      });
  }

  private createReferenceCadastrale(feature: any, checkLocation = false) {
    /* Exemple pour comprendre. Une parcelle peut contenir plusieurs découpages et pour chaque découpage, il peut y avoir un trou
      {
        ...,
        coordinates: [
          [
            [
              [40, 40], [20, 45], [45, 30], [40, 40]
            ]
          ],
          [
            [
              [20, 35], [10, 30], [10, 10], [30, 5]...
            ],
            [
              [30, 20], [20, 15], [20, 25], [30, 20] => trou
            ]
          ]
        ]
      }
    */

    var parcelles: number[][][][] = feature.geometry.coordinates;
    parcelles.forEach((parcelle: number[][][]) => {
      parcelle.forEach((polygon: number[][]) => {
        let flattenCoordinates = polygon.map((polygonCoords) => {
          return { lng: polygonCoords[0], lat: polygonCoords[1] };
        });
        // Test d'inclusion du point geographique
        const googleMapPolygon = new Vue.prototype.$googleMapsApi.Polygon({
          paths: flattenCoordinates,
        });

        if (
          !checkLocation ||
          (checkLocation &&
            Vue.prototype.$googleMapsApi.geometry.poly.containsLocation({ lat: this.latitude, lng: this.longitude }, googleMapPolygon))
        ) {
          var existingReferenceCadastrale = this.referencesCadastrales.find(
            (referenceCadastrale: ReferenceCadastraleDto) =>
              referenceCadastrale.section === feature.properties.section &&
              this.padWithZeros(referenceCadastrale.parcelle, 4) === feature.properties.numero
          );

          if (existingReferenceCadastrale) {
            existingReferenceCadastrale.polygon = polygon;
            existingReferenceCadastrale.superficieGouvernement = feature.properties.contenance;
            existingReferenceCadastrale.superficie = existingReferenceCadastrale.superficieGouvernement;
          } else {
            this.referencesCadastrales.push({
              technicalId: Guid.create(),
              section: feature.properties.section,
              parcelle: parseInt(feature.properties.numero).toString(),
              superficie: feature.properties.contenance,
              superficieGouvernement: feature.properties.contenance,
              polygon: polygon,
            });
          }
        }
      });
    });
  }

  private drawPolygons(): void {
    this.polygons = [];

    this.referencesCadastrales
      .filter((rf) => rf.polygon)
      .forEach((referenceCadastrale: ReferenceCadastraleDto) => {
        var newPolygon: number[] = [];
        referenceCadastrale.polygon.forEach((polygon: number[]) => {
          newPolygon.push(this.getPixelOnXFromCoordinate(polygon[0]));
          newPolygon.push(this.getPixelOnYFromCoordinate(polygon[1]));
        });
        this.polygons.push(newPolygon);
      });

    setTimeout(async () => {
      // Capture whole canva and save it to PiecesJointes
      var canvas = document.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
      var dataURL = canvas.toDataURL();
      await this.addPieceJointe(this.type, dataURL, 'PlanCadastreInspire.png');
    }, 1000);
  }

  private getPixelOnXFromCoordinate(coord: number): number {
    return ((coord - this.boundingBox[1]) / (this.boundingBox[3] - this.boundingBox[1])) * this.imageWidth;
  }

  private getPixelOnYFromCoordinate(coord: number): number {
    return this.imageHeight - ((coord - this.boundingBox[0]) / (this.boundingBox[2] - this.boundingBox[0])) * this.imageHeight;
  }

  private padWithZeros(number: number | string, length: number) {
    var my_string = '' + number;
    while (my_string.length < length) {
      my_string = '0' + my_string;
    }

    return my_string;
  }

  //#endregion
}
