









































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import { MissionDto } from '@/shared/dtos/mission.dto';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import _ from 'lodash';

@Component({
  name: 'PrestationExpertisePush',
  components: { Spinner },
})
export default class PrestationExpertisePush extends Mixins(DeviceMixin) {
  public missionsStatusToPush: any[] = [];
  public isPushProcessTerminated = true;
  public hasErrorOccuredDuringPushProcess = false;
  public nbPushedMissions: number;

  @Prop() public missionId: number;

  //#region LIFE CYCLES
  public async beforeMount(): Promise<void> {
    await this.init();
  }

  //#endregion

  //#region COMPUTED
  //#endregion

  //#region WATCH
  //#endregion

  //#region EVENTS
  public onCloseButtonClick(): void {
    this.$emit('onClose');
  }

  public async onRetryButtonClick(): Promise<void> {
    await this.init();
  }

  //#endregion

  //#region FUNCTIONS
  public async init(): Promise<void> {
    // Get stored missions to push
    const missionsToPush: MissionDto[] = await Vue.prototype.$missionStoreService.getToPush();

    if (missionsToPush && missionsToPush.length) {
      await Vue.prototype.$logStoreService.info(`UPDATE missions - START`, 'PrestationExpertisePush');

      // Create missionsStatusToPush to display
      this.missionsStatusToPush = missionsToPush.map((missionToPush: MissionDto) => {
        return { isStatusValid: null, reference: missionToPush.reference, emprunteur: missionToPush.emprunteur } as MissionStatusToPush;
      });

      this.isPushProcessTerminated = false;
      this.nbPushedMissions = 0;

      // For each missions to push
      for (let missionToPush of missionsToPush) {
        const missionStatusToPush = this.missionsStatusToPush.find(
          (mission: MissionStatusToPush) => mission.reference === missionToPush.reference
        );

        // Send mission.prestationExpertise datas
        const hasErrorOccured = await Vue.prototype.$synchronizationService.updateMission(missionToPush);

        this.hasErrorOccuredDuringPushProcess = hasErrorOccured;
        // Set the current mission to push status
        missionStatusToPush.isStatusValid = !hasErrorOccured;

        if (!hasErrorOccured) this.nbPushedMissions++;

        if (this.nbPushedMissions > 4) {
          document.querySelector('.prestation-expertise-push-component-items').scrollTo({
            top: (this.nbPushedMissions - 4) * 56, // Height = 50px + margin = 16px
            left: 0,
          });
        }
      }

      await Vue.prototype.$logStoreService.info(`UPDATE missions - END`, 'PrestationExpertisePush');

      this.isPushProcessTerminated = true;
    }

    //#endregion
  }
}

interface MissionStatusToPush {
  isStatusValid?: boolean;
  reference: string;
  emprunteur: string;
}
