

































import { Component, Prop } from 'vue-property-decorator';
import PrestationExpertiseMenu from '@/components/prestation-expertise/prestation-expertise-menu';
import { ComponentSection } from '@/shared/models/component-section';
import ValidationStatus from '@/components/validation-status.vue';
import VisaSectionStatus from '@/components/visa-section-status.vue';
import { RapportExpertiseNoteSectionDto } from '@/shared/dtos/rapport-expertise-section-note.dto';

@Component({
  name: 'PrestationExpertiseDesktopMenu',
  components: {
    ValidationStatus,
    VisaSectionStatus,
  },
})
export default class PrestationExpertiseDesktopMenu extends PrestationExpertiseMenu {
  @Prop() validation: any;
  @Prop() notes: { [index: number]: RapportExpertiseNoteSectionDto };

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onMenuItemClick(componentSection: ComponentSection): void {
    const el = document.querySelector('#' + componentSection.slug);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

    this.$emit('componentSectionChanged', componentSection);
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
