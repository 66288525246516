import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import { BaseDtoApiService } from './base-dto-api.service';
import axios from 'axios';

export class PrestationExpertisePieceJointeApiService extends BaseDtoApiService<PrestationExpertisePieceJointeDto> {
  constructor() {
    super('prestationexpertisepiecesjointes');
  }

  public getFile(id: number): Promise<any> {
    return axios.get(`${this._endpoint}/${id}`, {
      responseType: 'blob',
    });
  }
}
