import { required, helpers } from 'vuelidate/lib/validators';

const etudeDeMarcheValidation = {
  mission: {
    dateVisite: {
      required,
    },
    prestationExpertise: {
      contratCategorieDActifId: { required },
      contratTypeBienId: {
        customRequired(value) {
          return this.businessEntries.contratTypeBiens.filter((b) => b.parentId == this.etudeDeMarche.contratCategorieDActifId).length > 0
            ? helpers.req(value)
            : true;
        },
      },
      annexes: {
        required,
      },
    },
    contratGroup: [
      'mission.prestationExpertise.contratCategorieDActifId',
      'mission.prestationExpertise.contratTypeBienId',
      'mission.dateVisite',
    ],
    situationGeographiqueGroup: [],
    rapportGroup: ['mission.prestationExpertise.annexes'],
  },
};

export { etudeDeMarcheValidation };
