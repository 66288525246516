








































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { BModal, BSidebar } from 'bootstrap-vue';
import { RapportExpertiseBusinessEntryRelationDto } from '@/shared/dtos/rapport-expertise-business-entry-relation.dto';
import { RapportExpertiseBusinessEntryDto } from '@/shared/dtos/rapport-expertise-business-entry.dto';
import Checkboxs from '@/shared/components/checkboxs.vue';
import Radios from '@/shared/components/radios.vue';
import Dropdowns from '@/shared/components/dropdowns.vue';
import { isNotNullOrEmpty } from '@/shared/utilities/string.utility';
import DeviceMixin from '@/mixins/device.mixin';

@Component({
  name: 'ChoicePopup',
  components: {
    BSidebar,
    Checkboxs,
    Radios,
    Dropdowns,
  },
})
export default class ChoicePopup extends Mixins(DeviceMixin) {
  @Prop() public value: RapportExpertiseBusinessEntryRelationDto[] | number;
  @Prop() public list: RapportExpertiseBusinessEntryDto[];
  @Prop() public rapportId: number;
  @Prop() public isDisabled: boolean;
  @Prop() public title: string;
  @Prop() public id: string;
  @Prop({ default: true }) public titleBold: boolean;
  @Prop({ default: true }) public state: boolean;
  @Prop({ default: false }) public multiple: boolean;
  @Prop({ default: false }) public dropdown: boolean;

  /* for radios */
  @Prop({ default: false }) public validation: boolean;
  @Prop() public validationTitle: String;
  @Prop() public validationContent: String;
  @Prop({ default: false }) public canDeselect: boolean;

  public baseURL: string = Vue.prototype.$config.baseUrlApi;
  public listSelected: RapportExpertiseBusinessEntryDto[] = [];

  //for dropdown
  public listQualificationSelected: any = [];
  public oldValue: RapportExpertiseBusinessEntryRelationDto[] | number = null;

  //#region LIFE CYCLES

  beforeMount(): void {
    if (this.dropdown) {
      this.updateListQualificationSelected();
    } else {
      this.updateListSelected();
    }
  }

  //#endregion

  //#region WATCH
  @Watch('value')
  public valueChanged(): any {
    if (this.dropdown) {
      this.updateListQualificationSelected();
    } else {
      this.updateListSelected();
    }
  }
  //#endregion

  //#region COMPUTED

  get choiceValue() {
    return this.value;
  }

  set choiceValue(val: RapportExpertiseBusinessEntryRelationDto[] | number) {
    this.$emit('input', val);
  }

  get isEdit(): boolean {
    if (typeof this.value === 'number') {
      return isNotNullOrEmpty(this.value);
    }

    return this.value && this.value.length > 0;
  }

  //#endregion

  //#region EVENTS

  public onShowSideBarClick(): void {
    this.oldValue = JSON.parse(JSON.stringify(this.choiceValue));
    this.closeSidebar();
  }

  public onCollapseSideBarClick(): void {
    this.closeSidebar();
  }

  public onShowModalValidationClick(): void {
    (this.$refs.modalValidation as BModal).show();
  }

  public onOkModalValidationClick(): void {
    (this.$refs.radioElement as Radios).submitValidation();
    (this.$refs.modalValidation as BModal).hide();
    this.closeSidebar();
  }

  public onCancelModalValidationClick(): void {
    (this.$refs.radioElement as Radios).cancelValidation();
    (this.$refs.modalValidation as BModal).hide();
  }

  public onTerminateClick(): void {
    this.closeSidebar();
  }

  public onCancelClick(): void {
    this.choiceValue = this.oldValue;
    this.closeSidebar();
  }

  //#endregion

  //#region FUNCTIONS

  public closeSidebar(): void {
    this.$root.$emit('bv::toggle::collapse', 's' + this.id);
  }

  public isSelected(id: number): boolean {
    if (this.value === null || this.value === undefined) return false;

    if (typeof this.value === 'number') {
      return this.value === id;
    }

    return this.value.map((v: any) => v.businessEntrySelectedId).includes(id);
  }

  private updateListSelected(): void {
    this.listSelected = this.list.filter((x) => this.isSelected(x.id));
  }

  public updateListQualificationSelected(): void {
    this.listQualificationSelected = [];
    this.list.forEach((businessEntryQualifiable: any, index: number) => {
      var businessEntrySelected = (this.value as RapportExpertiseBusinessEntryRelationDto[]).find((element: any) => {
        return element.businessEntrySelectedId == businessEntryQualifiable.businessEntry.id;
      });

      if (businessEntrySelected !== undefined) {
        var businessEntryQualifiableSelected = businessEntryQualifiable.businessEntryQualifications.find((qualification: any) => {
          return qualification.id == businessEntrySelected.businessEntryQualificationId;
        });

        const businessEntryParentSelected = businessEntryQualifiable.businessEntry;

        this.listQualificationSelected.push({
          businessEntryParentSelected,
          businessEntryQualifiableSelected,
        });
      }
    });
  }

  //#endregion
}
