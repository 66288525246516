


































import { Component, Mixins } from 'vue-property-decorator';

import ValeurSpotPlusSectionMixin from '@/mixins/valeur-spot-plus-section.mixin';

import NumericNullable from '@/shared/components/numeric-nullable.vue';
import PrestationExpertiseSituationCadastrale from '@/shared/components/prestation-expertise/prestation-expertise-situation-cadastrale.vue';
import PrestationExpertiseGoogleMapsStreet from '@/shared/components/prestation-expertise/prestation-expertise-google-maps-street.vue';
import StarRating from 'vue-star-rating';

@Component({
  name: 'PrestationExpertiseValeurSpotPlusDescriptionGeneraleDescriptionDuBien',
  components: {
    NumericNullable,
    PrestationExpertiseSituationCadastrale,
    StarRating,
    PrestationExpertiseGoogleMapsStreet,
  },
})
export default class PrestationExpertiseValeurSpotPlusDescriptionGeneraleDescriptionDuBien extends Mixins(ValeurSpotPlusSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
