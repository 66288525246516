

































































import { GeographiqueCommoditeDto } from '@/shared/dtos/geographique-commodite.dto';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
import { groupBy, orderBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ExpCommodite',
})
export default class ExpCommodite extends Vue {
  @Prop() public value: GeographiqueCommoditeDto[];
  @Prop() public categorieId: number;
  @Prop() public titre: string;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get filteredCommodites() {
    return (
      this.value
        .filter((p: any) => p.categorie == this.categorieId)
        .sort((a: any, b: any) => {
          if (a.typeDistance && b.typeDistance) {
            return this.sortByTempsDistance(a, b);
          } else {
            return this.sortByMetreDistance(a, b);
          }
        }) || []
    );
  }

  get filteredCommoditesBySousCategories() {
    var orderBySousCategories = this.filteredCommodites.sort((a: any, b: any) => {
      return a.sousCategorie.localeCompare(b.sousCategorie);
    });
    return groupBy(orderBySousCategories, (x) => x.sousCategorie);
  }

  get isSousCategorieNullOrEmpty() {
    var values = this.value.filter((p: any) => p.categorie == this.categorieId) || [];
    return values.length === 0 || isNullOrEmpty(values[0].sousCategorie);
  }

  //#endregion

  //#region EVENTS

  onCommoditeClick(item: any) {
    var itemSelected = this.value.filter((v: any) => v == item)[0];
    if (itemSelected.selectionne) {
      itemSelected.selectionne = false;
    } else {
      itemSelected.selectionne = true;
    }
    this.$emit('input', this.value);
  }

  //#endregion

  //#region FUNCTIONS
  isPieds(value: any) {
    return value === null || value === 1;
  }

  isVoiture(value: any) {
    return value === null || value === 2;
  }

  private sortByTempsDistance(a: any, b: any): number {
    if (a.typeDistance == b.typeDistance) {
      if (a.tempsDistance == b.tempsDistance) {
        return 0;
      }
      if (a.tempsDistance > b.tempsDistance) {
        return 1;
      }
    }

    if (a.typeDistance > b.typeDistance) {
      return 1;
    }

    return -1;
  }

  private sortByMetreDistance(a: any, b: any): number {
    if (a.metreDistance == b.metreDistance) {
      return 0;
    }
    if (a.metreDistance > b.metreDistance) {
      return 1;
    }

    return -1;
  }

  //#endregion
}
