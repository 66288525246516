export enum RapportExpertiseNoteSectionEnum {
  //Contrat = 11, // pas de note pour le contrat, mais on réserve le numéro au cas où...
  SituationGeographique = 12,
  DescriptionGenerale = 13,
  DescriptionParticuliere = 14,
  SituationFiscale = 15,
  SituationJuridique = 16,
  SituationUrbanistique = 17,
  EtudeDeMarche = 18,
  Evaluation = 19,
  Conclusion = 20,
  Global = 21,
  SituationLocative = 22,
}
