






import { Component, Mixins } from 'vue-property-decorator';

import EtudeDeMarcheSectionMixin from '@/mixins/etude-de-marche-section.mixin';

import PrestationExpertiseSituationAdresse from '@/shared/components/prestation-expertise/prestation-expertise-situation-adresse.vue';
import StarRating from 'vue-star-rating';

@Component({
  name: 'PrestationExpertiseEtudeDeMarcheSituationGeographiquePlansDeSituation',
  components: {
    PrestationExpertiseSituationAdresse,
    StarRating,
  },
})
export default class PrestationExpertiseEtudeDeMarcheSituationGeographiquePlansDeSituation extends Mixins(EtudeDeMarcheSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
